import * as React from "react"
import { useQuery, useMutation } from "@apollo/client"
import { ErrorBoundary } from "react-error-boundary"
import { useRecoilState, useRecoilValue } from "recoil"
import { CoordinateSystemUnit, CreateProjectRequest, ProjectCreatedWithStatus, ProjectStatus } from "../../types"
import { GET_CONFIG, HAS_ACCESS, CREATE_PROJECT, SYNC_PROJECT } from "../../api/QuadriApi"
import ConnectApi, { ConnectData } from "../../api/ConnectApi"
import { extensionApiState, projectIdState, projectOriginState, quadriProjectIdState } from "../StateSelectors"
import { PendingSpinner } from "../Pending"
import { LicenseBanner } from "./LicenseBanner"
import { OopsFallback } from "../Oops/OopsFallback"
import ImportedProjectSettings from "./ImportedProjectSettings"
import QuadriProjectSettings from "./QuadriProjectSettings"
import QuadriHeader from "./QuadriHeader"
import QuadriFooter from "./QuadriFooter"
import { getMenu } from "../../util/getMenu"
import { useTranslation } from "react-i18next"
import { exceptionFromApolloError } from "../../util/apolloError"

const pollIntervalMs = 5000

// Used to determine the config page state. Exported so that the progress banner can use it
export type ConfigStatus = "Creating" | "Active" | "Failed" | "Syncing" | "Default" | "Deleting"

// The exported wrapper component that handles error boundary and input
// to the loading component
const ConfigMain = () => {
  const projectId = useRecoilValue(projectIdState)
  const projectOrigin = useRecoilValue(projectOriginState)

  return (
    <ErrorBoundary FallbackComponent={OopsFallback}>
      <ConfigLoad {...{ projectId, projectOrigin }} />
    </ErrorBoundary>
  )
}

interface ConfigLoadProps {
  projectId?: string
  projectOrigin?: string
  accessToken?: string
}

// TODO: Shorten this somehow
// The responsibility of this is to load everything necessary to render the
// Config page. It must therefore also hold initial project state as well
// as data from Connect
const ConfigLoad = (props: ConfigLoadProps) => {
  const { projectId, projectOrigin } = props
  const { t } = useTranslation("common")

  if (!projectId) throw new Error("Cannot load config page: Missing project id")
  if (!projectOrigin) throw new Error("Cannot load config page: Missing project origin")

  // Need to set this if this is the first time project is created
  const [, setQuadriProjectId] = useRecoilState(quadriProjectIdState)
  const extensionApi = useRecoilValue(extensionApiState)

  // Connect Data
  const [connectApi, setConnectApi] = React.useState<ConnectApi | undefined>(undefined)
  const [connectData, setConnectData] = React.useState<ConnectData>({})
  const [fetchedConnect, setFetchedConnect] = React.useState(false)

  // Feature Catalogs data depends on if the project has ever been created...

  React.useEffect(() => {
    const runEffectAsync = async () => {
      const api = new ConnectApi(projectOrigin)
      setConnectApi(api)
      let connectData = await api.getConnectProjectData(projectId)
      setConnectData(connectData)
      setFetchedConnect(true)
    }
    runEffectAsync()
  }, [projectOrigin, projectId])

  const accessQuery = useQuery(HAS_ACCESS)
  const projectQuery = useQuery(GET_CONFIG, {
    variables: { id: projectId }
  })

  const { data, startPolling, stopPolling, updateQuery } = projectQuery
  const quadriProjectId = data?.tryGetConnectQuadriProject?.project?.quadriProjectId
  const projectStatus = data?.tryGetConnectQuadriProject?.project?.projectStatus

  // Set the quadri Project ID if the request to GraphQL returns one
  React.useEffect(() => {
    if (quadriProjectId) {
      setQuadriProjectId(quadriProjectId)
      console.log("Set Quadri Project Id: ", quadriProjectId)
    }
  }, [quadriProjectId, setQuadriProjectId])

  // Show the menu if the project is returned as active
  React.useEffect(() => {
    if (projectStatus === "Active") {
      console.log("Showing extension menu items")
      console.log("GET CONFIG query data: ", data)
      extensionApi?.ui.setMenu(getMenu(t))
    }
  }, [extensionApi?.ui, projectStatus, data])

  // "Handle" errors (they are handled by parent error boundary)
  if (accessQuery.error) throw exceptionFromApolloError(accessQuery.error)
  if (projectQuery.error) throw exceptionFromApolloError(projectQuery.error)

  // From here, the queries are either loading, or they have data
  if (!connectApi || !fetchedConnect || accessQuery.loading || projectQuery.loading) {
    return <PendingSpinner />
  } else {
    // Here, everything loaded successfully
    const needsLicense = !accessQuery.data.hasAccess?.access

    // But do we have a project???
    const { found, project } = projectQuery.data.tryGetConnectQuadriProject

    const innerProps = {
      connectProjectId: projectId,
      featureCatalogId: project?.featureCatalogId,
      coordinateSystemUnit: project?.coordinateSystemUnit,
      projectFound: found,
      startPolling,
      stopPolling,
      updateQuery,
      connectData,
      needsLicense,
      projectStatus: project?.projectStatus,
      projectStatusMessage: project?.projectStatusMessage,
      projectUpdateMessage: project?.projectUpdateMessage,
      createdAt: project?.createdAt,
      updatedAt: project?.updatedAt,
      projectCreatedWithStatus: project?.projectCreatedWithStatus
    }
    return <ConfigMainInner {...innerProps} />
  }
}

interface ConfigMainInnerProps {
  startPolling(pollInterval: number): void
  stopPolling(): void
  updateQuery: (mapFn: (previousResult: any, options: any) => any) => void
  connectProjectId: string
  connectData: ConnectData
  needsLicense?: boolean
  featureCatalogId?: string
  coordinateSystemUnit?: CoordinateSystemUnit
  projectFound?: boolean
  projectStatus?: ProjectStatus
  projectStatusMessage?: string
  projectUpdateMessage?: string
  createdAt?: string
  updatedAt?: string
  projectCreatedWithStatus?: ProjectCreatedWithStatus
}

// This is the inner component responsible for page interaction and rendering
// It is assumed that all required data is loaded when this is rendered
const ConfigMainInner = (props: ConfigMainInnerProps) => {
  const { region, unitSystem, boundaryCoordinates, crsName } = props.connectData
  const { startPolling, stopPolling, updateQuery, needsLicense, connectProjectId } = props
  const { projectStatus, projectStatusMessage, createdAt, updatedAt } = props
  const { projectUpdateMessage, projectCreatedWithStatus } = props

  // Feature catalog state is kept here and not in child QuadriProjectSettings component
  // because it is used in the creation request that is tied to the button.
  const [featureCatalogId, setFeatureCatalogId] = React.useState<string | undefined>(props.featureCatalogId)
  const [featureCatalogStandard, setFeatureCatalogStandard] = React.useState<string | undefined>(undefined)
  const [coordinateSystemUnit, setCoordinateSystemUnit] = React.useState<CoordinateSystemUnit>(
    props.coordinateSystemUnit || "Meter"
  )

  // Save the id of the existing feature catalog if there is one. This is used
  // to fetch a list of compatible feature catalogs if the user wants to upgrade
  const existingFcId = props.featureCatalogId || null

  // If project is never created, then this is the mutation that gets sent on button click
  const [createProject, createProjectResponse] = useMutation(CREATE_PROJECT, {
    // Only run on success
    onCompleted: (data) => {
      updateQuery((prev: any) => updateQueryProjectStatus(prev, "Creating"))
    }
  })

  // Mutation used for syncing after the project has been created
  const [syncProject, syncProjectResponse] = useMutation(SYNC_PROJECT, {
    // Only run on success
    onCompleted: (data) => {
      updateQuery((prev: any) => updateQueryProjectStatus(prev, "Updating"))
    }
  })

  // Callback function to Create/Sync button
  const submitQuadriProject = (e: any) => {
    e.preventDefault()
    if (connectProjectId !== undefined && featureCatalogId !== undefined && region !== undefined) {
      const project: CreateProjectRequest = {
        connectProjectId: connectProjectId,
        featureCatalogId,
        coordinateSystemUnit,
        connectProjectRegion: region
      }

      if (projectStatus === "Active") {
        syncProject({ variables: { project } })
        console.log("Syncing")
      } else {
        createProject({ variables: { project } })
        console.log("Creating")
      }
    } else {
      console.error("Missing create/update parameters")
    }
  }

  // Get status of the config page, this is a combination of project status and query statuses
  const configStatus = getConfigStatus(createProjectResponse, syncProjectResponse, projectStatus)

  // Stop polling whenever config status changes to something not creating or syncing
  React.useEffect(() => {
    if (projectStatus !== "Creating" && projectStatus !== "Updating") {
      stopPolling()
      console.log("stopped polling")
    } else {
      console.log("started polling")
      startPolling(pollIntervalMs)
    }
  }, [projectStatus, stopPolling, startPolling])

  // Is the "Enable Quadri"/"Sync to Quadri" button enabled or disabled
  const changeInProgress = configStatus === "Creating" || configStatus === "Syncing" || configStatus === "Deleting"

  // Disable creation / deletion
  const isButtonDisabled = changeInProgress || !featureCatalogId || !featureCatalogStandard

  // Disable editing the feature catalogs
  const disableEdit = changeInProgress || needsLicense

  console.log("Project status = ", projectStatus)
  console.log("changeInProgress", changeInProgress)
  console.log("isButtonDisabled", isButtonDisabled)
  console.log("disableEdit", disableEdit)

  return (
    <>
      {needsLicense ? <LicenseBanner /> : null}
      <QuadriHeader
        configStatus={configStatus}
        isButtonDisabled={isButtonDisabled}
        statusMessage={projectStatusMessage}
        updateErrorMessage={projectUpdateMessage}
        createProjectResponse={createProjectResponse}
        submitQuadriProject={submitQuadriProject}
        createdAt={createdAt}
        updatedAt={updatedAt}
        projectCreatedWithStatus={projectCreatedWithStatus}
      />
      <QuadriProjectSettings
        existingFcId={existingFcId}
        fcStandard={featureCatalogStandard}
        setFcStandard={setFeatureCatalogStandard}
        featureCatalogId={featureCatalogId}
        setFeatureCatalogId={setFeatureCatalogId}
        coordinateSystemUnits={coordinateSystemUnit}
        setCoordinateSystemUnits={setCoordinateSystemUnit}
        disableEdit={disableEdit}
        configStatus={configStatus}
        projectStatus={projectStatus}
        region={region}
      />
      <ImportedProjectSettings
        connectProjectId={props.connectProjectId}
        unitSystem={unitSystem}
        boundaryCoordinates={boundaryCoordinates}
        crsName={crsName}
      />
      <QuadriFooter
        connectProjectId={connectProjectId}
        projectStatus={projectStatus}
        changeInProgress={changeInProgress}
      />
    </>
  )
}

// The project status depends both on the latest received project status
// And on the status of requests that are still loading as well as request errors
const getConfigStatus = (
  createProjectMutation: any,
  syncProjectMutation: any,
  projectStatus?: ProjectStatus
): ConfigStatus =>
  projectStatus === "Creating" || createProjectMutation.loading
    ? "Creating"
    : projectStatus === "Updating" || syncProjectMutation.loading
    ? "Syncing"
    : projectStatus === "Failed" || createProjectMutation.error !== undefined
    ? "Failed"
    : projectStatus === "Active" || createProjectMutation.data !== undefined || syncProjectMutation.data !== undefined
    ? "Active"
    : projectStatus === "Deleting" // Anything else needed here?
    ? "Deleting"
    : "Default"

// This function is used to update the get project query after a create/sync returns a new status
const updateQueryProjectStatus = (prev: any, status: ProjectStatus) => ({
  ...prev,
  tryGetConnectQuadriProject: {
    ...prev.tryGetConnectQuadriProject,
    project: {
      ...prev.tryGetConnectQuadriProject.project,
      projectStatus: status
    }
  }
})

export default ConfigMain
