import { Activity } from "../../types"
import i18next from "i18next"

export const getActivityDetails = ({ type, tasksAffectedCount: affectedTasks, worksetName }: Activity) => {
  switch (type) {
    case "Share":
      return (
        <>
          {i18next.t("timeline:util.shared")}
          {getAffectedTasks(affectedTasks)} {i18next.t("timeline:util.tasks")}
        </>
      )
    case "Receive":
      return (
        <>
          {i18next.t("timeline:util.received")}
          {getAffectedTasks(affectedTasks)} {i18next.t("timeline:util.tasks")}
        </>
      )
    case "Join":
      return (
        <>
          {i18next.t("timeline:util.joinedWorkset")}
          {renderWorksetName(worksetName)}
        </>
      )

    case "Reserve":
      return (
        <>
          {i18next.t("timeline:util.reserved")}
          {getAffectedTasks(affectedTasks)} {i18next.t("timeline:util.tasks")}
        </>
      )
    case "Release":
      return (
        <>
          {i18next.t("timeline:util.released")}
          {getAffectedTasks(affectedTasks)} {i18next.t("timeline:util.tasks")}
        </>
      )
  }
}

export const getAffectedTasks = (affectedTasks?: number) => (affectedTasks ? ` ${affectedTasks}` : "")

const renderWorksetName = (worksetName?: string) =>
  worksetName ? (
    <>
      {" "}
      <em>{worksetName}</em>
    </>
  ) : (
    ""
  )

export const getDescription = ({ description }: Activity) => (description ? <em>. {description}</em> : "")
