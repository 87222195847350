import React from "react"

interface OopsProps {
  heading?: string
  body?: React.ReactNode
}

const Oops = (props: OopsProps) => (
  <div className="d-flex h-100 flex-column justify-content-center align-items-center text-center text-muted">
    <div
      className="bg-gray_0 d-block rounded d-flex justify-content-center align-items-center mb-1"
      style={{ width: "192px", height: "192px" }}
    >
      <i className="icon-font i64 tc-icon-error-alt"></i>
    </div>
    <h1 className="mb-1">{props.heading || "Oops!"}</h1>
    {props.body ? (
      <p>{props.body}</p>
    ) : (
      <>
        <p>It seems like an we've experienced an issue</p>
        <p className="mt-1">Please try again at a later time...</p>
      </>
    )}
  </div>
)

export default Oops
