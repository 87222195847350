import { useTranslation } from "react-i18next"
import { ProjectCreatedWithStatus } from "../../types"
import { ConfigStatus } from "./ConfigMain"
import { ProgressBanner } from "./ProgressBanner"

// Contains the button and the progress banner
const QuadriHeader = (props: {
  configStatus: ConfigStatus
  isButtonDisabled?: boolean
  statusMessage?: string
  updateErrorMessage?: string
  createProjectResponse: any
  submitQuadriProject: (e: any) => void
  createdAt?: string
  updatedAt?: string
  projectCreatedWithStatus?: ProjectCreatedWithStatus
}) => {
  const { configStatus, isButtonDisabled, createdAt, updatedAt } = props
  const { statusMessage, createProjectResponse, submitQuadriProject } = props
  const { updateErrorMessage, projectCreatedWithStatus } = props

  const { t } = useTranslation("config")

  return (
    <div className="row">
      <div className="col-12 head-container mt-0">
        <div className="page-heading mt-0">
          <h1>{t("quadriHeader.title")}</h1>
          <QuadriActionButton
            configStatus={configStatus}
            isButtonDisabled={isButtonDisabled}
            submitQuadriProject={submitQuadriProject}
          />
        </div>
      </div>

      {(configStatus === "Creating" || configStatus === "Active" || configStatus === "Failed") && (
        <ProgressBanner
          retryProcess={submitQuadriProject}
          configStatus={configStatus}
          errorMessage={statusMessage || createProjectResponse.error?.message}
          updateErrorMessage={updateErrorMessage}
          disableRetry={isButtonDisabled}
          createdAt={createdAt}
          updatedAt={updatedAt}
          projectCreatedWithStatus={projectCreatedWithStatus}
        />
      )}
    </div>
  )
}

const QuadriActionButton = (props: {
  configStatus: ConfigStatus
  isButtonDisabled?: boolean
  submitQuadriProject: (e: any) => void
}) => {
  const { configStatus, isButtonDisabled, submitQuadriProject } = props
  const { t } = useTranslation("config")
  return (
    <button
      className={configStatus === "Creating" ? "button primary icon-left" : "button primary"}
      onClick={submitQuadriProject}
      disabled={isButtonDisabled}
      title={configStatus === "Active" ? t("quadriHeader.updateTooltip") : undefined}
    >
      {configStatus === "Syncing" ? (
        <>
          <i className="icon-font tc-icon-spinner-light"></i>
          {t("quadriHeader.updatingQuadri")}
        </>
      ) : configStatus === "Creating" ? (
        <>
          <i className="icon-font tc-icon-spinner-light"></i>
          {t("quadriHeader.enablingQuadri")}
        </>
      ) : configStatus === "Active" ? (
        t("quadriHeader.update")
      ) : (
        t("quadriHeader.saveAndEnable")
      )}
    </button>
  )
}

export default QuadriHeader
