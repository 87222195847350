import {LitElement, html} from 'lit-element';
import getRequestAsPromise from '@twebcomponents/web-components-common/js/xhttp/getRequestAsPromise.js';
import TranslationManager from '@twebcomponents/web-components-common/js/utilities/translationManager.js';
import TDatepicker from '@twebcomponents/web-components-common/js/dom/tdatepicker';
import addLeadingZero from '@twebcomponents/web-components-common/js/filters/addLeadingZero';

class DateRangePicker extends LitElement {

  render() {
    return html`<div class="filter-type ${this.isFormField ? 'block' : ''}">
      <div style="display:none">
      {{COMPONENT_CSS}}
      </div>      
      <div id="defaultStyles"></div>
      <div id="customStylesFromUrl"></div>      
      <div id="customStyles"></div>
      <style>
      .date-picker .dates-group div.empty:hover {
        background: unset;
        cursor: default;
      }
      </style>      
      
      ${this.stylesLoaded?html`
        <div class="select-menu dropdown-pane-container ${this.isShowDateRange?'active':''}">
            <div class="name dropdownpane-link" @click="${this.showDateRange}">
              <div>
                <div>
                  ${this.getDefaultDateLabel(this.selection.from, this.selection.to, 'Label')} 
                </div>
              </div>                                                            
              <div>
                <i class="icon-font tc-icon-arrow-drop-down"></i>
              </div>
            </div>
            <div class="dropdown-pane date-filter ${this.isShowDateRange?'is-open':''} dropdown"> 
              <div class="select-menu-item custom-date  ${this.isShowFromToDates?'active':''}">
                <div class="title" @click="${this.ShowFromToDates}">
                  <h6 class="text-meta">${this.tm.translate("Title")}</h6>																		
                  <button class="button tertiary icon-medium icon-circle"><i class="icon-font ${this.isShowFromToDates?'tc-icon-content-close':'tc-icon-content-add'}"></i></button>
                </div>
              </div>
              <div class="custom-date-fields">                                                                                                                                     
                <div class="">
                  <div class="input-group">
                      <label id="from_date_label">
                      ${this.tm.translate("From")} 
                      </label>
                      <div class="input-button-group" id="from_date_container">
                          <!--<input type="date" placeholder="mm/dd/yy">
                          <div class="line"></div>
                          <button class="button tertiary icon-large icon-square">
                            <i class="icon-font tc-icon-calendar"></i>
                          </button>-->
                          <input type="text" placeholder="${ this.tm.translate('DEFAULT_DATE_FORMAT') }"  
                            id="from_date"
                            value="${this.defaultFromDate}" 
                            class="${this.hasInvalidDate('from_date')? 'error': ''}"
                            @click="${(e) => this.openDefaultDatePicker('from_date', e)}"
                            @change="${(e) => this.closeDefaultDatePicker('from_date', 'from_date_label', 'FROM', e)}">
                          <div class="line"></div>
                          <button class="button tertiary icon-large icon-square" 
                            @click="${(e) => this.toggleDefaultDatePicker('from_date', 'from_date_container', 'from_date_label', e)}">
                              <i class="icon-font tc-icon-calendar"></i>
                          </button>
                      </div>
                      ${ this.hasInvalidDate('from_date') ? html`<span class="error-message">${this.tm.translate('ENTER_VALID_DATE')}</span>`: ''}
                  </div>
                  <div class="input-group">
                      <label  id="to_date_label">
                      ${this.tm.translate("To")} 
                      </label>
                      <div class="input-button-group" id="to_date_container">
                          <!--<input type="date" placeholder="mm/dd/yy">
                          <div class="line"></div>
                          <button class="button tertiary icon-large icon-square">
                            <i class="icon-font tc-icon-calendar"></i>
                          </button>-->
                          <input type="text" placeholder="${ this.tm.translate('DEFAULT_DATE_FORMAT') }"  
                            id="to_date"
                            value="${this.defaultToDate}"  
                            class="${this.hasInvalidDate('to_date') || this.hasInvalidToDate() ? 'error': ''}"
                            @click="${(e) => this.openDefaultDatePicker('to_date', e)}"
                            @change="${(e) => this.closeDefaultDatePicker('to_date', 'to_date_label', 'TO', e)}">
                          <div class="line"></div>
                          <button class="button tertiary icon-large icon-square" 
                            @click="${(e) => this.toggleDefaultDatePicker('to_date', 'to_date_container', 'to_date_label', e)}">
                              <i class="icon-font tc-icon-calendar"></i>
                          </button>
                      </div>
                      ${ this.hasInvalidDate('to_date') ? html`<span class="error-message">${this.tm.translate('ENTER_VALID_DATE')}</span>`: ''}
                      ${ this.hasInvalidToDate() ? html`<span class="error-message">${this.tm.translate('InvalidToDate')}</span>`: ''}																			
                  </div>
                </div>
              </div>
          
          <hr>
          <ul>
          <li>
              <div class="select-menu-item" @click="${(e) => this.updateSelection('TODAY', 'TODAY', e)}">
                <div class="icon radio">
                    <input class="custom-input" name="filter" type="radio" id="today" .checked="${this.isTodaySelected}" />
                    <label></label>                                                                 
                </div>
                <label for="today">${this.tm.translate("Today")}</label>                                                
              </div>                                        
            </li>    
            <li>
              <div class="select-menu-item" @click="${(e) => this.updateSelection('YESTERDAY', 'YESTERDAY', e)}">
                <div class="icon radio">
                    <input class="custom-input" name="filter" type="radio" id="yesterday" .checked="${this.isYesterdaySelected}" />
                    <label></label>                                                                 
                </div>
                <label for="yesterday">${this.tm.translate("Yesterday")}</label>                                                
              </div>                                        
            </li>    
            <li>
              <div class="select-menu-item" @click="${(e) => this.updateSelection('PAST7DAYS', 'PAST7DAYS', e)}">
                <div class="icon radio">
                    <input class="custom-input" name="filter" type="radio" id="seven" .checked="${this.isPast7DaysSelected}" />
                    <label></label>                                                                 
                </div>
                <label for="seven">${this.tm.translate("PastSevenDays")}</label>                                                                                             
              </div>                                            
            </li>
            <li>
              <div class="select-menu-item" @click="${(e) => this.updateSelection('PAST30DAYS', 'PAST30DAYS', e)}">
                <div class="icon radio">
                    <input class="custom-input" name="filter" type="radio" id="thirty" .checked="${this.isPast30DaySelected}" />
                    <label></label>                                                                 
                </div>
                <label for="thirty">${this.tm.translate("PastThirtyDays")}</label>                                                                                    
              </div>                                            
            </li>
            <!--<li>
              <div class="select-menu-item">
                <div class="icon radio">
                    <input class="custom-input" name="filter" type="radio" id="all" />
                    <label></label>                                                                 
                </div>
                <label for="all">All time</label>
              </div>                                                
            </li>-->                                                          
            <li>
              <button class="button link-primary ${this.defaultFromDate || this.defaultToDate?'':'disabled'}" @click="${(e) => this.resetAll()}">${this.tm.translate("Reset")}</button>
            </li>                                                                 
          </ul>
              
            </div>
            <div class="line"></div>
        </div>

        
        `:html`<div><i class="icon-font tc-icon-spinner-dark"></i></div>`}
        </div> 
      `;
  }

//${this.selection || this.selection.to || ''}

  static get properties() {
    return {
      /* translationSource: To provide an array of translation sources. Each item in the list can either be a translation url or a translation dictionary. For example, the translation source can be defined as below.
      var translationUrl = "translations.json";

      var translationDictionary = {
      "componentTitle1": "Object picker",
      "launchButtonText": "Select",
      "browseDevice": "Browse device photos",
      "submit1": "Select"
      };


      var translationSource = [
        {
            "type": "URL",
            "value": translationUrl
        },
        {
            "type": "DICTIONARY",
            "value": translationDictionary
        }
      ];
      */
      translationSource: {
        type: Object,
        attribute: "translation-source",
        reflect: false
      },
      /* translationKeyMap: To provide translation 'custom translation key' - 'default translation key' mapping. This is useful when the component's translation key is different from the one mentioned in the cutome translation key */
      translationKeyMap: {
        type: Object,
        attribute: "translation-key-map",
        reflect: false
      },
      /* customStyleUrl: To provide custom stylesheet url */
      customStyleUrl: { 
        type: String,
        attribute: "custom-style-url",
        reflect: false
      },
      /* customStyle: To provide custom style */
      customStyle: { 
        type: String,
        attribute: "custom-style",
        reflect: false
      },
      /* selection: This is used to provide default selection when the components loads. This attribute also reflects the realtime selection made in the component. So, the consumer application can use this if required */
      selection: {
        type: Object,
        attribute: "selection",
        reflect: true
      },
      isFormField: {
        type: Boolean,
        attribute: "is-form-field",
        reflect: true
      }
    };
  }

  constructor(defaultAttributes) {
    super();
    this.componentName = 'date-range-picker';

    this.translationSource = [];
    this.translationKeyMap = {};
    this.customStyle = '';
    this.customStyleUrl = '';
    this.selection = {
      from: null,
      to: null
    };

    this.defaultFromDate = '';
    this.defaultToDate = '';
    this.isShowFromToDates = false;

    this.defaultTranslationDictionary = {
      "Label": "Date",
      "Title": "Custom Date",
      "From": "from",
      "To": "to",
      "Today": "Today",
      "Yesterday": "Yesterday",
      "PastSevenDays": "Past 7 Days",
      "PastThirtyDays": "Past 30 days",
      "Reset": "Reset",
      "InvalidToDate": "TO Date should be greater than FROM Date"
    };
    this.stylesLoaded = false;

    this.tm = new TranslationManager(null, null, this.defaultTranslationDictionary);

    defaultAttributes = defaultAttributes || {};
    Object.keys(defaultAttributes).forEach(function(attrKey){
      if( defaultAttributes[attrKey] != null ) {
        this.setAttribute(attrKey, defaultAttributes[attrKey]);
      }
    }.bind(this));
  }
  
  connectedCallback() {
    console.log("connectedCallback");
    super.connectedCallback();
    this.addEventListener("on-window-resize", function(e){
      this.dispatchEvent(new CustomEvent('on-container-height-changed', {detail: {}}));
    }.bind(this));
    this.dispatchEvent(new CustomEvent('on-component-ready', {detail: {source: "date-range-picker"}}));

    this.getStyles();

    this.getCustomStyles();

    if(window.document.addEventListener)
      window.document.addEventListener("click", (evt) => this.documentClick(evt, this), false);
    else
      window.document.attachEvent("onclick", this.documentClick).bind(this);
    
    this.addEventListener("on-check-collapse", function(e){
      this.checkCollapse(e);
    }.bind(this));      
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if(newValue && newValue != 'null' && newValue.indexOf('{{') == -1){
      this.translationSource = (name == 'translation-source')? (newValue ? this.parseJSON(newValue, []) : []) : this.translationSource;
      this.translationKeyMap = (name == 'translation-key-map')? (this.isNonEmptyObject(this.parseJSON(newValue, {})) ? this.parseJSON(newValue, {}) : {}) : this.translationKeyMap;

      this.customStyleUrl = (name == 'custom-style-url')? newValue : this.customStyleUrl;
      this.customStyle = (name == 'custom-style')? newValue : this.customStyle;

      this.selection = (name == 'selection')? (newValue ? this.parseJSON(newValue, []) : []) : this.selection;
      this.isFormField = name === 'is-form-field' ? (newValue === "true" ? true : false) : this.isFormField;
      
      if(name == "translation-source"){
        this.tm.setTranslationSource(this.translationSource);
      }

      if(name == "translation-key-map"){
        this.tm.setTranslationKeyMap(this.translationKeyMap);
      }

      if(name == 'selection'){
        this.setDefaultDates();
        this.dispatchEvent(new CustomEvent('selection-changed', {detail: {source: "date-range-picker", data: this.selection}}));
      }
      this.requestUpdate();
    }
  }

  parseJSON(value, defaultValueOnParseFailure){
    var result = null;
    if (typeof value === "object") {
      result = value;
    } else {
      try {
        result = JSON.parse(value);
      }
      catch (err) {
        if(defaultValueOnParseFailure){
          result = defaultValueOnParseFailure;
        }else{
          throw err;
        }
      }
    }

    return result;
  }

  getDefaultDateLabel(from, to, defaultValue){
    var result = this.tm.translate(defaultValue) || '';
    if(this.isTodaySelected){
      result = this.tm.translate("Today");
    }else if(this.isYesterdaySelected){
      result = this.tm.translate("Yesterday");
    }else if(this.isPast7DaysSelected){
      result = this.tm.translate("PastSevenDays");
    }else if(this.isPast30DaySelected){
      result = this.tm.translate("PastThirtyDays");
    }else if(from && to){
      result = this.formatDate(from, 'mm/dd/yyyy') + " - " + this.formatDate(to, 'mm/dd/yyyy');
    }else if(from){
      result = this.tm.translate("From") + " " + this.formatDate(this.selection.from, 'mm/dd/yyyy');
    }else if(to){
      result = this.tm.translate("To") + " " + this.formatDate(this.selection.to, 'mm/dd/yyyy');
    }
    return result;
  }

  resetAll(){
    if(this.selection.from != null || this.selection.to != null){
      this.resetPredefinedDateRanges();
      this.selection = {
        from: null,
        to: null
      };
      this.defaultFromDate = '';
      this.defaultToDate = '';
      this.shadowRoot.querySelector('#from_date').value = '';
      this.shadowRoot.querySelector('#to_date').value = '';
      this.isShowDateRange = false;
      this.requestUpdate();
      this.dispatchEvent(new CustomEvent('date-range-selected', {detail: {source: "date-range-picker", data: this.selection}}));    
    }
  }

  reApplyDatePicker(){
    this.applyDatePicker('from_date', 'from_date_container', 200);
    this.applyDatePicker('to_date', 'to_date_container', 300);
  }
  resetPredefinedDateRanges(){
    this.isTodaySelected = false;
    this.isYesterdaySelected = false;
    this.isPast7DaysSelected = false;
    this.isPast30DaySelected = false;
  }

  setDefaultDates(){
    this.defaultFromDate = (this.selection && this.selection.from && this.formatDate(this.selection.from, 'mm-dd-yyyy')) || '';
    this.defaultToDate = (this.selection && this.selection.to && this.formatDate(this.selection.to, 'mm-dd-yyyy')) || '';
    if( this.selection.from == null && this.selection.to == null  ) {
      this.resetPredefinedDateRanges();
    }
    this.requestUpdate("defaultFromDate");
    this.requestUpdate("defaultToDate");
  }

  updateSelection(value, type, e){
    e.stopPropagation();
    console.log("updateSelection = ", value, type);

    let temp = new Date().getTime();
    let from = null, to = null;
    if(type == "FROM" || type == "TO"){
      if(this.shadowRoot.querySelector("#" + value)){
        let selectedValue = this.shadowRoot.querySelector("#" + value).value;
        if(!this.isValidDate(selectedValue)){
          return false;
        }
      }
    }

    switch(type){
      case "FROM":
        if(this.shadowRoot.querySelector("#" + value)){
          value = this.shadowRoot.querySelector("#" + value).value;
          //console.log("from_date=", value);
          if( value) {  
            value = value.replace(/-/g, '/');//mm-dd-yyyy
            from = new Date(new Date(value).setHours(0,0,0));
            this.selection.from = !isNaN(from.getTime())? from.toString() : null;
          } else {
            this.selection.from = null;
          }
          this.resetPredefinedDateRanges();
        }
        break;
      case "TO":
        if(this.shadowRoot.querySelector("#" + value)){
          value = this.shadowRoot.querySelector("#" + value).value;
          //console.log("to_date=", value);
          if( value && !this.hasInvalidToDate()) {  
            value = value.replace(/-/g, '/');//mm-dd-yyyy
            to = new Date(new Date(value).setHours(0,0,0));
            this.selection.to = !isNaN(to.getTime())? to.toString() : null;
          } else {
            this.selection.to = null;
          }
          this.resetPredefinedDateRanges();
        }
        break;
      case "TODAY":
        from = new Date();
        to = new Date();
        from = new Date(from.setHours(0, 0, 0));
        to = new Date(to.setHours(0, 0, 0));
        this.selection.from = from.toString();
        this.selection.to = to.toString();
        this.resetPredefinedDateRanges();
        this.isShowDateRange = false;
        this.isTodaySelected = true;
        break;
      case "YESTERDAY":
        from = new Date();
        to = new Date();
        from = new Date(from.setHours( 0, 0, 0));
        from = new Date(from.setDate(from.getDate() - 1));
        to = new Date(to.setHours( 0, 0, 0));
        to = new Date(to.setDate(to.getDate() - 1));
        this.selection.from = from.toString();
        this.selection.to = to.toString();
        this.resetPredefinedDateRanges();
        this.isShowDateRange = false;
        this.isYesterdaySelected = true;
        break;
      case "PAST7DAYS":
        from = new Date(temp);
        to = new Date(temp);
        from = new Date(from.setHours( 0, 0, 0));
        from = new Date(from.setDate(from.getDate() - 7));
        to = new Date(to.setHours(0, 0, 0));
        this.selection.from = from.toString();
        this.selection.to = to.toString();
        this.resetPredefinedDateRanges();
        this.isShowDateRange = false;
        this.isPast7DaysSelected = true;
        break;
      case "PAST30DAYS":
        from = new Date(temp);
        to = new Date(temp);
        from = new Date(from.setHours( 0, 0, 0));
        from = new Date(from.setDate(from.getDate() - 30));
        to = new Date(to.setHours(0, 0, 0));
        this.selection.from = from.toString();
        this.selection.to = to.toString();
        this.resetPredefinedDateRanges();
        this.isShowDateRange = false;
        this.isPast30DaySelected = true;
        break;
    }
    this.requestUpdate("selection");
    this.setDefaultDates();    
    this.requestUpdate();
    this.dispatchEvent(new CustomEvent('date-range-selected', {detail: {source: "date-range-picker", data: this.selection}}));
  }

  isNonEmptyObject(obj){
    //console.log("obj=", obj);
    var result = false;
    try{
      obj = typeof obj == "object" ? obj : JSON.parse(obj);
      result = Object.keys(obj).length > 0;
    }catch(err){
      result = false;
      console.log("error in isNonEmptyObject check", err);
    }

    //console.log("isNonEmptyObject = ", result);
    return result;
  }

  applyDatePicker(fieldName, container, timeOut, defaultValue) {
    window.setTimeout( (e) => {
      if( this.shadowRoot.querySelector('#' + fieldName) ) {
        TDatepicker(this.shadowRoot.querySelector('#' + fieldName), {
          //mode: 'dp-below',
          dateFormat: 'mm-dd-yyyy',
          //defaultValue: new Date(defaultValue),
          appendTo: this.shadowRoot.querySelector('#' + container),
          translations: this.tm && this.tm.translationDictionary ? this.tm.translationDictionary : {}
        });
      }
    }, timeOut);
    this.requestUpdate();
  }
  isValidDate(dateString){
    if(!dateString) {
      return true;
    }
    var dateArray = dateString.split("-");
    if( !dateString || isNaN(dateArray[0]) || isNaN(dateArray[1]) || isNaN(dateArray[2])  ) {
      return false;
    } else {
      var d = new Date(dateArray[2], parseInt(dateArray[0]) -1, dateArray[1]);
      if( isNaN(d.getTime())  ) {
        return false;
      }
      return true;
    }
  }

  formatDate(actualDate, format) {
    var ad = new Date(actualDate);
    var mm = addLeadingZero( ad.getMonth() + 1, 2);
    var dd = addLeadingZero( ad.getDate(), 2);
    var yyyy = ad.getFullYear();
    var yy = parseInt(yyyy.toString().substr(3));
    return format.replace('mm', mm).replace('dd', dd).replace('yyyy', yyyy).replace('yy', yy);
  }

  hasInvalidDate(fieldName){
    var result = null;
    if(this.shadowRoot.querySelector('#' + fieldName)){
      var val = this.shadowRoot.querySelector('#' + fieldName).value;
      result = !this.isValidDate(val);
    }
    return result;
  }

  hasInvalidToDate(){
    if(this.shadowRoot.querySelector('#from_date') && this.shadowRoot.querySelector('#to_date')){
      var fromDate = this.shadowRoot.querySelector('#from_date').value;
      fromDate = this.getDateFromString(fromDate);
      var toDate = this.shadowRoot.querySelector('#to_date').value;
      toDate = this.getDateFromString(toDate);
      if( fromDate && toDate && toDate < fromDate ) {
        return true;
      }
      return false;
    }
    return false;
  }
  getDateFromString(dateString) {
    var dateArray = dateString.split("-");
    if( !dateString || isNaN(dateArray[0]) || isNaN(dateArray[1]) || isNaN(dateArray[2])  ) {
      return false;
    } else {
      var d = new Date(dateArray[2], parseInt(dateArray[0]) -1, dateArray[1]);
      if( isNaN(d.getTime())  ) {
        return false;
      }
      return d;
    }
  }
  openDefaultDatePicker(fieldName, event) {
    event.preventDefault();
    event.stopPropagation();
    this.hideAllCalendars();
    var dateValue = this.shadowRoot.getElementById(fieldName).value;
    if( !this.isValidDate(dateValue) ) {
      this.shadowRoot.getElementById(fieldName) && (this.shadowRoot.getElementById(fieldName).value = '');
    } else {
      TDatepicker(this.shadowRoot.querySelector('#' + fieldName), {
        //mode: 'dp-below',
        dateFormat: 'mm-dd-yyyy',
        //defaultValue: new Date(defaultValue),
        appendTo: this.shadowRoot.querySelector('#' + fieldName + '_container'),
        translations: this.tm && this.tm.translationDictionary ? this.tm.translationDictionary : {}
      }).showCalender(event);
    }
    this.shadowRoot.getElementById(fieldName) && this.shadowRoot.getElementById(fieldName).focus();
  }  
  closeDefaultDatePicker(fieldName, nonInputElement, type, event) {
    if( !this.shadowRoot.getElementById(fieldName).value ) {
      if(fieldName == 'from_date') {
        this.selection.from = null;
      } else {
        this.selection.to = null;
      }
      this.resetPredefinedDateRanges();
      this.requestUpdate('selection');
      this.dispatchEvent(new CustomEvent('date-range-selected', {detail: {source: "date-range-picker", data: this.selection}}));
    } 
    this.shadowRoot.getElementById(nonInputElement) && this.shadowRoot.getElementById(nonInputElement).focus();
    window.setTimeout( (e) => {
      var tmpDate = this.shadowRoot.getElementById(fieldName).value || null;
      var selectedDate = (type == 'FROM')? this.selection.from: this.selection.to;
      if( tmpDate && tmpDate != this.formatDate(selectedDate, "mm-dd-yyyy") ) {
        this.updateSelection(fieldName, type, event);
      }
    }, 200);
    
  }
  hideAllCalendars(){
    this.shadowRoot.getElementById('from_date_label') && this.shadowRoot.getElementById('from_date_label').click();
    this.shadowRoot.getElementById('to_date_label') && this.shadowRoot.getElementById('to_date_label').click();
  }
  toggleDefaultDatePicker(fieldName, container, nonInputElement, event) {
    event.preventDefault();
    event.stopPropagation();
    //this.hideAllCalendars();
    if( this.shadowRoot.querySelector("#" + container + " .date-picker") ) {
      this.shadowRoot.getElementById(nonInputElement) && this.shadowRoot.getElementById(nonInputElement).click();
    } else {
      this.shadowRoot.getElementById(fieldName) && this.shadowRoot.getElementById(fieldName).click();
    }
  }
 
  firstUpdated(changedProps) {
    super.update(changedProps);
  }

  ShowFromToDates(e){
    this.isShowFromToDates = !this.isShowFromToDates;
    this.defaultFromDate = (this.selection && this.selection.from && this.formatDate(this.selection.from, 'mm-dd-yyyy')) || '';
    this.defaultToDate = (this.selection && this.selection.to && this.formatDate(this.selection.to, 'mm-dd-yyyy')) || '';
    this.shadowRoot.querySelector('#from_date').value = this.defaultFromDate;
    this.shadowRoot.querySelector('#to_date').value = this.defaultToDate;
    this.requestUpdate("isShowFromToDates");
  }

  showDateRange(e){
    if(e.key == "Escape"){
      this.isShowDateRange = false;
    } else{
      this.isShowDateRange = !this.isShowDateRange;
      this.isShowFromToDates = false;
      this.requestUpdate();
    }
  }

  documentClick(e){
    //console.log("target1 = ", e.target);
    this.dispatchEvent(new CustomEvent('on-check-collapse', {detail: {from:e.target.id}}));
  }

  checkCollapse(e){
    //console.log("target = ", e.target);
    if(e.detail.from !== this.id){
      //console.log("hide");
      this.isShowDateRange = false;
      this.requestUpdate();
    }
  }

  async getStyles(){
    var resultIconStyle = "";

    resultIconStyle = await getRequestAsPromise("GET", "{{RESOURCE_DOMAIN}}/fonts/icon-font.min.css", null);
    var nodeIcons = document.createElement('style');
    nodeIcons.innerHTML = resultIconStyle;
    this.shadowRoot.querySelector("#defaultStyles").appendChild(nodeIcons);

    var nodeFontStyle = document.createElement('style');
    nodeFontStyle.innerHTML = `@font-face {
      font-family: 'tcw_svg';
      src: url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.eot");
      src: url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.eot#iefix") format("embedded-opentype"), url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.ttf") format("truetype"), url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.woff") format("woff"), url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.svg#tcw_svg") format("svg");
      font-weight: normal;
      font-style: normal; 
    }	`;
    window.document.head.appendChild(nodeFontStyle);

    this.stylesLoaded = true;
    this.requestUpdate();
  }

  async getCustomStyles(){
    if(this.customStyleUrl){
      var result = "";
      var customStylesFromUrlNode = document.createElement('style');
      result = await getRequestAsPromise("GET", this.customStyleUrl, null);
      customStylesFromUrlNode.innerHTML = result;
      this.shadowRoot.querySelector("#customStylesFromUrl").appendChild(customStylesFromUrlNode);
      this.requestUpdate();
    }
    
    if(this.customStyle){
      var customStylesNode = document.createElement('style');
      customStylesNode.innerHTML = this.customStyle;
      this.shadowRoot.querySelector("#customStyles").appendChild(customStylesNode);
      this.requestUpdate();
    }
  }
}

console.log("is date-range-picker already registered = ", !!customElements.get('date-range-picker'));
if(!customElements.get('date-range-picker')){
  customElements.define('date-range-picker', DateRangePicker);
  console.log("is date-range-picker successfully registered = ", !!customElements.get('date-range-picker'));
}

export default DateRangePicker;
window.DateRangePicker = DateRangePicker;