import React, { useRef, useEffect } from "react"
require("@connect/type-picker/build/type-picker")

export interface ITypeTranslationSource {
  type: "DICTIONARY"
  value: any
}

export interface ITypePicker {
  onChange: (event: Event, which: string) => void
  typeList: any[]
  selectionList: any[]
  id: string
  translationSource: ITypeTranslationSource[]
  name?: string
}

const TypePicker = ({ onChange, typeList, selectionList, id, translationSource, name }: ITypePicker) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    //Hook's version of ComponentDidMount
    if (ref.current) {
      ref.current.addEventListener("selection-changed", (e: Event) => onChange(e, name ? name : "type-picker"))
    }

    //Hook's version of ComponentWillUnmount
    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current.removeEventListener("selection-changed", (e: Event) => onChange(e, name ? name : "type-picker"))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <type-picker
      ref={ref}
      selection-list={`${JSON.stringify(selectionList)}`}
      id={id}
      type-list={`${JSON.stringify(typeList)}`}
      translation-source={JSON.stringify(translationSource)}
    />
  ) as any
}

export default React.memo(TypePicker)
