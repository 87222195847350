import React from "react"
import { useTranslation } from "react-i18next"
import Skeleton from "react-skeleton-loader"

type OptionProps = React.HTMLProps<HTMLOptionElement>

interface SelectProps extends React.HTMLProps<HTMLSelectElement> {
  label: string
  options: OptionProps[]
  loading?: boolean
}

export const Select = (props: SelectProps) => {
  const { t } = useTranslation("common")
  return (
    <div>
      <div className="input-group">
        <label htmlFor={props.label}>
          <span>{props.label}</span>
          {props.required && <span className="error-message"> *</span>}
        </label>
        <div className="input-focus-group">
          {props.loading ? (
            <Skeleton
              // This should be 2.5 rem, but decimals are not accepted by the component...
              // TODO: Switch to better skeleton rendering library
              height={"2rem"}
              color={"#EAEAEF"}
              width={"100%"}
              heightRandomness={0} // These need to be set, or the component will add randomness
              widthRandomness={0} // ^^^^^^^^^^^^^^^^^^^^^^^^
            />
          ) : (
            <select
              id={props.label}
              name={props.name || props.label}
              placeholder={props.placeholder || props.label}
              value={props.value || ""}
              onChange={props.onChange || undefined}
              autoComplete="off"
              disabled={props.disabled || false}
              required={props.required || false}
            >
              <option disabled={true} value="">
                -- {t("optionPrompt")} --
              </option>
              {props.options.map((option: OptionProps, i: number) => (
                <option key={`select-input-${i}`} {...option}>
                  {option.label}
                </option>
              ))}
            </select>
          )}
          <div className="line" />
        </div>
      </div>
    </div>
  )
}
