export const versionSameOrHigherSameMajor = (v0string: string, v1string: string) => {
  const [v0, v1] = [vParse(v0string), vParse(v1string)]
  return v0.major === v1.major && v0.minor >= v1.minor
}

// Could not use semver package for this since the version strings are not standard
export const versionIsSameOrHigher = (v0string: string, v1string: string) => {
  const [v0, v1] = [vParse(v0string), vParse(v1string)]
  return v0.major > v1.major || (v0.major === v1.major && v0.minor >= v1.minor)
}

// Parse a version string into { major: number, minor: number }
const vParse = (version: string) => {
  const parts = version.split(".")
  if (parts.length !== 2) {
    throw new Error(`Version string "${version}" is not valid. It should be two integers separated by a dot '.'`)
  } else {
    const [major, minor] = parts.map((n) => parseInt(n, 10))
    if (isNaN(major) || isNaN(minor)) {
      throw new Error(`Failed to parse version ${version}. Version parts need to be valid integers`)
    } else {
      return { major, minor }
    }
  }
}
