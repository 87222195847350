import React from "react"
import { useQuery } from "@apollo/client"
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil"
import { PendingSpinner } from "../Pending"
import { quadriProjectIdState, sidebarWorksetState, userProjectRoleState } from "../StateSelectors"
import { GET_WORKSET_TASKS } from "../../api/QuadriApi"
import DisconnectModal from "./DisconnectModal"
import { formatDateAsConnect } from "../../util/dateTools"
import { useHistory } from "react-router-dom"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"
import Oops from "../Oops/Oops"
import { useTranslation } from "react-i18next"
import { exceptionFromApolloError } from "../../util/apolloError"

const MAX_TASKS = 20

const WorksetsSidebar = () => {
  const quadriProjectId = useRecoilValue(quadriProjectIdState)
  const [sidebar, setSidebar] = useRecoilState(sidebarWorksetState)
  const userProjectRole = useRecoilValueLoadable(userProjectRoleState)
  const [tabActive, setTabActive] = React.useState(0)
  const [showDisconnectModal, setShowDisconnectModal] = React.useState(false)
  const showModal = () => setShowDisconnectModal(true)
  const closeModal = () => setShowDisconnectModal(false)
  const { t } = useTranslation("worksets")

  if (sidebar && userProjectRole.contents && quadriProjectId) {
    const buttonDisconnectWorkset = showModal

    const reservedTaskIds = sidebar.reservedTaskIds || []

    let activity = [...sidebar.activity!] || []
    if (activity.length > 0) {
      activity.sort((a, b) => new Date(b.time).valueOf() - new Date(a.time).valueOf())
    }

    const userIsNotAdmin = userProjectRole.contents !== "ADMIN"

    console.log("userProjectRole: ", userProjectRole.contents)

    return (
      <div className="panel">
        {showDisconnectModal && (
          <DisconnectModal
            closeModal={closeModal}
            worksetName={sidebar.name}
            worksetId={sidebar.id}
            quadriProjectId={quadriProjectId}
            user={sidebar.fullName}
          />
        )}
        <header>
          <div className="panel-title">
            <h3>{sidebar.name}</h3>
          </div>
          <button className="button icon-circle icon-medium tertiary" onClick={() => setSidebar(null)}>
            <i className="icon-font tc-icon-content-close" />
          </button>
        </header>

        <section>
          <div className="panel-body">
            <div className="sub-section pb-1 border-bottom">
              <div className="section-details pb-1">
                <div className="label-group">
                  <label>{t("sidebar.created")}</label>
                  <div className="value">
                    {formatDateAsConnect(sidebar.createdTime)} {t("sidebar.by")} {sidebar.fullName}
                  </div>
                </div>
                <div className="label-group">
                  <label>{t("sidebar.lastActivity")}</label>
                  <div className="value">{formatDateAsConnect(activity[0].time) || "none"}</div>
                </div>
              </div>
            </div>

            <div className="sub-section">
              <ul className="tabs mb-0">
                <li
                  className={`tabs-title text-ellipsis ${tabActive === 0 && "active"}`}
                  onClick={() => setTabActive(0)}
                >
                  <span className="text-ellipsis px-1">
                    {t("sidebar.reservedTasks")}
                    {reservedTaskIds && reservedTaskIds.length > 0 ? ` (${reservedTaskIds.length})` : ""}
                  </span>
                </li>
                {/* <li
                  className={`tabs-title text-ellipsis ${tabActive === 1 && "active"}`}
                  onClick={() => setTabActive(1)}
                >
                  <span className="text-ellipsis px-1">
                    Objects{sidebar.reservedFeatures ? ` (${sidebar.reservedFeatures.length})` : ""}
                  </span>
                </li> */}
                <li
                  className={`tabs-title text-ellipsis ${tabActive === 2 && "active"}`}
                  onClick={() => setTabActive(2)}
                >
                  <span className="text-ellipsis px-1">{t("sidebar.activity")}</span>
                </li>
              </ul>

              <div className="tabs-content">
                <div className={`tabs-panel ${tabActive === 0 && "active"}`}>
                  <ErrorBoundary FallbackComponent={TasksErrorFallback}>
                    <TasksList quadriProjectId={quadriProjectId} reservedTaskIds={reservedTaskIds} />
                  </ErrorBoundary>
                </div>
                {/* <div className={`tabs-panel ${tabActive === 1 && "active"}`}>
                  <ul className="list">
                    {sidebar.reservedFeatures &&
                      sidebar.reservedFeatures.length > 0 &&
                      sidebar.reservedFeatures.map((o: any, i: number) => (
                        <li key={`tasks-panel-item-${i}`} className="list-item">
                          {o.featureTypeName}
                        </li>
                      ))}
                  </ul>
                </div> */}
                <div className={`tabs-panel ${tabActive === 2 && "active"}`}>
                  <div className="table-list">
                    {activity &&
                      activity.length > 0 &&
                      activity.map((a: any, i: number) => (
                        <div className="row table-list-item" key={`a-li-${i}`}>
                          <div className="col">
                            <div>
                              <b>{a.type}</b> &bull; {a.description}
                            </div>
                            <div className="sub-text">{formatDateAsConnect(a.time)}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {sidebar.status === "Connected" && (
          <footer className="px-2 py-2">
            {userIsNotAdmin ? (
              <button className="button icon-left link-warning full-width" disabled={true}>
                {t("sidebar.disconnectWorkset")}
              </button>
            ) : (
              <button className="button icon-left link-warning full-width" onClick={buttonDisconnectWorkset}>
                <i className="icon-font tc-icon-cloud-off"></i>
                {t("sidebar.disconnectWorkset")}
              </button>
              // <button className="button icon-left link-warning full-width" onClick={buttonDisconnectWorkset}>
              //   {deleting || disabled ? (
              //     <i className="icon-font tc-icon-spinner-dark"></i>
              //   ) : (
              //     <i className="icon-font tc-icon-cloud-off"></i>
              //   )}
              //   {deleting ? "Disconnecting Workset..." : disabled ? "Disabled, reloading page" : "Disconnect workset"}
              // </button>
            )}
          </footer>
        )}
      </div>
    )
  }

  return (
    <div className="panel">
      <PendingSpinner />
    </div>
  )
}

const TasksList = (props: { quadriProjectId: string; reservedTaskIds?: string[] }) => {
  const history = useHistory()
  const goToTask = (id: string) => history.push(`/tasks/${id}`)
  const { t } = useTranslation("worksets")

  const tasksTruncated = (props.reservedTaskIds?.length || 0) > MAX_TASKS
  const reservedTaskIds = tasksTruncated ? props.reservedTaskIds?.slice(0, MAX_TASKS) : props.reservedTaskIds

  console.log("requesting taskIds: ", reservedTaskIds)
  console.log("Tasks truncated: ", tasksTruncated)

  const { data, loading, error } = useQuery(GET_WORKSET_TASKS, {
    skip: !props.reservedTaskIds || props.reservedTaskIds.length < 1,
    variables: {
      quadriProjectId: props.quadriProjectId,
      taskIds: reservedTaskIds
    }
  })

  if (!data) {
    if (loading) {
      return (
        <div className="mt-2">
          <PendingSpinner />
        </div>
      )
    } else if (error) {
      throw exceptionFromApolloError(error)
    } else {
      return <></>
    }
  } else {
    console.log(data)
    const reservedTasks = data.getTasks
    return (
      <div className="table-list">
        {reservedTasks &&
          reservedTasks.length > 0 &&
          reservedTasks.map((task: any, i: number) => {
            console.log("workset task: ", task)
            const path = renderTaskPath(task)
            return (
              <div className="row table-list-item" onClick={() => goToTask(task.id)} key={`t-div-${task.id}`}>
                <div className="col">
                  <div className="text-ellipsis" title={task.name}>
                    {task.name}
                  </div>
                  <div className="sub-text text-ellipsis" title={path}>
                    {t("sidebar.path")}: {path}
                  </div>
                </div>
              </div>
            )
          })}
        {tasksTruncated && (
          <div className="row table-list-item" key="truncated-warning">
            <div className="col text-muted">...{t("sidebar.truncatedMessage")}...</div>
          </div>
        )}
      </div>
    )
  }
}

const renderTaskPath = (task: any) =>
  task.taskPath
    .slice()
    .reverse()
    .slice(1)
    .map((t: any) => t.name)
    .join("/")

const TasksErrorFallback = (props: FallbackProps) => {
  const { t } = useTranslation("worksets")
  return (
    <div className="mt-2">
      <Oops body={t("sidebar.tasksLoadError")} />
    </div>
  )
}

export default WorksetsSidebar
