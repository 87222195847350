import Skeleton from "react-skeleton-loader"
import { Activity } from "../../types"
import "./Timeline.css"
import { TimelineIcon } from "./TimelineIcon"
import { UserRow } from "../UserRow"
import { ConnectUser } from "../../api/ConnectApi"
import { formatDateAsConnect } from "../../util/dateTools"
import { getActivityDetails, getDescription } from "./util"

export interface TimelineCardProps {
  onSelect: (activity: Activity) => void
  active?: Activity // used to render "selected"
  activity: Activity
  connectProjectId: string
  users: { [key: string]: ConnectUser }
}

export const TimelineCard = ({
  onSelect,
  active,
  activity,
  activity: {
    type,
    time,
    // user: { id, name, organization }
    userId
  },
  connectProjectId,
  users
}: TimelineCardProps) => {
  const activeClass = active === activity ? " active" : ""
  const onSelectActivity = () => onSelect(activity)

  return (
    <div className={`module activity-list mb-1 p-4 pointer${activeClass}`} onClick={onSelectActivity}>
      <div className="d-none d-md-flex activity-time">
        <p className="text-meta">{formatDateAsConnect(time)}</p>
      </div>
      <div className="flex-row">
        <div className="d-none d-md-block activity-type">
          <TimelineIcon type={type} />
        </div>
        <div className="activity">
          <div className="d-block d-md-none mb-1">
            <small className="text-meta">
              {/* TODO: What's this? */}
              <Skeleton />
            </small>
          </div>
          <UserRow connectProjectId={connectProjectId} user={users[userId]} />
          <div className="mt-1">
            <p>
              {getActivityDetails(activity)}
              {getDescription(activity)}
            </p>
          </div>
        </div>
        {activity.taskChanges?.length && (
          <div className="vertical-center">
            <i className="icon-font tc-icon-chevron-right"></i>
          </div>
        )}
      </div>
    </div>
  )
}
