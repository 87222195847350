import * as oidc from "oidc-client"
import { ITokenProvider } from "../types"

export class TokenProvider {
  private userManager: oidc.UserManager
  private updateUser?: (user: ITokenProvider) => void

  constructor(private settings: oidc.UserManagerSettings) {
    this.userManager = new oidc.UserManager(this.settings)
    this.userManager.events.addAccessTokenExpiring(this.signinSilent)
  }

  // Called when the page loads
  public async signIn(updateUser: (user: ITokenProvider) => void): Promise<void> {
    this.updateUser = updateUser

    // First check if already signed in
    const user = await this.userManager.getUser()
    if (user) {
      if (this.userAccessTokenExpired(user)) {
        await this.signinRedirect()
      } else {
        console.log("signed in, user: ", user)
        this.onUpdateUser(user)
      }
    } else {
      await this.signinRedirect()
    }
  }

  private onUpdateUser(user: oidc.User) {
    this.updateLocalStorageUserWithoutRefreshToken(user)
    if (this.updateUser) this.updateUser(convertUser(user))
  }

  // Total hack to prevent the refresh token to be used to refresh tokens...
  private updateLocalStorageUserWithoutRefreshToken(user: oidc.User) {
    const { authority, client_id } = this.settings
    const localStorageAddress = `oidcuser:${authority}:${client_id}`
    const userWithoutRefreshToken = { ...user }
    delete userWithoutRefreshToken["refresh_token"]
    window.localStorage.setItem(localStorageAddress, JSON.stringify(userWithoutRefreshToken))
  }

  private signinSilent = async () => {
    try {
      console.log("signing in silent")

      // When not automatic silent refresh in settings:
      const user = await this.userManager.signinSilent()
      console.log("sign in silent, user: ", user)
      this.onUpdateUser(user)
    } catch (e) {
      // If cookie has expired, signin silent will throw "Frame window timed out"
      console.log("Redirecting to sign in because silent failed: ", e)
      await this.signinRedirect()
    }
  }

  private signinRedirect = async () => {
    await this.userManager.signinRedirect({ state: window.location.href })
    // The above line does not actually await until the redirect happens.
    // The below line waits for 10 seconds for the page to redirect
    await new Promise((resolve) => setTimeout(resolve, 10000))
  }

  private userAccessTokenExpired(user: oidc.User) {
    const nowUnix = Math.round(new Date().getTime() / 1000)
    const accessTokenRefreshTime = this.userManager.settings.accessTokenExpiringNotificationTime || 60
    const wouldRenew = user.expires_at - accessTokenRefreshTime
    return wouldRenew < nowUnix
  }
}

const convertUser = (oidcUser: oidc.User) => ({
  idToken: oidcUser.id_token,
  accessToken: oidcUser.access_token
})
