const locationToOrigin = (location: string) => {
  switch (location) {
    case "northAmerica":
      return "//app.connect.trimble.com"
    case "europe":
      return "//app21.connect.trimble.com"
    case "asia":
      return "//app31.connect.trimble.com"
    default:
      // Default to North America region
      return "//app.connect.trimble.com"
  }
}

export default locationToOrigin
