import React from "react"
import { useRecoilValue } from "recoil"
import { Route, Switch } from "react-router-dom"
import Routes, { RoutesType } from "./Routes"
import { sidebarTaskState, sidebarTimelineState, sidebarWorksetState } from "./StateSelectors"
import VersionTag from "./VersionTag"
import Environment from "../enviroments"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"
import Oops from "./Oops/Oops"

interface LayoutProps {
  children: React.ReactElement
}

const Layout = (props: LayoutProps) => {
  const sidebarTask = useRecoilValue(sidebarTaskState)
  const sidebarTimeline = useRecoilValue(sidebarTimelineState)
  const sidebarWorkset = useRecoilValue(sidebarWorksetState)

  return (
    <div className="wrapper">
      <div className="connectContent">
        <div className="pushContent">
          <main className="main">
            <div className="container-fluid" style={{ overflowY: "scroll" }}>
              <Switch>
                {Routes.map((r: RoutesType, i: number) => (
                  <Route key={`layout-main-panel-${i}`} exact={r.exact} path={r.path}>
                    {Environment.showVersionHeader && (
                      <div className="head-container mt-0">
                        <div className="page-heading mt-0">
                          <VersionTag />
                        </div>
                      </div>
                    )}
                  </Route>
                ))}
              </Switch>

              {props.children}
            </div>

            {(sidebarTask || sidebarTimeline || sidebarWorkset) && (
              <div className="d-none d-xl-block detailsPanelWrapper show-right-panel-small-screen">
                <ErrorBoundary FallbackComponent={SidepanelErrorFallback}>
                  <Switch>
                    {Routes.map((r: RoutesType, i: number) => (
                      <Route key={`layout-right-panel-${i}`} exact={r.exact} path={r.path}>
                        {r.sidebar}
                      </Route>
                    )).filter((item) => {
                      // Set up routing only to sidebars that have a valid sidebarXXX object to show
                      const modePrefix = item.props.path.substring(0, 5)
                      return (
                        (sidebarTask && modePrefix === "/task") ||
                        (sidebarTimeline && modePrefix === "/time") ||
                        (sidebarWorkset && modePrefix === "/work")
                      )
                    })}
                  </Switch>
                </ErrorBoundary>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  )
}

const SidepanelErrorFallback = (props: FallbackProps) => (
  <div className="col-12 align-center" style={{ height: "100%" }}>
    <Oops body={props.error.message} />
  </div>
)

export default Layout
