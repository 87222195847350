import Oops from "../Oops/Oops"
import { useTranslation } from "react-i18next"

interface TimelineErrorProps {
  activitiesQuery: any
}

export const TimelineError = (props: TimelineErrorProps) => {
  const { activitiesQuery } = props
  const { t } = useTranslation("timeline")
  return activitiesQuery.error ? (
    activitiesQuery.error.message.indexOf("access to this Quadri") !== -1 ? (
      <Oops body={t("error.needSync")} heading={activitiesQuery.error.message} />
    ) : (
      <Oops body={activitiesQuery.error.message} heading={t("error.fetchError")} />
    )
  ) : (
    <Oops />
  )
}
