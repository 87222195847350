import React from "react"
import { IColumn } from "./CommonTable"

interface IProps<IRow> {
  columns: IColumn[]
  list: IRow[]
  selectedRows: number[]
  rowClass: string
  lazyLoad: boolean
  index: number
  style: React.CSSProperties
  onRowSelect: (e: React.MouseEvent<HTMLElement>, index: number) => void
  onRowDbClick: (index: number) => void
  getComponent: (col: IColumn, row: IRow, rowIndex: number) => React.ReactNode
  getCellValue: (col: IColumn, row: IRow) => React.ReactNode
  getRowProps?: (index: number) => void
}

export default class TableRow<IRow> extends React.Component<IProps<IRow>> {
  public renderCell = (col: IColumn, j: number) => {
    const { wrapperTag } = col
    const Wrapper = wrapperTag ? wrapperTag.tag : undefined
    return (
      <div
        className={col.fieldClass}
        key={`td-${j}`}
        onDoubleClick={(e) => (!col.skipDbClick ? this.props.onRowDbClick(this.props.index) : e.preventDefault())}
      >
        {!!col.component ? (
          this.props.getComponent(col, this.props.list[this.props.index], this.props.index)
        ) : Wrapper && wrapperTag ? (
          <Wrapper className={wrapperTag.class} {...wrapperTag.props}>
            {this.props.getCellValue(col, this.props.list[this.props.index])}
          </Wrapper>
        ) : (
          this.props.getCellValue(col, this.props.list[this.props.index])
        )}
      </div>
    )
  }

  public render() {
    const props = this.props
    const rowProps = (this.props.getRowProps && this.props.getRowProps(props.index)) || {}
    return (
      <div
        className={`${props.rowClass ? props.rowClass : "row table-list-item"} ${
          !!props.selectedRows && props.selectedRows.includes(props.index) ? "active" : ""
        }`}
        style={props.style}
        onClick={(e) => props.onRowSelect(e, props.index)}
        {...rowProps}
      >
        {props.index === props.list.length - 1 && props.lazyLoad ? (
          <div className="center" style={{ width: "100%" }}>
            <i className="icon-font tc-icon-spinner-dark mr-1" />
          </div>
        ) : (
          props.columns.map((col, j) => this.renderCell(col, j))
        )}
      </div>
    )
  }
}
