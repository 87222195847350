import { atom, DefaultValue, selector } from "recoil"
import * as QueryString from "query-string"
import { TaskSidebar, TimelineSidebar, WorksetSidebar } from "../types"
import { getLoggedInUserProjectRole, getUsersByTidUuid } from "./stateQueries"
import { TCExtensionAPI } from "trimble-connect-project-workspace-api"

export const sidebarTaskState = atom({
  key: "sidebarTaskState",
  default: null as TaskSidebar | null
})

export const sidebarTimelineState = atom({
  key: "sidebarTimelineState",
  default: null as TimelineSidebar | null
})

export const sidebarWorksetState = atom({
  key: "sidebarWorksetState",
  default: null as WorksetSidebar | null
})

export const selectedTaskRow = atom({
  key: "selectedTask",
  default: [] as number[]
})

export const projectIdState = atom<string | undefined>({
  key: "projectIdState",
  default: undefined
})

export const quadriProjectIdState = atom<string | undefined>({
  key: "quadriProjectIdState",
  default: undefined
})

const privateDefaultProjectOriginState = selector({
  key: "privateDefaultProjectOriginState",
  get: () => {
    // eslint-disable-next-line no-restricted-globals
    const params = QueryString.parse(location.search)
    if (params.origin) {
      return `https:${params.origin.toString()}/tc/api/2.0`
    }
    return "https://app21.connect.trimble.com/tc/api/2.0"
  }
})

const privateProjectOriginState = atom({
  key: "privateProjectOriginState",
  default: privateDefaultProjectOriginState
})

export const projectOriginState = selector({
  key: "projectOriginState",
  get: ({ get }) => get(privateProjectOriginState),
  set: ({ set }, newProjectOrigin: string | DefaultValue) =>
    set(
      privateProjectOriginState,
      newProjectOrigin instanceof DefaultValue ? newProjectOrigin : `https:${newProjectOrigin}/tc/api/2.0`
    )
})

/** Selector for getting the users list, based on connect project ID and origin */
export const usersByTidUuidState = selector({
  key: "UsersByTidUuidState",
  get: async ({ get }) => {
    const usersByTidUuid = await getUsersByTidUuid({
      connectProjectId: get(projectIdState),
      connectProjectOrigin: get(projectOriginState)
    })
    return usersByTidUuid
  }
})

export const extensionApiState = atom({
  key: "ExtensionApiState",
  default: undefined as TCExtensionAPI | undefined
})

export const userProjectRoleState = selector({
  key: "UserProjectRoleState",
  get: async ({ get }) => {
    const userProjectRole = await getLoggedInUserProjectRole({
      connectProjectId: get(projectIdState),
      connectProjectOrigin: get(projectOriginState)
    })
    return userProjectRole
  }
})
