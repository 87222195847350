import {LitElement, html} from 'lit-element';
import getRequestAsPromise from '@twebcomponents/web-components-common/js/xhttp/getRequestAsPromise.js';
import TranslationManager from '@twebcomponents/web-components-common/js/utilities/translationManager.js';
import hasTargetContainsElemId from '@twebcomponents/web-components-common/js/functions/eventTarget.helper';

class TypePicker extends LitElement {

    render() {
    return html`<div class="filter-type ${this.isFormField ? 'block' : ''}">
      <div style="display:none">
      {{COMPONENT_CSS}}
      </div>
      <div id="defaultStyles"></div>
      <div id="customStylesFromUrl"></div>
      <div id="customStyles"></div>

      ${this.stylesLoaded?html`
      <div class="select-menu dropdown-pane-container ${this.isShowTypeList?'active':''}">
        <div class="name dropdownpane-link" @click="${this.showTypeList}">
          <div>
            <div>${this.tm.translate("Label")}</div>
          </div>
          <div>
            ${this.getBadgeTemplate()}
            <i class="icon-font tc-icon-arrow-drop-down"></i>
          </div>
        </div>
        <div class="dropdown-pane ${this.isShowTypeList?'is-open':''} dropdown" id="type-dropdown-pane-wrapper">
          ${this.categoryList.length ? html`
          <ul>
            ${this.categoryList.map((item, i) => this.getCategoryTemplate(item.category, item.translationKeyForCategory, item.iconClassForCategory, item.iconColorForCategory, item.customIconClassForCategory))}
          </ul>`: html`
          <div class="info">
            ${this.tm.translate("NoResults")}
          </div>`}
        </div>
        <div class="line"></div>
      </div>
      `:html`<div><i class="icon-font tc-icon-spinner-dark"></i></div>`}
      </div>
      `;
  }

  getCategoryTemplate(cat, translationKey, iconClassForCategory, iconColorForCategory , customIconClassForCategory){
    return html`<li class="${this.getTypes(cat, this.typeList).length && this.expandedItems[cat] ? 'open' : ''}">
    <div class="select-menu-item"  @click="${(e) => this.updateSelectionListByCategory(e, cat)}">
      <div class="icon checkbox">
          <input class="custom-input" name="filter" type="checkbox" id="${'t_' + this.currentId }" .indeterminate="${this.getCategorySelection(cat) == 'PARTIAL'}" .checked="${this.getCategorySelection(cat) == 'FULL' || this.getCategorySelection(cat) == 'PARTIAL'}"/>
          <label></label>
      </div>
      ${this.getTypes(cat, this.typeList).length ? html`
      <div class="control">
			  <button class="button tertiary icon-medium icon-circle" @click="${(e) => this.toggleExpand(e, cat)}">
				  <i class="icon-font ${this.expandedItems[cat] ? 'tc-icon-arrow-drop-down' : 'tc-icon-arrow-next'}"></i>
				</button>
			</div>` : html``}
      ${iconClassForCategory || customIconClassForCategory ? html`
			<div class="row-center">${iconClassForCategory ? html`<i class="icon-font i16 ${iconClassForCategory}" style="color:${iconColorForCategory}"></i>&nbsp;` : html`<img src="${customIconClassForCategory}" style="max-width: 1rem"/>&nbsp;`}<label title="${translationKey ? this.tm.translate(translationKey) : cat}" >${translationKey ? this.tm.translate(translationKey) : cat}</label></div>`: html`<label title="${translationKey ? this.tm.translate(translationKey) : cat}" >${translationKey ? this.tm.translate(translationKey) : cat}</label>`}
  </div>

  ${this.getTypes(cat, this.typeList).length && this.expandedItems[cat] ? html`
  <ul>
    ${this.getTypes(cat, this.typeList).map((item, i) => this.getTypeTemplate(item))}
  </ul>` : html``}
  </li>`
  }

  getTypeTemplate(item){
    return html`<li>
    <div class="select-menu-item"  @click="${(e) => this.updateSelectionList(e, item)}">
      <div class="icon checkbox">
          <input class="custom-input" name="filter" type="checkbox" id="${'t_' + this.currentId }" .checked="${this.isSelected(item)}"/>
          <label></label>
      </div>
      ${item.iconClassForValue ? html`<i class="icon-font i16 ${item.iconClassForValue}"></i>&nbsp;` : html``}<label title="${item.translationKeyForValue ? this.tm.translate(item.translationKeyForValue) : item.value}" >${item.translationKeyForValue ? this.tm.translate(item.translationKeyForValue) : item.value}</label>
  </div>
  </li>`
  }

  getBadgeTemplate(){
    return html`${this.selectionList && this.selectionList.length > 0 ? html`
    <span class="badge pill primary">${this.selectionList.length > 99 ? '99+' : this.selectionList.length} </span>`
    : ''}`
  }

  static get properties() {
    return {
      /* translationSource: To provide an array of translation sources. Each item in the list can either be a translation url or a translation dictionary. For example, the translation source can be defined as below.
      var translationUrl = "translations.json";

      var translationDictionary = {
      "componentTitle1": "Object picker",
      "launchButtonText": "Select",
      "browseDevice": "Browse device photos",
      "submit1": "Select"
      };


      var translationSource = [
        {
            "type": "URL",
            "value": translationUrl
        },
        {
            "type": "DICTIONARY",
            "value": translationDictionary
        }
      ];
      */
      translationSource: {
        type: Object,
        attribute: "translation-source",
        reflect: false
      },
      /* translationKeyMap: To provide translation 'custom translation key' - 'default translation key' mapping. This is useful when the component's translation key is different from the one mentioned in the cutome translation key */
      translationKeyMap: {
        type: Object,
        attribute: "translation-key-map",
        reflect: false
      },
      /* customStyleUrl: To provide custom stylesheet url */
      customStyleUrl: {
        type: String,
        attribute: "custom-style-url",
        reflect: false
      },
      /* customStyle: To provide custom style */
      customStyle: {
        type: String,
        attribute: "custom-style",
        reflect: false
      },
      /* typeList: Array of types. The types can be grouped / categorized as well and each group / category can have one or more types. The types and categories can have optional translationKeys */
      typeList: {
        type: Array,
        attribute: "type-list",
        reflect: false
      },
      /* selectionList: This is used to provide default selection when the components loads. This attribute also reflects the realtime selctions made in the component. So, the consumer application can use this if required */
      selectionList: {
        type: Array,
        attribute: "selection-list",
        reflect: true
      },
      isFormField: {
        type: Boolean,
        attribute: "is-form-field",
        reflect: true
      }
    };
  }

  constructor(defaultAttributes) {
    super();
    this.componentName = 'type-picker';

    this.translationSource = [];
    this.translationKeyMap = {};
    this.customStyle = '';
    this.customStyleUrl = '';
    this.typeList = [];
    this.categoryList = [];
    this.selectionList = [];
    this.currentId = 0;
    this.expandedItems = {};

    this.defaultTranslationDictionary = {
      Label : "Types",
      NoResults : "No types found"
    };
    this.stylesLoaded = false;

    this.tm = new TranslationManager(null, null, this.defaultTranslationDictionary);

    defaultAttributes = defaultAttributes || {};
    Object.keys(defaultAttributes).forEach(function(attrKey){
      if( defaultAttributes[attrKey] != null ) {
        this.setAttribute(attrKey, defaultAttributes[attrKey]);
      }
    }.bind(this));
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("on-window-resize", function(e){
      this.dispatchEvent(new CustomEvent('on-container-height-changed', {detail: {}}));
    }.bind(this));
    this.dispatchEvent(new CustomEvent('on-component-ready', {detail: {source: "type-picker"}}));

    this.getStyles();

    this.getCustomStyles();

    if(window.document.addEventListener)
      window.document.addEventListener("click", (evt) => this.documentClick(evt, this), false);
    else
      window.document.attachEvent("onclick", this.documentClick).bind(this);

    this.addEventListener("on-check-collapse", function(e){
      this.checkCollapse(e);
    }.bind(this));
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if(newValue && newValue != 'null' && newValue.indexOf('{{') == -1){
      this.translationSource = (name == 'translation-source')? (newValue ? this.parseJSON(newValue, []) : []) : this.translationSource;
      this.translationKeyMap = (name == 'translation-key-map')? (this.isNonEmptyObject(this.parseJSON(newValue, {})) ? this.parseJSON(newValue, {}) : {}) : this.translationKeyMap;

      this.customStyleUrl = (name == 'custom-style-url')? newValue : this.customStyleUrl;
      this.customStyle = (name == 'custom-style')? newValue : this.customStyle;

      this.typeList = (name == 'type-list')? (newValue ? this.parseJSON(newValue, []) : []) : this.typeList;
      this.selectionList = (name == 'selection-list')? (newValue ? this.parseJSON(newValue, []) : []) : this.selectionList;

      this.isFormField = name === 'is-form-field' ? (newValue === "true" ? true : false) : this.isFormField;

      if(name == "translation-source"){
        this.tm.setTranslationSource(this.translationSource);
      }

      if(name == "translation-key-map"){
        this.tm.setTranslationKeyMap(this.translationKeyMap);
      }

      if(name == 'type-list'){
        this.setCategoryList(this.typeList);
        this.resetExpandedItems();
      }

      if(name == 'selection-list'){
        this.dispatchEvent(new CustomEvent('selection-changed', {detail: {source: "type-picker", data: this.selectionList}}));
        this.requestUpdate("selectionList");
      }

      this.requestUpdate();
    }
  }

  parseJSON(value, defaultValueOnParseFailure){
    var result = null;
    if (typeof value === "object") {
      result = value;
    } else {
      try {
        result = JSON.parse(value);
      }
      catch (err) {
        if(defaultValueOnParseFailure){
          result = defaultValueOnParseFailure;
        }else{
          throw err;
        }
      }
    }

    return result;
  }

  updateSelectionListByCategory(e, cat) {
    if(e.target.type == "submit"){ //To fix the issue seen in IE browser : On clicking the expandable  arrow for files/folders or any values, the entire set of activities are getting selected
      return;
    }
    var selectionType = this.getCategorySelection(cat);
    var types = this.getTypes(cat, this.typeList);
    //var selectedTypes = this.selectionList.filter(t => t.category != cat) || [];

    if (!types.length) { //could be a type with no category?
      for (let i = 0; i < this.typeList.length; i++) {
        let t = this.typeList[i];
        if (t.value == cat && !t.category) {
          this.toggleSelection(t);
          break;
        }
      }
    } else {
      if (selectionType == "PARTIAL") {
        for (let i = 0; i < types.length; i++) {
          if (this.isSelected(types[i])) {
            this.toggleSelection(types[i]);
          }
        }
      } else { //FULL or EMPTY selection
        for (let i = 0; i < types.length; i++) {
          this.toggleSelection(types[i]);
        }
      }
    }

    //this.selectionList = selectedTypes;
    console.log("selectionList", this.selectionList);
    this.requestUpdate("selectionList");
    this.dispatchEvent(new CustomEvent('type-selected', { detail: { source: "type-picker", data: this.selectionList } }));
  }

  toggleSelection(item){
    var itemIndex = -1;

    for(var i = 0; i < this.selectionList.length; i++){
      let s = this.selectionList[i];
      if(s.value == item.value && s.category == item.category){
        itemIndex = i;
        break;
      }
    }

    if(itemIndex != -1){
      this.selectionList.splice(itemIndex, 1);
    }else{
      this.selectionList.push(item);
    }
  }

  updateSelectionList(e, item){
    e.stopPropagation();
    console.log("updateSelectionList id = ", item);

    this.toggleSelection(item);

    this.requestUpdate("selectionList");
    this.dispatchEvent(new CustomEvent('type-selected', {detail: {source: "type-picker", data: this.selectionList}}));
  }

  isSelected(item){
    var itemIndex = -1;

    for(var i = 0; i < this.selectionList.length; i++){
      let s = this.selectionList[i];
      if(s.value == item.value && s.category == item.category){
        itemIndex = i;
        break;
      }
    }

    return itemIndex != -1 ? true : false;
  }

  isNonEmptyObject(obj){
    //console.log("obj=", obj);
    var result = false;
    try{
      obj = typeof obj == "object" ? obj : JSON.parse(obj);
      result = Object.keys(obj).length > 0;
    }catch(err){
      result = false;
      console.log("error in isNonEmptyObject check", err);
    }

    //console.log("isNonEmptyObject = ", result);
    return result;
  }

  firstUpdated(changedProps) {
    super.update(changedProps);
  }

  setCategoryList(){
    var categoryList = [];
    for(var i=0; i<this.typeList.length; i++){
      let cat = categoryList.filter(c => c.category == this.typeList[i].category && c.translationKeyForCategory == this.typeList[i].translationKeyForCategory);
      if(this.typeList[i].category && cat.length == 0){
        categoryList.push({category: this.typeList[i].category, translationKeyForCategory: this.typeList[i].translationKeyForCategory, iconClassForCategory: this.typeList[i].iconClassForCategory , iconColorForCategory: this.typeList[i].iconColorForValue , customIconClassForCategory:this.typeList[i].customIconClassForCategory });
      }else if(!this.typeList[i].category){
        categoryList.push({category: this.typeList[i].value, translationKeyForCategory: this.typeList[i].translationKeyForValue, iconClassForCategory: this.typeList[i].iconClassForValue, iconColorForCategory: this.typeList[i].iconColorForValue , customIconClassForCategory: this.typeList[i].customIconClassForCategory});
      }
    }

    this.categoryList = categoryList;
  }

  getTypes(category, list){ //For list, you can pass either this.typeList or this.selectionList
    var types = [];
    types = list.filter(t => t.category == category);
    return types || [];
  }

  getCategorySelection(category){
    var selectionType = "EMPTY"; // possible values are "EMPTY, ""FULL" and "PARTIAL"
    let total = this.getTypes(category, this.typeList).length;
    let selected = this.getTypes(category, this.selectionList).length;

    if(total == selected){
      if(selected == 0 && total == 0){ //could be a type without category?
        for (let i = 0; i < this.typeList.length; i++) {
          let t = this.typeList[i];
          if (t.value == category && !t.category) {
            selectionType = this.isSelected({value:category}) ? "FULL" : "EMPTY";
            break;
          }
        }
      }else{
        selectionType = "FULL";
      }
    }else if(selected == 0){
      selectionType = "EMPTY";
    }else if(total > selected){
      selectionType = "PARTIAL";
    }

    //console.log(category, selectionType, total, selected);
    //console.log("getCategorySelection = ", selectionType);
    //console.log("getCategorySelection = ", selectionType);
    return selectionType;
  }

  toggleExpand(e, cat){
    e.stopPropagation();
    this.expandedItems[cat] = !this.expandedItems[cat];
    this.requestUpdate();
  }

  resetExpandedItems(){
    this.expandedItems = {};
    this.requestUpdate();
  }

  showTypeList(e){
    if (e.key == "Escape") {
      this.isShowTypeList = false;
    } else {
      this.isShowTypeList = !this.isShowTypeList;
      let dropDownContainer = this.shadowRoot.getElementById('type-dropdown-pane-wrapper');
      if(dropDownContainer) {
        setTimeout(() => dropDownContainer.scrollTop = 0, 0);
      }
      this.requestUpdate();
    }
  }

  documentClick(e){
    this.dispatchEvent(new CustomEvent('on-check-collapse', { detail: { event: e } }) );
  }

  checkCollapse(e) {
    let isClickedOutside = hasTargetContainsElemId(e.detail.event, this.id);
    if (isClickedOutside) {
      this.isShowTypeList = false;
      this.requestUpdate();
    }
  }

  async getStyles(){
    var resultIconStyle = "";

    resultIconStyle = await getRequestAsPromise("GET", "{{RESOURCE_DOMAIN}}/fonts/icon-font.min.css", null);
    var nodeIcons = document.createElement('style');
    nodeIcons.innerHTML = resultIconStyle;
    this.shadowRoot.querySelector("#defaultStyles").appendChild(nodeIcons);

    var nodeFontStyle = document.createElement('style');
    nodeFontStyle.innerHTML = `@font-face {
      font-family: 'tcw_svg_1.12.0';
      src: url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.eot");
      src: url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.eot#iefix") format("embedded-opentype"), url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.ttf") format("truetype"), url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.woff") format("woff"), url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.svg#tcw_svg") format("svg");
      font-weight: normal;
      font-style: normal;
    }	`;
    window.document.head.appendChild(nodeFontStyle);

    this.stylesLoaded = true;
    this.requestUpdate();
  }

  async getCustomStyles(){
    if(this.customStyleUrl){
      var result = "";
      var customStylesFromUrlNode = document.createElement('style');
      result = await getRequestAsPromise("GET", this.customStyleUrl, null);
      customStylesFromUrlNode.innerHTML = result;
      this.shadowRoot.querySelector("#customStylesFromUrl").appendChild(customStylesFromUrlNode);
      this.requestUpdate();
    }

    if(this.customStyle){
      var customStylesNode = document.createElement('style');
      customStylesNode.innerHTML = this.customStyle;
      this.shadowRoot.querySelector("#customStyles").appendChild(customStylesNode);
      this.requestUpdate();
    }
  }
}

console.log("is type-picker already registered = ", !!customElements.get('type-picker'));
if(!customElements.get('type-picker')){
  customElements.define('type-picker', TypePicker);
  console.log("is type-picker successfully registered = ", !!customElements.get('type-picker'));
}

export default TypePicker;
window.TypePicker= TypePicker;