import React from "react"
import Skeleton from "react-skeleton-loader"
import { Card } from "../Card"

const TimelineItemsSkeleton = (props: { count: number }) => {
  const skeletonCards = []
  for (let i = 0; i < props.count; i++) {
    skeletonCards.push(<SkeletonCard key={`tisk-${i}`} />)
  }
  return <>{skeletonCards}</>
}

const SkeletonCard = () => (
  <Card role="status">
    <div className="d-none d-md-flex activity-time">
      <p className="text-meta">
        <Skeleton widthRandomness={0} borderRadius={"0px"} color={"#EAEAEF"} />
      </p>
    </div>
    <div className="flex-row">
      <div className="d-none d-md-block activity-type">
        <Skeleton widthRandomness={0} width={"2rem"} height={"2rem"} borderRadius={"0px"} color={"#EAEAEF"} />
      </div>
      <div className="activity">
        <div className="d-block d-md-none mb-1">
          <small className="text-meta">
            <Skeleton />
          </small>
        </div>
        <div className="row-center">
          <div className="mr-2 avatar small borderless">
            <Skeleton height={"7rem"} width={"7rem"} borderRadius={"25%"} color={"#EAEAEF"} />
          </div>
          <div className="text-ellipsis">
            <h5 className="text-ellipsis">
              <Skeleton borderRadius={"0px"} color={"#EAEAEF"} />
            </h5>
            <small className="text-meta">
              <Skeleton borderRadius={"0px"} color={"#EAEAEF"} />
            </small>
          </div>
        </div>
        <div className="mt-1">
          <p>
            <Skeleton width={"90%"} count={2} borderRadius={"0px"} color={"#EAEAEF"} />
          </p>
        </div>
      </div>
    </div>
  </Card>
)

export default TimelineItemsSkeleton
