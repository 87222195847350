import { ActivityType } from "../../types"
import ShareIcon from "../../assets/icons/quadri/TaskShared_24.svg"
import ReceiveIcon from "../../assets/icons/quadri/TaskReceived_24.svg"
import JoinIcon from "../../assets/icons/quadri/TaskJoined_24.svg"
import ReserveIcon from "../../assets/icons/quadri/TaskReserved_24.svg"
import ReleaseIcon from "../../assets/icons/quadri/TaskReleased_24.svg"

export const TimelineIcon = (props: { type: ActivityType }) => <img src={getTypeIcon(props.type)} alt="task icon" />

const getTypeIcon = (type: ActivityType) => {
  switch (type) {
    case "Share":
      return ShareIcon
    case "Receive":
      return ReceiveIcon
    case "Join":
      return JoinIcon
    case "Reserve":
      return ReserveIcon
    case "Release":
      return ReleaseIcon
  }
}
