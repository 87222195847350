import { ExtensionSubMenu } from "trimble-connect-project-workspace-api"
import Environment from "../enviroments"

const iconsRootUrl = window.location.origin

export const getMenu = (t: any) => ({
  title: t("sharedModel") + (Environment.envName ? ` - ${Environment.envName}` : ""),
  icon: `${iconsRootUrl}/Quadri_24.svg`,
  command: "top_menu",
  subMenus: [
    {
      title: t("tasks"),
      // icon: `${iconsRootUrl}/Tasks_24.svg`,
      icon: `${iconsRootUrl}/Libraries_16.svg`,
      command: "tasks"
    },
    {
      title: t("timeline"),
      //icon: `${iconsRootUrl}/Timeline_24.svg`,
      icon: `${iconsRootUrl}/Activity_16.svg`,
      command: "timeline"
    },
    {
      title: t("worksets"),
      // icon: `${iconsRootUrl}/Worksets_24.svg`,
      icon: `${iconsRootUrl}/Cloud_16.svg`,
      command: "worksets"
    }
  ].filter((m) => true && m) as ExtensionSubMenu[]
})
