import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import config_en from "./translations/en/config.json"
import config_nb from "./translations/nb/config.json"
import common_en from "./translations/en/common.json"
import common_nb from "./translations/nb/common.json"
import tasks_en from "./translations/en/tasks.json"
import tasks_nb from "./translations/nb/tasks.json"
import timeline_en from "./translations/en/timeline.json"
import timeline_nb from "./translations/nb/timeline.json"
import fromconnect_en from "./translations/en/fromconnect.json"
import fromconnect_nb from "./translations/nb/fromconnect.json"
import worksets_en from "./translations/en/worksets.json"
import worksets_nb from "./translations/nb/worksets.json"

i18n.use(initReactI18next).init({
  resources: {
    en: {
      config: config_en,
      common: common_en,
      tasks: tasks_en,
      worksets: worksets_en,
      timeline: timeline_en,
      fromconnect: fromconnect_en
    },
    nb: {
      config: config_nb,
      common: common_nb,
      tasks: tasks_nb,
      worksets: worksets_nb,
      timeline: timeline_nb,
      fromconnect: fromconnect_nb
    }
  },
  fallbackLng: "en",

  interpolation: {
    escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  }
})
export default i18n
