import { useMutation } from "@apollo/client"
import React from "react"
import { useTranslation } from "react-i18next"
import { DELETE_PROJECT } from "../../api/QuadriApi"
import { ProjectStatus } from "../../types"
import DeleteModal from "./DeleteModal"

// const deleteRefreshDelayMs = 15000 // TODO: This is a race condition, should have some proper logic
const deleteRefreshDelayMs = 1500000 // Set it to 1500 seconds so that it does not refresh

interface QuadriFooterProps {
  projectStatus: ProjectStatus | undefined
  changeInProgress: boolean
  connectProjectId: string
}
//
const QuadriFooter = (props: QuadriFooterProps) => {
  const { connectProjectId, projectStatus } = props
  const { t } = useTranslation("config")

  const [disabled, setDisabled] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const showModal = () => setShowDeleteModal(true)
  const closeModal = () => setShowDeleteModal(false)

  // Mutation used for disabling after the project has been created
  const [deleteProjectMutation] = useMutation(DELETE_PROJECT, {
    onCompleted: async (data) => {
      if (data.deleteProject.success) {
        // Reload the page if the project was deleted successfully
        setTimeout(() => {
          setDeleting(false)
          setDisabled(true)
          // eslint-disable-next-line no-restricted-globals
          ;(location as any).reload(true)
        }, deleteRefreshDelayMs)
      }
    }
  })
  const deleteProject = (purge: boolean) => {
    setDeleting(true)
    deleteProjectMutation({
      variables: {
        deleteProjectRequest: {
          connectProjectId,
          purge
        }
      }
    })
  }

  const isDeleting = deleting || projectStatus === "Deleting"

  // const buttonDeleteProject = !deleting ? deleteProject : undefined
  const buttonDeleteProject = !isDeleting ? showModal : undefined

  return (
    <div className="row mb-5">
      {showDeleteModal && <DeleteModal closeModal={closeModal} deleteProject={deleteProject} />}
      <div className="col-12 head-container mt-0">
        <div className="page-heading mt-0">
          <div />
          {/* This is the case for when a project is not active */}
          {typeof projectStatus === "undefined" ? (
            <button className="button warning ml-1" type="button" disabled={true}>
              {t("quadriFooter.disable")}
            </button>
          ) : (
            // This is the case for when a project is active, but in some state
            <button
              className="button warning ml-1"
              type="button"
              onClick={buttonDeleteProject}
              disabled={props.changeInProgress || isDeleting}
            >
              {(isDeleting || disabled) && <i className="icon-font tc-icon-spinner-light"></i>}
              {isDeleting
                ? t("quadriFooter.disablingQuadri")
                : disabled
                ? t("quadriFooter.quadriDisabled")
                : t("quadriFooter.disable")}
            </button>
          )}
          {/* {projectStatus !== "Deleting" ? (
            <button className="button warning ml-1" type="button" disabled={true}>
              Disable
            </button>
          ) : (
            <button className="button warning ml-1" type="button" onClick={buttonDeleteProject}>
              {(isDeleting || disabled) && <i className="icon-font tc-icon-spinner-light"></i>}
              {isDeleting ? "Disabling Quadri..." : disabled ? "Disabled, reloading page" : "Disable"}
            </button>
          )} */}
        </div>
      </div>
    </div>
  )
}

export default QuadriFooter
