import * as React from "react"
import { useTranslation } from "react-i18next"

export interface ProjectSettingGroupProps {
  name: string
  editLink: string
  projectSettings: ProjectSettingProps[]
  onClick?: React.MouseEventHandler // For testing setting the value
  optional?: boolean
  message?: string
}

export const ProjectSettingGroup = (props: ProjectSettingGroupProps) => {
  const { projectSettings, optional, message } = props
  const { t } = useTranslation("config")
  return (
    <div className="row my-2">
      <div className="col-12 d-flex justify-content-between">
        <h5>{props.name}</h5>
        <a
          className="text-decoration-none"
          href={props.editLink}
          target="_blank"
          rel="noreferrer"
          onClick={props.onClick}
        >
          {t("projectSettings.edit")}
        </a>
      </div>
      <div className="col-12">
        <hr className="my-1" />
      </div>

      {projectSettings.find((x) => x.value !== undefined) ? (
        projectSettings.map((x) => <ProjectSetting key={x.name} {...x} />)
      ) : optional ? (
        <div className="col-12 mb-1">
          <div className="alert info py-2">{message}</div>
        </div>
      ) : (
        <div className="col-12 mb-1">
          <div className="alert error py-2">{message}</div>
        </div>
      )}
    </div>
  )
}

interface ProjectSettingProps {
  name: string
  value?: string
}

const ProjectSetting = (props: ProjectSettingProps) => {
  return (
    <div className="col-12 mb-1" key={props.name}>
      <small className="text-meta">{props.name}</small>
      <p>{props.value}</p>
    </div>
  )
}
