import {LitElement, html} from 'lit-element';
import getRequestAsPromise from '@twebcomponents/web-components-common/js/xhttp/getRequestAsPromise.js';
import TranslationManager from '@twebcomponents/web-components-common/js/utilities/translationManager.js';

class UserPicker extends LitElement {
  
  render() {
    return html`
      <div class="filter-type ${this.isFormField ? 'block' : ''}">
        <div style="display:none">
        {{COMPONENT_CSS}}
        </div>
        <div id="defaultStyles"></div>
        <div id="customStylesFromUrl"></div>      
        <div id="customStyles"></div>       
          
        ${this.stylesLoaded ? html`
          <div class="select-menu dropdown-pane-container ${this.isShowUserList?'active':''} ${this.isFormField ? 'is-form-field' : ''}">
            <div class="name dropdownpane-link" @click="${this.showUserList}">
              <div>
                <div>${this.tm.translate("Label")}</div>
              </div>
              <div>
                ${this.getBadgeTemplate()}
                <i class="icon-font tc-icon-arrow-drop-down"></i>
              </div>
            </div>
            <div class="dropdown-pane ${this.isShowUserList?'is-open':''} dropdown" id="dropdown-pane-wrapper">
              <header>
                <div class="input-button-group search ${this.isShowSuggestionList?'is-active':''} dropdown-pane-container">
                    <button class="button tertiary icon-medium icon-circle">
                        <i class="icon-font tc-icon-search"></i>
                    </button>       
                    <input type="text" id="txt_search" placeholder="${this.tm.translate("SearchPlaceHolder")}" @keyup="${this.showSuggestionList}" .value="${this.userSearchText}">     
                    <div class="line"></div>
                    ${(this.showResultsPanel) ? html`<div class="auto-complete">
                      ${this.suggestionList.length?html`
                      <ul>
                        ${this.suggestionList.map((item,i) => this.getSuggestedUserTemplate(item))}
                      </ul>`:html`${this.showResults ? html`<div class="info">
                        ${this.tm.translate("NoResults")}
                      </div>`: ''}`}
                      <!--<div class="info">
                        Too many results to display. Please refine your search.
                      </div>-->
                      <footer class="pr-1">
                          <span class="sub-text">
                            <span>${this.tm.translate("LastUpdated")}</span>: ${this.getLastUpdatedDateTime()}
                          </span>
                          ${this.loading ? html`
                          <div class="align-center"><i class="icon-font tc-icon-spinner-dark"></i></div>`:html`
                          <button class="button icon-medium icon-circle tertiary" @click="${(e) => {this.loadUsers(true, e);e.stopPropagation();}}">
                          <i class="icon-font tc-icon-refresh"></i>
                          </button>
                          `}
                      </footer>
                    </div>` : ''}
                    ${this.userSearchText != '' ? html`<button class="button tertiary icon-medium icon-circle" @click="${this.clearSuggestionList}">
                      <i class="icon-font tc-icon-content-close"></i>
                    </button>` : '' }                                           
                </div>   
              </header>
              <hr/>
              <section>
                ${this.userList.length ? html`
                <ul>
                  ${this.userList.map((item, i) => this.getUserTemplate(item))}
                </ul>`: html`
                <div class="info">
                  ${this.tm.translate("NoResults")}
                </div>`}                                                                        
                <footer class="pr-1">
                  <span class="sub-text">
                  <span>${this.tm.translate("LastUpdated")}</span>: ${this.getLastUpdatedDateTime()}                                 
                  </span>
                  ${this.loading ? html`
                  <div class="align-center"><i class="icon-font tc-icon-spinner-dark"></i></div>`:html`
                  <button class="button icon-medium icon-circle tertiary" @click="${(e) => {this.loadUsers(true, e);e.stopPropagation();}}">
                  <i class="icon-font tc-icon-refresh"></i>
                  </button>
                  `}
                </footer>
              </section>
            </div>
            <div class="line"></div>
          </div>`
          : html`<div><i class="icon-font tc-icon-spinner-dark"></i></div>`
        }
      </div>`;
  }

  getUserTemplate(item){
    return html`<li>
    <div class="select-menu-item multiple-line"  @click="${(e) => this.updateSelectionList(e, item)}">
      <div class="icon checkbox">
          <input class="custom-input" name="filter" type="checkbox" id="${'u_' + item.id}" .checked="${this.isSelected(item)}"/>
          <label></label>                                                                 
      </div>                                                                                    
      <div>
          <label>${this.getName(item)}</label> 
          <span class="sub-text">${item.email == null || item.email == undefined?'':item.email}</span>
      </div>                                                                                                                                                                                                                                            
  </div>  
  </li>`
  }

  getSuggestedUserTemplate(item){
    return html`<li> 
    <div class="select-menu-item multiple-line" @click="${(e) => this.updateSelectionList(e, item)}">                                                                                                                                                                     
      <div>
          <label>${this.getName(item)}</label> 
          <span class="sub-text">${item.email == null || item.email == undefined?'':item.email}</span>
      </div>                                                                                                                                                                                                                                            
    </div>
   </li>`
  }

  getBadgeTemplate(){
    return html`${this.selectionList && this.selectionList.length > 0 ? html`
    <span class="badge pill primary">${this.selectionList.length > 99 ? '99+' : this.selectionList.length} </span>`
    : ''}`
  }

  static get properties() {
    return {
      /* accessToken: Bearer token being passed in the api call (get user list) */
      accessToken: {
        type: String,
        attribute: 'access-token',
        reflect: false
      },
      /* apiUrl: Api url for fetching user list */
      apiUrl: {
        type: String,
        attribute: 'api-url',
        reflect: false
      },
      /* refreshDataCache: By default, the component will show users from cache (localStorage) if exist and fetch from api if the user list is not avaialble in cache. Add this attribute in case, you want to force the api call for the initial loading of user list */
      refreshDataCache: {
        type: Boolean,
        attribute: 'refresh-data-cache',
        reflect: false
      },
      /* translationSource: To provide an array of translation sources. Each item in the list can either be a translation url or a translation dictionary. For example, the translation source can be defined as below.
      var translationUrl = "translations.json";

      var translationDictionary = {
      "componentTitle1": "Object picker",
      "launchButtonText": "Select",
      "browseDevice": "Browse device photos",
      "submit1": "Select"
      };


      var translationSource = [
        {
            "type": "URL",
            "value": translationUrl
        },
        {
            "type": "DICTIONARY",
            "value": translationDictionary
        }
      ];
      */
      translationSource: {
        type: Object,
        attribute: "translation-source",
        reflect: false
      },
      /* translationKeyMap: To provide translation 'custom translation key' - 'default translation key' mapping. This is useful when the component's translation key is different from the one mentioned in the cutome translation key */
      translationKeyMap: {
        type: Object,
        attribute: "translation-key-map",
        reflect: false
      },
      /* customStyleUrl: To provide custom stylesheet url */
      customStyleUrl: { 
        type: String,
        attribute: "custom-style-url",
        reflect: false
      },
      /* customStyle: To provide custom style */
      customStyle: { 
        type: String,
        attribute: "custom-style",
        reflect: false
      },
      /* selectionList: This is used to provide default selection when the components loads. This attribute also reflects the realtime selctions made in the component. So, the consumer application can use this if required */
      selectionList: {
        type: Array,
        attribute: "selection-list",
        reflect: true
      },
      // Used to display the user picker as a text box instead of filter.
      isFormField: {
        type: Boolean,
        attribute: "is-form-field",
        reflect: true
      }
    };
  }

  constructor(defaultAttributes) {
    super();
    console.log("api url:", this.apiUrl);
    this.componentName = 'user-picker';
    this.apiUrl = "";
    this.accessToken = "";

    this.suggestionList = [];
    this.userList = [];
    this.selectionList = [];
    this.translationSource = [];
    this.translationKeyMap = {};
    this.customStyle = '';
    this.customStyleUrl = '';

    this.loading = false;
    this.pageSize = 25;
    this.showResults = false;
    this.showResultsPanel = false;

    this.isShowSuggestionList = false;
    this.isShowUserList = false;
    this.defaultTranslationDictionary = {
      Label : "User",
      SearchPlaceHolder : "Search users",
      LastUpdated : "Last updated",
      NoResults : "No users found"
    };
    this.stylesLoaded = false;
    this.userSearchText = '';

    this.tm = new TranslationManager(null, null, this.defaultTranslationDictionary);

    defaultAttributes = defaultAttributes || {};
    Object.keys(defaultAttributes).forEach(function(attrKey){
      if( defaultAttributes[attrKey] != null ) {
        this.setAttribute(attrKey, defaultAttributes[attrKey]);
      }
    }.bind(this));
  }
  
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("on-window-resize", function(e){
      this.dispatchEvent(new CustomEvent('on-container-height-changed', {detail: {}}));
    }.bind(this));
    this.dispatchEvent(new CustomEvent('on-component-ready', {detail: {source: "user-picker"}}));

    this.getStyles();

    this.getCustomStyles();

    if(window.document.addEventListener)
      
      window.document.addEventListener("click", (evt) => this.documentClick(evt, this), false);
    else
      window.document.attachEvent("onclick", this.documentClick).bind(this);
    
      this.addEventListener("on-check-collapse", function(e){
      this.checkCollapse(e);
    }.bind(this));      
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if(newValue && newValue != 'null' && newValue.indexOf('{{') == -1){
      this.accessToken = (name == 'access-token')? newValue : this.accessToken;
      this.apiUrl = (name == 'api-url')? newValue : this.apiUrl;

      this.translationSource = (name == 'translation-source')? (newValue ? this.parseJSON(newValue, []) : []) : this.translationSource;
      this.translationKeyMap = (name == 'translation-key-map')? (this.isNonEmptyObject(this.parseJSON(newValue, {})) ? this.parseJSON(newValue, {}) : {}) : this.translationKeyMap;

      this.selectionList = (name == 'selection-list')? (newValue ? this.parseJSON(newValue, []) : []) : this.selectionList;
      this.isFormField = name === 'is-form-field' ? (newValue === "true" ? true : false) : this.isFormField;
      this.refreshDataCache = (name == 'refresh-data-cache')? newValue : this.refreshDataCache;
      this.customStyleUrl = (name == 'custom-style-url')? newValue : this.customStyleUrl;
      this.customStyle = (name == 'custom-style')? newValue : this.customStyle;

      //creating array of users with id and tiduuid
      this.selectionTuuidList = this.userCache.users.filter((user) => this.selectionList.includes(user.id)).map((user) => ({ id: user.id, tiduuid: user.tiduuid }));
	
      if( (name == 'api-url' || name == 'access-token' || name == "refresh-data-cache") && this.accessToken && this.apiUrl){
        if(!this.loading){
          if(this.refreshDataCache)
            this.loadUsers(true);
          else
            this.loadUsers(false);
        }
      } 

      if(name == "translation-source"){
        this.tm.setTranslationSource(this.translationSource);
      }
      if(name == "translation-key-map"){
        this.tm.setTranslationKeyMap(this.translationKeyMap);
      }

      if(name == 'selection-list'){
        this.dispatchEvent(new CustomEvent('selection-changed', {detail: {source: "user-picker", data: this.selectionList, userData: this.selectionTuuidList}}));
      }

      this.requestUpdate();
    }
  }

  parseJSON(value, defaultValueOnParseFailure){
    var result = null;
    if (typeof value === "object") {
      result = value;
    } else {
      try {
        result = JSON.parse(value);
      }
      catch (err) {
        if(defaultValueOnParseFailure){
          result = defaultValueOnParseFailure;
        }else{
          throw err;
        }
      }
    }

    return result;
  }

  loadUsers(clearCache){
    if(this.loading){
      console.log("previous userlist call is still in progress");
      return;
    }
    //this.loading = true;
    this.userList = [];

    if(clearCache){
      this.userCache = [];
    }
    var projectUserData = this.userCache;

    // return the cached users list immediately to avoid delay to the user - UX decision
    if(projectUserData && projectUserData.users && projectUserData.users.length && projectUserData.key == this.apiUrl.toLowerCase()){
      console.log("resolving with data from cache = ", projectUserData.users);
      this.userList = projectUserData.users;
      this.loading = false;
      this.requestUpdate('loading');
      this.requestUpdate('userList');
    }else{
      this.userCache = [];

      var startIndex = !this.userList.length ? 0 : this.userList.length;
      var endIndex = startIndex + this.pageSize;
      this.loading = true;    
      this.requestUpdate('loading');
      this.getList(this.apiUrl, this.accessToken, startIndex, endIndex).then(
        (res) => this.processUserResults(res),
        (err) => {
          console.log("error in fetching user list", err);
          this.loading = false;
          this.requestUpdate();
        }
      );
    }
  }
  
  processUserResults(result) {
    var self = this;
    //this.loading = false;
    var usersTemp = JSON.parse(result.response);

    //display the available user list instead of waiting for the entire list to arrive
    self.userCache = usersTemp;
    self.userList = self.userCache.users;
    console.log("first call self.userList = ", self.userList);
    self.requestUpdate('userList');

    var contentRange = result.getResponseHeader("content-range");
    var totalRecords = parseInt(contentRange.substring(contentRange.indexOf("/") + 1));
    var rangeEnd = parseInt(contentRange.split("/")[0].split("-")[1]);

    //Make additional calls if more users exist
    if (usersTemp.length < totalRecords) {
      console.log("inside length check usersTemp.length = ", usersTemp.length);
      console.log("inside length check totalRecords = ", totalRecords);
      var userApiCalls = [];
      for (var i = rangeEnd + 1; i < totalRecords; i += this.pageSize) {
        let start = i, end = i + this.pageSize - 1;
        userApiCalls.push(this.getList(this.apiUrl, this.accessToken, start, end));
      }

      console.log("userApiCalls = ", userApiCalls);
      if (userApiCalls.length) {
        Promise.all(userApiCalls).then(function (userArrays) {
          self.loading = false;
          console.log("all user api calls success = ", userArrays);
          userArrays = userArrays.map((u) => JSON.parse(u.response));
          userArrays = Array.prototype.concat.apply([], userArrays);
          usersTemp = usersTemp.concat(userArrays);
          usersTemp = usersTemp.sort((u1, u2) => self.getName(u1).localeCompare(self.getName(u2)));
          self.userCache = usersTemp;
          self.userList = self.userCache.users;
          console.log("self.userList = ", self.userList);
          self.requestUpdate('userList');
        }, function (err) {
          console.log("error fetching users list", err);
          self.loading = false;
        });
      } else {
        self.loading = false;
        this.userCache = usersTemp;
        this.userList = this.userCache.users;
        this.requestUpdate('userList');
      }
    } else {
      self.loading = false;
      console.log("all the users are available in the first call itself = ", usersTemp);
      this.userCache = usersTemp;
      this.userList = this.userCache.users;
      this.requestUpdate('userList');
    }
  }

  get userCache(){
    return JSON.parse(localStorage.getItem("up_users_list"));
  }

  set userCache(userList){
    var projectUserData = {
      key: this.apiUrl.toLowerCase(),
      users: userList,
      //lastModifiedTimeStamp: (new Date(0)).getTime(),
      cacheDateTime: Date.now()
    }
    localStorage.setItem("up_users_list", JSON.stringify(projectUserData));    
  }

  getLastUpdatedDateTime(){
    var result = '';
    if(this.userCache && this.userCache.cacheDateTime > (new Date(0)).getTime()){
      result = (new Date(this.userCache.cacheDateTime)).toUTCString();
    }
    return result;
  }

  updateSelectionList(e, item){
    e.stopPropagation();
    console.log("updateSelectionList id = ", item.id);
    var id = '';
    if(item && item.id){
      id = item.id;
    }else{
      id = item;
    }

    if(id){
      if(this.selectionList.indexOf(id) > -1){
        this.selectionList.splice(this.selectionList.indexOf(id), 1);
      }else{
        this.selectionList.push(id);
      }
      this.requestUpdate('selectionList');
      this.dispatchEvent(new CustomEvent('user-selected', {detail: {source: "user-picker", data: this.selectionList}}));
      this.hideSuggestionsPanel();
    }
  }

  isSelected(item){
    var id = '';
    if(item && item.id){
      id = item.id;
    }else{
      id = item;
    }

    return id && (this.selectionList.indexOf(id) > -1);
  }

  isNonEmptyObject(obj){
    //console.log("obj=", obj);
    var result = false;
    try{
      obj = typeof obj == "object" ? obj : JSON.parse(obj);
      result = Object.keys(obj).length > 0;
    }catch(err){
      result = false;
      console.log("error in isNonEmptyObject check", err);
    }

    //console.log("isNonEmptyObject = ", result);
    return result;
  }
  
  firstUpdated(changedProps) {
    super.update(changedProps);
  }

  getList(apiUrl, accessToken, startIndex, endIndex){
    startIndex = startIndex || 0;
    endIndex = endIndex || startIndex + this.pageSize - 1;
    var headers = {
      "Content-type": "application/json",
      "Authorization": "Bearer " + (this.accessToken || accessToken),
      "Range": "items=" + startIndex +"-" + endIndex,
      "X-Range": "items=" + startIndex +"-" + endIndex
    };
    var promise = getRequestAsPromise("GET", apiUrl, headers, true);
    return promise;
  }

  updated(changedProps){
    super.updated(changedProps);
  }

  getName(item){
    var result = "";
    if(item.firstName){
      result = item.firstName;
    }
    if(item.lastName){
      result = result ? result + ' ' + item.lastName: item.lastName;
    }

    return result;
  }

  showSuggestionList(e){
    e.stopPropagation();
    this.showResultsPanel = false;
    //console.log("e=",e);
    if(e.key == "Escape"){
      this.isShowSuggestionList = false;
      //e.preventDefault();
    }else{
      this.showResultsPanel = true;
      this.isShowSuggestionList = true;
      this.suggestionList = [];
      var query = this.shadowRoot.querySelector("#txt_search");
      //console.log("query = ", query);
      if(query){
        //console.log("query value = ", query.value);
        //console.log("item=", item);
        var results = [];
        if(this.userList.length){
          results = this.userList.filter(u => (this.getName(u) && this.getName(u).toLowerCase().indexOf(query.value.toLowerCase()) > -1) || (u.email && u.email.toLowerCase().indexOf(query.value.toLowerCase()) > -1) );
        }

        //exclude already selected users form suggestionList
        results = results.filter(u => !(this.isSelected(u.id)) );
        this.suggestionList = results;

        if(!query.value){
          this.isShowSuggestionList = false;
          this.showResultsPanel = false;
        }
        this.showResults = true;
        this.userSearchText = query.value;
      }

      //console.log(this.suggestionList);
    }
    this.requestUpdate('suggestionList');
  }

  clearSuggestionList(e){
    e.stopPropagation();
    var query = this.shadowRoot.querySelector("#txt_search");
    if(query && query.value){
      query.value = "";
    }
    this.userSearchText = '';
    this.isShowSuggestionList = false;
    this.suggestionList = [];
    this.showResultsPanel = false;
    this.showResults = false;
    this.requestUpdate('suggestionList');
  }
  
  showUserList(e){
    if (e.key == "Escape") {
      this.isShowUserList = false;
      this.isShowSuggestionList = false;
      this.clearSearchBox();
    } else {
      this.isShowUserList = !this.isShowUserList;
      if (!this.isShowUserList) {
        this.isShowSuggestionList = false;
        this.clearSearchBox();
      }
      let dropDownContainer = this.shadowRoot.getElementById('dropdown-pane-wrapper');
      if (dropDownContainer) {
        setTimeout(() => dropDownContainer.scrollTop = 0, 0);        
      }
      this.requestUpdate('userList');
    }
  }

  getOriginalTarget(e) {
    if ('composedPath' in e) return e.composedPath()[0]; // Standard
    else if ('path' in e) return e.path[0]; // Chrome
    else if ('originalTarget' in e) return e.originalTarget; // Firefox
    else if ('srcElement' in e) return e.srcElement; // Old IE & Safari
    else return e.target; // Fallback to normal target.
  };

  getIdList(e) {
    let elements = "";
    let path = e.path;
    let composedPath = e.composedPath();
    if (composedPath) {
      elements = composedPath;
    } else if (path) {
      elements = path;
    }
    let ids = [];
    elements.forEach(elem => {
      if (!elem.id) return;
      ids.push(elem.id);
    });
    return ids;
  }

  documentClick(e){
    this.dispatchEvent(new CustomEvent('on-check-collapse', {detail: {from: e.target.id, event: e}})); 
  }
  
  checkCollapse(e) {
    let elemIDs = this.getIdList(e.detail.event);
    if (elemIDs.indexOf(this.id) === -1) {
      this.isShowUserList = false;
      this.isShowSuggestionList = false;
      this.showResultsPanel = false;
      this.showResults = false;
      this.clearSearchBox();
      this.requestUpdate('userList');
    }
  }



  hideSuggestionsPanel(){
    this.isShowSuggestionList = false;
    this.showResultsPanel = false;
    this.showResults = false;
    this.clearSearchBox();
    this.requestUpdate('userList');
  }
  clearSearchBox(){
    var query = this.shadowRoot.querySelector("#txt_search");
    if(query){
      query.value = '';
    }
    this.userSearchText = '';
  }

  async getStyles(){
    var resultIconStyle = "";

    resultIconStyle = await getRequestAsPromise("GET", "{{RESOURCE_DOMAIN}}/fonts/icon-font.min.css", null);
    var nodeIcons = document.createElement('style');
    nodeIcons.innerHTML = resultIconStyle;
    this.shadowRoot.querySelector("#defaultStyles").appendChild(nodeIcons);

    var nodeFontStyle = document.createElement('style');
    nodeFontStyle.innerHTML = `@font-face {
      font-family: 'tcw_svg';
      src: url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.eot");
      src: url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.eot#iefix") format("embedded-opentype"), url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.ttf") format("truetype"), url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.woff") format("woff"), url("{{RESOURCE_DOMAIN}}/fonts/tcw_svg.svg#tcw_svg") format("svg");
      font-weight: normal;
      font-style: normal; 
    }	`;
    window.document.head.appendChild(nodeFontStyle);

    this.stylesLoaded = true;
    this.requestUpdate();
  }

  async getCustomStyles(){
    if(this.customStyleUrl){
      var result = "";
      var customStylesFromUrlNode = document.createElement('style');
      result = await Xhttp.getStylesAsString(this.customStyleUrl);
      customStylesFromUrlNode.innerHTML = result;
      this.shadowRoot.querySelector("#customStylesFromUrl").appendChild(customStylesFromUrlNode);
      this.requestUpdate();
    }
    
    if(this.customStyle){
      var customStylesNode = document.createElement('style');
      customStylesNode.innerHTML = this.customStyle;
      this.shadowRoot.querySelector("#customStyles").appendChild(customStylesNode);
      this.requestUpdate();
    }
  }
}

console.log("is user-picker already registered = ", !!customElements.get('user-picker'));
if(!customElements.get('user-picker')){
  customElements.define('user-picker', UserPicker);
  console.log("is user-picker successfully registered = ", !!customElements.get('user-picker'));
}

export default UserPicker;
window.UserPicker= UserPicker;