import React from "react"

const VersionTag = () => {
  const HardRefreshButton = () => {
    // eslint-disable-next-line no-restricted-globals
    const onClick = () => (location as any).reload(true)
    return (
      <button className="button border-0 icon-medium icon-circle tertiary" onClick={onClick}>
        <i className={"icon-font tc-icon-refresh"}></i>
      </button>
    )
  }

  return (
    <div className="text-meta d-flex" style={{ alignItems: "center" }}>
      Version: #20220831.2 (1394871f) <HardRefreshButton />{" "}
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://dev.azure.com/trmb-ccss/CCSS/_release?_a=releases&view=mine&definitionId=258"
      >
        Is this the latest version? (Opens new tab)
      </a>
    </div>
  )
}

export default VersionTag
