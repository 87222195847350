import * as oidc from "oidc-client"

export class AuthCallbackHandler {
  private userManager: oidc.UserManager
  private inRenewIframe: boolean
  private callbackUrl: string

  constructor(settings: oidc.UserManagerSettings) {
    this.userManager = new oidc.UserManager(settings)
    this.callbackUrl = settings.redirect_uri || "no callback url"

    this.inRenewIframe = (window.frameElement && window.frameElement.nodeName === "IFRAME") || false

    oidc.Log.logger = console
    oidc.Log.level = oidc.Log.WARN
  }

  // Called in the page registered as callback url (in either main window or iframe)
  public async handleCallback(): Promise<void> {
    if (this.isCallback()) {
      try {
        if (this.inRenewIframe) {
          await this.signinSilentCallback()
        } else {
          await this.signinRedirectCallback()
        }
      } catch (e) {
        throw new Error("SignIn callback failed: " + e)
      }
    } else {
      throw new Error("The callback handler should only be used in the callback page")
    }
  }

  private async signinSilentCallback() {
    await this.userManager.signinSilentCallback(window.location.href)
  }

  private async signinRedirectCallback() {
    const user = await this.userManager.signinRedirectCallback()
    window.history.replaceState({}, window.document.title, window.location.origin + window.location.pathname)
    window.location = user.state || "/"
  }

  private isCallback() {
    const url = window.location.href
    return url.indexOf(this.callbackUrl) !== -1
  }
}
