interface CheckBoxGroupProps {
  checkGroupKey: string
  groupTitle?: string
  checkedSet: { [key: string]: boolean }
  toggleChecked: (key: string) => void
  checkBoxes: Array<{ checkKey: string; description: string }>
}

const CheckBoxGroup = (props: CheckBoxGroupProps) => {
  const { checkGroupKey, groupTitle, checkedSet, toggleChecked, checkBoxes } = props
  return (
    <div className="checkradio-group">
      {groupTitle && <label className="label">{groupTitle}</label>}
      {checkBoxes.map(({ checkKey, description }) => {
        const onChange = (e: any) => toggleChecked(checkKey)
        return (
          <div className="input-group checkradio" key={`${checkGroupKey}-${checkKey}`}>
            <div className="group-items">
              <div className="icon checkbox" onClick={onChange}>
                <input className="custom-input" type="checkbox" checked={checkedSet[checkKey]} onChange={() => {}} />
                <label></label>
              </div>
              <label>{description}</label>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CheckBoxGroup
