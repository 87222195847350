import { useTranslation } from "react-i18next"
import { Card } from "../Card"
import { ProjectSettingGroup } from "./ProjectSettingGroup"

// The card to render Connect project settings
const ImportedProjectSettings = (props: {
  connectProjectId: string
  unitSystem?: string
  boundaryCoordinates?: string
  crsName?: string
}) => {
  const { connectProjectId, unitSystem, boundaryCoordinates, crsName } = props

  const { t } = useTranslation("config")

  return (
    <div className="row">
      <div className="col-12">
        <Card header={t("connectSettings.header")}>
          <div className="col-12 col-lg-4">
            <p className="text-meta">{t("connectSettings.description")}</p>
          </div>
          <div className="col-12 col-lg-8">
            <BoundaryItem connectProjectId={connectProjectId} boundaryCoordinates={boundaryCoordinates} t={t} />
            <CoordinateItem connectProjectId={connectProjectId} crsName={crsName} t={t} />
          </div>
        </Card>
      </div>
    </div>
  )
}

const BoundaryItem = (props: { connectProjectId: string; boundaryCoordinates?: string; t: any }) => (
  <ProjectSettingGroup
    name={props.t("connectSettings.projectBoundary")}
    editLink={`https://web.connect.trimble.com/projects/${props.connectProjectId}/settings/details`}
    projectSettings={[{ name: props.t("connectSettings.coordinates"), value: props.boundaryCoordinates }]}
    optional={true}
    message={props.t("connectSettings.boundaryNotDefined")}
  />
)

const CoordinateItem = (props: { connectProjectId: string; crsName?: string; t: any }) => (
  <ProjectSettingGroup
    name={props.t("connectSettings.coordinateReferenceSystem")}
    editLink={`https://web.connect.trimble.com/projects/${props.connectProjectId}/settings/details`}
    projectSettings={[{ name: props.t("connectSettings.system"), value: props.crsName }]}
    optional={true}
    message={props.t("connectSettings.crsNotDefined")}
  />
)

export default ImportedProjectSettings
