import { useTranslation } from "react-i18next"
import Oops from "../Oops/Oops"

interface WorksetsErrorProps {
  worksetsQuery: any
}

export const WorksetsError = (props: WorksetsErrorProps) => {
  const { worksetsQuery } = props
  const { t } = useTranslation("worksets")

  return worksetsQuery.error ? (
    worksetsQuery.error.message.indexOf("access to this Quadri") !== -1 ? (
      <Oops body={t("worksetsError.updateMessage")} heading={t("worksetsError.errorHeading")} />
    ) : (
      <Oops heading={t("worksetsError.errorHeading")} />
    )
  ) : (
    <Oops />
  )
}
