import React from "react"

interface IProps {
  title?: string
  message: string
  icon: string
  children?: React.ReactNode
  isSmallContainer?: boolean
}

const Empty = ({ title, message, icon, isSmallContainer, ...props }: IProps) => (
  <div className={`empty ${isSmallContainer ? "small" : ""}`}>
    <div className="empty-icon">
      <i className={icon} />
    </div>
    <div className="empty-text">
      {title && <h3 className="text-muted">{title}</h3>}
      <p className="small text-muted">{message}</p>
    </div>
    {props.children && <div className="empty-action">{props.children}</div>}
  </div>
)

export default Empty
