import { useQuery } from "@apollo/client"
import { useRecoilState } from "recoil"
import { GET_ACTIVITY_TASKS } from "../../api/QuadriApi"
import { Activity } from "../../types"
import { SidebarTasksList } from "../SidebarTasksList"
import { sidebarTimelineState } from "../StateSelectors"
import { userFullName } from "../UserRow"
import { getActivityDetails } from "./util"
import { useTranslation } from "react-i18next"
import { exceptionFromApolloError } from "../../util/apolloError"

const MAX_TASKS = 20

const TimelineSidebar = () => {
  const [sidebar, setSidebar] = useRecoilState(sidebarTimelineState)
  console.log(sidebar)

  const activity = sidebar?.activity
  const users = sidebar?.users
  const quadriProjectId = sidebar?.quadriProjectId
  const user = users && activity ? users[activity.userId] : undefined
  const { t } = useTranslation("timeline")
  return (
    <div className="panel">
      <header>
        <div className="panel-title">
          <h3>{t("sidebar.activityDetails")}</h3>
        </div>
        <button className="button icon-circle icon-medium tertiary" onClick={() => setSidebar(null)}>
          <i className="icon-font tc-icon-content-close" />
        </button>
      </header>

      <section>
        <div className="panel-body">
          <div className="sub-section">
            <div className="section-header">
              <h5>{activity && getActivityDetails(activity)}</h5>
            </div>
            <div className="section-details pb-1">
              {activity?.worksetName && (
                <div className="label-group">
                  <label>{t("sidebar.workset")}</label>
                  <div className="value">{activity?.worksetName}</div>
                </div>
              )}
              {user && (
                <div className="label-group">
                  <label>{t("sidebar.user")}</label>
                  <div className="value">{userFullName(user)}</div>
                </div>
              )}
            </div>
          </div>

          {quadriProjectId && activity && activity.tasksAffectedCount ? (
            <TasksDisplay activity={activity} quadriProjectId={quadriProjectId} />
          ) : (
            <div className="sub-section">
              <div className="section-header" />
            </div>
          )}
        </div>
      </section>
    </div>
  )
}

const TasksDisplay = (props: { activity: Activity; quadriProjectId: string }) => {
  const { activity, quadriProjectId } = props

  const tasksTruncated = (activity.taskChanges?.length || 0) > MAX_TASKS
  const taskChangesTruncated = tasksTruncated ? activity.taskChanges?.slice(0, MAX_TASKS) : activity.taskChanges
  const taskIds = taskChangesTruncated?.map((tc) => tc.taskId) || []
  const { t } = useTranslation("timeline")

  console.log("requesting taskIds: ", taskIds)
  console.log("Tasks truncated: ", tasksTruncated)

  const { loading, error, data } = useQuery(GET_ACTIVITY_TASKS, {
    variables: { quadriProjectId, taskIds }
  })

  if (error) throw exceptionFromApolloError(error)

  return (
    <div className="sub-section">
      <div className="section-header">
        <h5>{t("sidebar.sharedTask")}</h5>
      </div>
      <div className="section-details">
        <SidebarTasksList
          loading={loading}
          tasksData={data?.getTasks}
          tasksCount={taskIds.length}
          truncated={tasksTruncated}
        />
      </div>
    </div>
  )
}

export default TimelineSidebar
