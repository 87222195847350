import React, { useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"

// import { translate } from "@fulcrum/lib";
require("@connect/date-range-picker/build/date-range-picker")

export interface IDateRangePicker {
  onChange: (event: Event, which: string) => void
  id: string
  selection: { from: string | null; to: string | null }
  label?: string
}

// export const DateRangePicker = ({ onChange, id, selection, label }: IDateRangePicker) => {
export const DateRangePicker = ({ onChange, id, selection }: IDateRangePicker) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    //Hook's version of ComponentDidMount
    if (ref.current) {
      ref.current.addEventListener("date-range-selected", (e: Event) => onChange(e, "date-range-picker"))
    }

    //Hook's version of ComponentWillUnmount
    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current.removeEventListener("date-range-selected", (e: Event) => onChange(e, "date-range-picker"))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { t } = useTranslation("fromconnect")
  const translationSource = [
    {
      type: "DICTIONARY",
      value: {
        Label: t("datePicker.label"),
        Title: t("datePicker.title"),
        From: t("datePicker.fromLabel"),
        To: t("datePicker.toLabel"),
        Today: t("datePicker.Today"),
        Yesterday: t("datePicker.Yesterday"),
        PastSevenDays: t("datePicker.PastSevenDays"),
        PastThirtyDays: t("datePicker.PastThirtyDays"),
        Reset: t("datePicker.Reset"),
        InvalidToDate: t("datePicker.InvalidToDate"),
        DEFAULT_DATE_FORMAT: t("datePicker.defaultDateFormat"),
        JAN: t("datePicker.jan"),
        FEB: t("datePicker.feb"),
        MAR: t("datePicker.mar"),
        APR: t("datePicker.apr"),
        MAYSH: t("datePicker.maysh"), // MAYSH for "MAY short". Some languages may (!) have a more-than-3-letter name for the month of may.
        JUN: t("datePicker.jun"),
        JUL: t("datePicker.jul"),
        AUG: t("datePicker.aug"),
        SEP: t("datePicker.sep"),
        OCT: t("datePicker.oct"),
        NOV: t("datePicker.nov"),
        DEC: t("datePicker.dec"),
        JANUARY: t("datePicker.january"),
        FEBRUARY: t("datePicker.february"),
        MARCH: t("datePicker.march"),
        APRIL: t("datePicker.april"),
        MAY: t("datePicker.may"),
        JUNE: t("datePicker.june"),
        JULY: t("datePicker.july"),
        AUGUST: t("datePicker.august"),
        SEPTEMBER: t("datePicker.september"),
        OCTOBER: t("datePicker.october"),
        NOVEMBER: t("datePicker.november"),
        DECEMBER: t("datePicker.december"),
        SUN: t("datePicker.sun"),
        MON: t("datePicker.mon"),
        TUE: t("datePicker.tue"),
        WED: t("datePicker.wed"),
        THU: t("datePicker.thu"),
        FRI: t("datePicker.fri"),
        SAT: t("datePicker.sat"),
        SUNDAY: t("datePicker.sunday"),
        MONDAY: t("datePicker.monday"),
        TUESDAY: t("datePicker.tuesday"),
        WEDNESDAY: t("datePicker.wednesday"),
        THURSDAY: t("datePicker.thursday"),
        FRIDAY: t("datePicker.friday"),
        SATURDAY: t("datePicker.saturday")
      }
    }
  ]

  return (
    <date-range-picker
      ref={ref}
      selection={JSON.stringify({
        from: selection.from ? new Date(selection.from) + "" : selection.from,
        to: selection.to ? new Date(selection.to) + "" : selection.to
      })}
      id={id}
      translation-source={JSON.stringify(translationSource)}
    />
  )
}

export default React.memo(DateRangePicker)
