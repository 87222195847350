import { ConnectUser } from "../api/ConnectApi"

interface UserRowProps {
  connectProjectId: string
  user: ConnectUser
}

export const UserRow = (props: UserRowProps) => {
  return (
    <div className="row-center">
      <div className="mr-2 avatar small borderless">
        <Thumbnail {...props} />
      </div>
      <div className="text-ellipsis">
        <UserLink {...props} />
        <EmployerField {...props} />
      </div>
    </div>
  )
}

const Thumbnail = (props: UserRowProps) => {
  const { user } = props
  const { thumbnail } = user
  const accessToken = localStorage.getItem("tc-access-token")
  const thumbnailUrl = `${thumbnail}&accessToken=${accessToken}`
  return <img src={thumbnailUrl} alt={`thumbnail ${userFullName(user)}`} />
}

const UserLink = (props: UserRowProps) => {
  const { connectProjectId, user } = props
  // eslint-disable-next-line no-restricted-globals
  var url = parent !== window ? document.referrer : document.location
  // console.log("url: ", url)
  const link = `https://web.connect.trimble.com/projects/${connectProjectId}/team/${user.id}`
  return (
    <h5 className="text-ellipsis">
      <a href={link} target="_blank" rel="noreferrer" className="link-secondary">
        {/* <a href={link} target="_parent" className="link-secondary"> /* Navigating parent window does not work */}
        {userFullName(user)}
      </a>
    </h5>
  )
}

const EmployerField = (props: UserRowProps) => {
  const companyName = props.user.company?.name
  return <>{companyName && <small className="text-meta text-ellipsis">{companyName}</small>}</>
}

export const userFullName = (user?: ConnectUser) => (user ? `${user.firstName} ${user.lastName}` : "")
