import { ApolloClient, gql, InMemoryCache, NormalizedCacheObject, createHttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import Environment from "../enviroments"

const QuadriApiBaseUrl = Environment.quadriGraphQlUrl

console.log("Using graphql url: ", QuadriApiBaseUrl)

export class QuadriApi {
  private baseUrl: string
  private client: ApolloClient<NormalizedCacheObject>

  constructor(baseUrl?: string) {
    this.baseUrl = baseUrl || QuadriApiBaseUrl

    // See https://www.apollographql.com/docs/react/networking/authentication/
    const link = authLink.concat(createHttpLink({ uri: this.baseUrl }))
    this.client = new ApolloClient({
      cache: new InMemoryCache(),
      link
    })
  }

  public getClient(): ApolloClient<NormalizedCacheObject> {
    return this.client
  }
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const accessToken = localStorage.getItem("tc-access-token")
  const idToken = localStorage.getItem("tc-id-token")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
      "x-jwt-assertion": idToken ? `Bearer ${idToken}` : ""
    }
  }
})

export const GET_ACTIVITIES = gql`
  query GET_ACTIVITIES($quadriProjectId: String!, $filter: ActivitiesFilter!) {
    getActivities(quadriProjectId: $quadriProjectId, filter: $filter) {
      type
      time
      userId
      description
      tasksAffectedCount
      worksetName
      taskChanges {
        taskId
        changeType
      }
    }
  }
`

export const GET_WORKSETS = gql`
  query GET_WORKSETS($quadriProjectId: String!, $filter: WorksetsFilter!) {
    getWorksets(quadriProjectId: $quadriProjectId, filter: $filter) {
      id
      name
      status
      userId
      createdTime
      reservedTaskIds
      reservedFeatures {
        featureTypeName
      }
      activity {
        type
        description
        time
      }
    }
  }
`

export const GET_WORKSET_TASKS = gql`
  query GET_WORKSET_TASKS($quadriProjectId: String!, $taskIds: [String!]!) {
    getTasks(quadriProjectId: $quadriProjectId, taskIds: $taskIds) {
      id
      name
      taskPath {
        name
      }
    }
  }
`

export const GET_PROJECT_STATUS = gql`
  query GET_PROJECT_STATUS($id: String!) {
    tryGetConnectQuadriProject(connectProjectId: $id) {
      found
      project {
        quadriProjectId
        projectStatus
      }
      error
    }
  }
`

export const GET_CONFIG = gql`
  query GET_CONFIG($id: String!) {
    tryGetConnectQuadriProject(connectProjectId: $id) {
      found
      project {
        quadriProjectId
        connectProjectId
        featureCatalogId
        coordinateSystemUnit
        projectStatusMessage
        projectStatus
        projectUpdateMessage
        createdAt
        updatedAt
        deletedAt
        projectCreatedWithStatus
      }
      error
    }
  }
`

export const GET_ROOTTASKS = gql`
  query GET_ROOTTASKS($quadriProjectId: String!) {
    getProjectRootTasks(quadriProjectId: $quadriProjectId) {
      id
      name
      type
      lastModifiedBy
      lastModifiedTime
      createdTime
      subTasks {
        id
        name
        type
      }
    }
  }
`
export const GET_TASKS = gql`
  query GET_TASKS($quadriProjectId: String!, $taskIds: [String!]!) {
    getTasks(quadriProjectId: $quadriProjectId, taskIds: $taskIds) {
      id
      name
      lastModifiedBy
      lastModifiedTime
      createdTime
      type
      subTasks {
        id
        name
        type
        lastModifiedBy
        lastModifiedTime
        createdTime
        subTasks {
          id
          name
          type
          lastModifiedBy
          lastModifiedTime
          createdTime
        }
      }
      taskPath {
        id
        name
      }
    }
  }
`
export const GET_ACTIVITY_TASKS = gql`
  query GET_ACTIVITY_TASKS($quadriProjectId: String!, $taskIds: [String!]!) {
    getTasks(quadriProjectId: $quadriProjectId, taskIds: $taskIds) {
      id
      name
      deleted
      type
      taskPath {
        id
        name
      }
    }
  }
`

export const HAS_ACCESS = gql`
  query HAS_ACCESS {
    hasAccess {
      access
    }
  }
`

export const FEATURE_CATALOG_STANDARDS = gql`
  query FEATURE_CATALOG_STANDARDS($tags: String) {
    featureCatalogStandards(tags: $tags) {
      id
      name
    }
  }
`

export const FEATURE_CATALOGS = gql`
  query FEATURE_CATALOGS($standardId: String, $minVersion: String, $tags: String) {
    featureCatalogs(standardId: $standardId, minVersion: $minVersion, tags: $tags) {
      id
      name
      standard
      version
      date
    }
  }
`

export const COMPATIBLE_FEATURE_CATALOGS = gql`
  query COMPATIBLE_FEATURE_CATALOGS($id: String!, $tags: String) {
    compatibleFeatureCatalogs(featureCatalogId: $id, tags: $tags) {
      id
      name
      standard
      version
      date
    }
  }
`

export const CREATE_PROJECT = gql`
  mutation CREATE_PROJECT($project: CreateProjectRequest!) {
    createProject(request: $project) {
      projectStatus
    }
  }
`

export const SYNC_PROJECT = gql`
  mutation SYNC_PROJECT($project: SyncProjectRequest!) {
    syncProject(request: $project) {
      projectStatus
    }
  }
`

export const DELETE_PROJECT = gql`
  mutation DELETE_PROJECT($deleteProjectRequest: DeleteProjectRequest!) {
    deleteProject(request: $deleteProjectRequest) {
      success
    }
  }
`

export const DISCONNECT_WORKSET = gql`
  mutation DISCONNECT_WORKSET($quadriProjectId: String!, $worksetId: String!) {
    disconnectWorkset(quadriProjectId: $quadriProjectId, worksetId: $worksetId) {
      success
    }
  }
`
