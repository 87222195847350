import { useMutation } from "@apollo/client"
import React from "react"
import { useTranslation } from "react-i18next"
import { DISCONNECT_WORKSET } from "../../api/QuadriApi"
import Modal from "../Modal"

interface DisconnectModalProps {
  closeModal: () => void
  worksetName: string
  worksetId: string
  quadriProjectId?: string
  user: string
}

const deleteRefreshDelayMs = 500

const DisconnectModal = (props: DisconnectModalProps) => {
  const [disconnecting, setDisconnecting] = React.useState(false)
  const [error, setError] = React.useState()
  const { t } = useTranslation("worksets")

  const [diconnectWorksetMutation] = useMutation(DISCONNECT_WORKSET, {
    onCompleted: async (data) => {
      if (data.disconnectWorkset.success) {
        setTimeout(() => {
          setDisconnecting(false)
          // eslint-disable-next-line no-restricted-globals
          ;(location as any).reload(true)
        }, deleteRefreshDelayMs)
      }
    }
  })

  const disconnectWorkset = () => {
    setDisconnecting(true)
    diconnectWorksetMutation({
      variables: {
        quadriProjectId: props.quadriProjectId,
        worksetId: props.worksetId
      }
    })
      .then((r: any) => console.log("Success: ", r))
      .catch((e: any) => {
        console.error("Error in disconnect mutation promise: ", e)
        setError(e.message)
        setDisconnecting(false)
      })
  }

  return (
    <Modal
      closeModal={props.closeModal}
      heading={t("disconnectModal.disconnectWorkset")}
      submitButtonText={
        disconnecting ? t("disconnectModal.disconnectingWorkset") : t("disconnectModal.disconnectWorkset")
      }
      submitButtonClasses="warning"
      onSubmitButtonClicked={disconnectWorkset}
    >
      {t("disconnectModal.disconnectPrompt")}
      <br />
      <br />
      <table>
        <tr>
          <td>{t("disconnectModal.workset")}:&nbsp;&nbsp;</td>
          <td>
            <b>{props.worksetName}</b>
          </td>
        </tr>
        <tr>
          <td>{t("disconnectModal.user")}:&nbsp;&nbsp;</td>
          <td>
            <b>{props.user}</b>
          </td>
        </tr>
      </table>
      {error && (
        <div className="alert error py-2 mt-2">
          {t("disconnectModal.failedMessage")}: {formatError(error, t)}
        </div>
      )}
    </Modal>
  )
}

const formatError = (message: string, t: any) => {
  console.error(
    "Error when disconnecting workset: ",
    message.indexOf("ADMIN") !== -1
      ? message + ". After giving ADMIN privileges in Connect, you need to run Update in the Quadri Extension"
      : message
  )
  return t("disconnectModal.checkErrorLog")
}

export default DisconnectModal
