import ConnectApi, { ConnectUser } from "../api/ConnectApi"

interface GetUsersByTidUuidProps {
  connectProjectId?: string
  connectProjectOrigin?: string
}

export const getUsersByTidUuid = async (props: GetUsersByTidUuidProps): Promise<{ [key: string]: ConnectUser }> => {
  const { connectProjectId, connectProjectOrigin } = props
  if (!connectProjectId || !connectProjectOrigin) {
    return {}
  } else {
    const usersByTidUuid = await new ConnectApi(connectProjectOrigin).getConnectProjectUsersByTidUuid(connectProjectId)
    return usersByTidUuid
  }
}

interface GetLoggedInUserProjectRoleProps extends GetUsersByTidUuidProps {}

export const getLoggedInUserProjectRole = async (props: GetLoggedInUserProjectRoleProps): Promise<string> => {
  const { connectProjectId, connectProjectOrigin } = props
  if (!connectProjectId || !connectProjectOrigin) {
    throw new Error("Connect project ID and origin needed to get logged in user role")
  } else {
    const api = new ConnectApi(connectProjectOrigin)
    const projectRoles = await api.getLoggedInUserProjectRoles()
    const projectRole = projectRoles.find((p: any) => p.id === connectProjectId)
    if (projectRole) {
      return projectRole.role
    } else {
      throw new Error(`User has no role in project ${connectProjectId}`)
    }
  }
}
