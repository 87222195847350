import React from "react"

export interface NoDataProps {
  icon: string
  heading: string
  message: string
}

const NoData = (props: NoDataProps) => {
  return (
    <div className="d-flex h-100 flex-column justify-content-center align-items-center text-center text-muted">
      <div
        className="bg-gray_0 d-block rounded d-flex justify-content-center align-items-center mb-1"
        style={{ width: "192px", height: "192px" }}
      >
        <i className={`icon-font i64 ${props.icon}`}></i>
      </div>
      <h1 className="mb-1">{props.heading}</h1>
      <p>{props.message}</p>
    </div>
  )
}

export default NoData
