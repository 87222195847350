import { useRecoilValue, useSetRecoilState, useRecoilValueLoadable } from "recoil"
import { useHistory } from "react-router-dom"
import { sidebarTaskState, quadriProjectIdState, projectIdState, usersByTidUuidState } from "../StateSelectors"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { iconBindings } from "../../assets/icons/quadriWindows/iconBindings"
import { TaskSidebar } from "../../types"
import TasksActivities from "./TasksActivities"
import { userFullName } from "../UserRow"
import { makeDatePersonEntry } from "./TasksMain"
import "./Tasks.css"

enum ActiveTab {
  TaskDetails = 0,
  Activity = 1
}

const TasksSidebar = () => {
  const [tabActive, setActiveTab] = useState(ActiveTab.TaskDetails)

  const setSidebar = useSetRecoilState(sidebarTaskState)

  const quadriProjectId = useRecoilValue(quadriProjectIdState)

  const projectId = useRecoilValue(projectIdState)

  const users = useRecoilValueLoadable(usersByTidUuidState)
  const { t } = useTranslation("tasks")
  const cBy = t("runtimeTexts.by")
  const cNone = t("runtimeTexts.noData")

  if (!quadriProjectId) throw new Error(t("tasksSidebar.noQuadriProject"))
  if (!projectId) throw new Error(t("tasksSidebar.noConnectProject"))

  const taskInfo = useRecoilValue(sidebarTaskState) ?? null // Info about the task in the main grid whose subtasks are being shown in the sidebar

  const taskName = taskInfo ? taskInfo.taskName : t("tasksSidebar.noName")

  const taskIds =
    taskInfo && taskInfo.subTasks
      ? taskInfo.subTasks.map(function (item: any) {
          return item.id
        })
      : []

  const history = useHistory()

  function ChangeTask(ownerTaskId: number | undefined, clickedSubtask: any, by: string, none: string) {
    if (!ownerTaskId) return
    const writeTaskInfo = {
      taskName: clickedSubtask.name,
      taskId: clickedSubtask.id,
      subTasks: clickedSubtask.subTasks,
      clickedTaskId: clickedSubtask.id,
      createdData: makeDatePersonEntry(clickedSubtask.createdTime, "", by, none),
      lastModifiedData: makeDatePersonEntry(
        clickedSubtask.lastModifiedTime,
        userFullName(users.contents[clickedSubtask.lastModifiedBy]),
        by,
        none
      )
    } as TaskSidebar
    setSidebar(writeTaskInfo)
    const changeTo = "/tasks/" + ownerTaskId + "/" + clickedSubtask.id
    history.push(changeTo)
  }

  return (
    <div className="panel">
      <header>
        <div className="panel-title">
          <h3>{taskName}</h3>
        </div>
        <button className="button icon-circle icon-medium tertiary" onClick={() => setSidebar(null)}>
          <i className="icon-font tc-icon-content-close" />
        </button>
      </header>
      <section>
        <div className="sub-section">
          {/* tabs section*/}
          <ul className="tabs no-gap">
            <li
              className={`tabs-title text-ellipsis ${tabActive === ActiveTab.TaskDetails && "active"}`}
              onClick={() => setActiveTab(ActiveTab.TaskDetails)}
            >
              <span className="text-ellipsis px-1">{t("tasksSidebar.details")}</span>
            </li>
            <li
              className={`tabs-title text-ellipsis" ${tabActive === ActiveTab.Activity && "active"}`}
              onClick={() => setActiveTab(ActiveTab.Activity)}
            >
              <span className="text-ellipsis px-1">{t("tasksSidebar.activity")}</span>
            </li>
          </ul>
          <div className="tabs-content">
            {/* Task details */}
            <div className={`tabs-panel ${tabActive === ActiveTab.TaskDetails && "active"}`}>
              <div className="pb-1 border-bottom">
                <div className="label-group ml-2">
                  <label>{t("tasksSidebar.created")}</label>
                  <div className="value">{taskInfo?.createdData}</div>
                </div>
                <div className="label-group ml-2">
                  <label>{t("tasksSidebar.latestChange")}</label>
                  <div className="value">{taskInfo?.lastModifiedData}</div>
                </div>
              </div>
              <h4 className="pt-2 pl-2 pb-2 text-semibold">
                {t("tasksSidebar.subTasks")} ({taskInfo && taskInfo.subTasks ? taskInfo.subTasks.length : 0})
              </h4>
              <ul className="list">
                {/* {taskData?.getTasks.map((item: any) => ( */}
                {taskInfo?.subTasks?.map((item: any) => (
                  <li
                    className="list-item group selectable pl-2 pt-1"
                    key={item.id}
                    onClick={() => ChangeTask(taskInfo?.taskId, item, cBy, cNone)}
                  >
                    <img src={iconBindings(item.type)} className="mb-n1 mr-1" width="24" height="24" alt="a" />
                    <span className="underline text-semibold text_col_trimble_blue_mid">{item.name}</span>
                  </li>
                ))}
              </ul>
              {/* <span>{waitOrError}</span> */}
            </div>
            {/* End of Task details, start of Task activities */}
            <div className={`tabs-panel ${tabActive === ActiveTab.Activity && "active"}`}>
              <TasksActivities projectId={projectId} taskId={taskInfo?.taskId} />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default TasksSidebar
