import React from "react"
import { useTranslation } from "react-i18next"
import { ProjectCreatedWithStatus } from "../../types"
import { formatDateAsConnect } from "../../util/dateTools"
import { ConfigStatus } from "./ConfigMain"

interface ProgressBannerProps {
  retryProcess: (e: any) => void
  configStatus: ConfigStatus
  errorMessage?: string
  updateErrorMessage?: string
  disableRetry?: boolean
  createdDateTime?: string
  lastUpdatedDateTime?: string
  createdAt?: string
  updatedAt?: string
  projectCreatedWithStatus?: ProjectCreatedWithStatus
}

export const ProgressBanner = (props: ProgressBannerProps) => {
  const [show, setShow] = React.useState(true)
  const [expanded, setExpanded] = React.useState(false)

  const hideBanner = () => setShow(false)
  const toggleExpand = () => setExpanded(!expanded)

  const innerProps = { ...props, expanded, toggleExpand, hideBanner }

  return <>{show && <ChooseProgressBanner {...innerProps} />}</>
}

interface ProgressBannerInnerProps extends ProgressBannerProps {
  expanded: boolean
  toggleExpand: () => void
  hideBanner: () => void
}

const ChooseProgressBanner = (props: ProgressBannerInnerProps) => {
  switch (props.configStatus) {
    case "Active":
      return <ActiveBanner {...props} />
    case "Creating":
      return <CreatingBanner {...props} />
    case "Failed":
      return <FailedBanner {...props} />
    default:
      return null
  }
}
const CreatingBanner = (props: ProgressBannerInnerProps) => {
  const { expanded, toggleExpand } = props
  const { t } = useTranslation("config")

  return (
    <BannerOuter>
      <BannerCard>
        <BannerTitle>
          <BannerTitleLeft>
            <i className="icon-font i32 text_col_trimble_blue_mid tc-icon-quadri"></i>
            <h2 className="mx-2">{t("progressBanner.enablingQuadri")}</h2>
          </BannerTitleLeft>
          <BannerTitleRight>
            <button className="button border-0 icon-medium icon-circle tertiary" onClick={toggleExpand}>
              <i className={`icon-font i32 ${expanded ? "tc-icon-chevron-up" : "tc-icon-chevron-down"}`}></i>
            </button>
          </BannerTitleRight>
        </BannerTitle>
        <BannerBody expanded={expanded}>
          <div className="flex-col col-12 col-md-8">
            <hr />
            <p className="text-meta">{t("progressBanner.waitMessage")}</p>
          </div>
        </BannerBody>
      </BannerCard>
      <ProgressBar percentage={55} color="#15619f" />
    </BannerOuter>
  )
}

const FailedBanner = (props: ProgressBannerInnerProps) => {
  const { expanded, toggleExpand, hideBanner, disableRetry, retryProcess } = props
  const { t } = useTranslation("config")
  return (
    <BannerOuter>
      <BannerCard>
        <BannerTitle>
          <BannerTitleLeft>
            <i className="icon-font tc-icon-error i32" style={{ color: "#c81922" }}></i>
            <h2 className="mx-2">{t("progressBanner.somethingWentWrong")}</h2>
          </BannerTitleLeft>
          <BannerTitleRight>
            <button className="button border-0 default ml-1" disabled={disableRetry || false} onClick={retryProcess}>
              {t("progressBanner.retry")}
            </button>
            {props.errorMessage && (
              <button className="button border-0 icon-medium icon-circle tertiary ml-1" onClick={toggleExpand}>
                <i className={`icon-font i32 ${expanded ? "tc-icon-chevron-up" : "tc-icon-chevron-down"}`}></i>
              </button>
            )}
            <button className="button border-0 icon-medium icon-circle tertiary ml-1" onClick={hideBanner}>
              <i className="icon-font i32 tc-icon-content-close"></i>
            </button>
          </BannerTitleRight>
        </BannerTitle>
        <BannerBody expanded={expanded && typeof props.errorMessage != "undefined"}>
          <div className="flex-col col-12 col-md-8">
            <hr />
            <p className="text-meta">{props.errorMessage && decodeURIComponent(props.errorMessage)}</p>
          </div>
        </BannerBody>
      </BannerCard>
      <ProgressBar percentage={100} color="#c81922" />
    </BannerOuter>
  )
}

const ActiveBanner = (props: ProgressBannerInnerProps) => {
  const { createdAt, updatedAt, updateErrorMessage, toggleExpand, expanded, projectCreatedWithStatus } = props
  const { t } = useTranslation("config")
  // const createdAt = "2021-10-16T12:21:31+00:00"
  // const lastUpdated = "2021-10-19T13:31:31+00:00"

  const createdWithStatus = projectCreatedWithStatus === "CreatedWithoutArea"
  const message =
    updateErrorMessage || (createdWithStatus ? t("projectCreatedWithStatus." + projectCreatedWithStatus) : undefined)

  return (
    <BannerOuter>
      <BannerCard>
        <BannerTitle>
          <BannerTitleLeft>
            {updateErrorMessage ? (
              <i className="icon-font i32 tc-icon-warning warning" style={{ color: "#FFBE00" }}></i>
            ) : createdWithStatus ? (
              <i className="icon-font i32 tc-icon-info-outline" style={{ color: "#15619f" }}></i>
            ) : (
              <i className="icon-font i32 tc-icon-check-circle-outline" style={{ color: "#5E9331" }}></i>
            )}
            <div className="mx-2">
              <h2>
                {createdWithStatus ? t("progressBanner.quadriEnabledWithNotes") : t("progressBanner.quadriEnabled")}
              </h2>
              {updateErrorMessage && <span className="text-meta">{t("progressBanner.updateWentWrong")}</span>}
            </div>
          </BannerTitleLeft>
          <BannerTitleRight>
            {createdAt && (
              <p className="text-meta ml-2">
                {t("progressBanner.enabled")}: <strong>{formatDateAsConnect(createdAt)}</strong>
              </p>
            )}
            {updatedAt && (
              <p className="text-meta ml-2">
                {t("progressBanner.lastUpdated")}: <strong>{formatDateAsConnect(updatedAt)}</strong>
              </p>
            )}
            {message && (
              <button className="button border-0 icon-medium icon-circle tertiary ml-1" onClick={toggleExpand}>
                <i className={`icon-font i32 ${expanded ? "tc-icon-chevron-up" : "tc-icon-chevron-down"}`}></i>
              </button>
            )}
          </BannerTitleRight>
        </BannerTitle>
        <BannerBody expanded={expanded && typeof props.updateErrorMessage != "undefined"}>
          <div className="flex-col col-12">
            <hr />
            <p className="text-meta">{message && decodeURIComponent(message)}</p>
          </div>
        </BannerBody>
      </BannerCard>
    </BannerOuter>
  )
}

const BannerOuter = (props: { children?: React.ReactNode }) => (
  <div className="col-12">
    <section className="module mb-3 p-0">{props.children}</section>
  </div>
)

const BannerCard = (props: { children?: React.ReactNode }) => <div className="p-4">{props.children}</div>

const BannerTitle = (props: { children?: React.ReactNode }) => (
  <div className="d-flex flex-row align-center">{props.children}</div>
)

const BannerBody = (props: { children?: React.ReactNode; expanded?: boolean }) => (
  <div className="d-flex row">
    <>{props.expanded && props.children}</>
  </div>
)

const BannerTitleLeft = (props: { children?: React.ReactNode }) => (
  <div className="d-flex flex-row align-center">{props.children}</div>
)

const BannerTitleRight = (props: { children?: React.ReactNode }) => (
  <div className="ml-auto d-flex flex-row align-center">{props.children}</div>
)

const ProgressBar = (props: { percentage: number; color: string }) => (
  <div
    className="col-12 m-0 p-0"
    style={{
      height: 5,
      width: "100%",
      backgroundColor: "#c1c0d1",
      borderRadius: 0,
      margin: 0,
      boxSizing: "inherit"
    }}
  >
    <div
      className="col-12"
      style={{
        height: 5,
        width: `${props.percentage}%`,
        backgroundColor: `${props.color}`,
        position: "absolute"
      }}
    />
  </div>
)
