import moment from "moment"

// Converts null to undefined
const convertDate = (connectDate: string | null) => (connectDate ? connectDate : undefined)

// Do nothing with the from date
export const convertFromDate = convertDate

// Add one day to the "to"-Date for an "inclusive" filter
export const convertToDate = (connectDate: string | null) => (connectDate ? adjustToDate(connectDate) : undefined)

// Adds one day to the date
const adjustToDate = (connectDateString: string) => {
  const connectDate = new Date(connectDateString)
  const adjustedDate = addDays(connectDate, 1)
  return adjustedDate.toString()
}

// Add N amount of days to a date
const addDays = (date: Date, days: number) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

// Format the date similar to the Connect style
export const formatDateAsConnect = (date: string) => moment(new Date(date)).format("MMM DD YYYY hh:mm a z")

// Format the date similar to the Connect style, do not show time-of-day
export const formatDateOnlyAsConnect = (date: string) => moment(new Date(date)).format("MMM DD YYYY z")
