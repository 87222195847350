import * as React from "react"
import { useTranslation } from "react-i18next"

export const LicenseBanner = () => {
  const navigate = () => {
    window.open("https://quadri.trimble.com/buy", "_blank")?.focus()
  }

  const { t } = useTranslation("config")

  return (
    <div className="alert warning group my-2" role="alert">
      <div className="group-items">
        <span className="block flex-grow-1">{t("licenseBanner.noLicenseMessage")}</span>
        <button className="button cta small" onClick={navigate} title={t("licenseBanner.opensNewTab")}>
          {t("licenseBanner.buyLicense")}
        </button>
      </div>
    </div>
  )
}
