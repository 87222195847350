import React from "react"

import TasksMain from "./Tasks/TasksMain"
import TasksSidebar from "./Tasks/TasksSidebar"

import TimelineMain from "./Timeline/TimelineMain"
import TimelineSidebar from "./Timeline/TimelineSidebar"

import WorksetsMain from "./Worksets/WorksetsMain"
import WorksetsSidebar from "./Worksets/WorksetsSidebar"

import ConfigMain from "./Config/ConfigMain"

export interface RoutesType {
  exact: boolean
  path: string
  main: JSX.Element
  sidebar?: JSX.Element
}

const Routes: RoutesType[] = [
  {
    //used for showing the top-level tasks collection
    exact: true,
    path: "/tasks",
    main: <TasksMain />,
    sidebar: <TasksSidebar />
  },
  {
    // used for showing a subtask and its children
    exact: true,
    path: "/tasks/:subTaskId",
    main: <TasksMain />,
    sidebar: <TasksSidebar />
  },
  {
    // used for switching subtask from the sidebar
    exact: true,
    path: "/tasks/:subTaskId/:subSubTaskId",
    main: <TasksMain />,
    sidebar: <TasksSidebar />
  },
  {
    exact: true,
    path: "/timeline",
    main: <TimelineMain />,
    sidebar: <TimelineSidebar />
  },
  {
    exact: true,
    path: "/worksets",
    main: <WorksetsMain />,
    sidebar: <WorksetsSidebar />
  },
  {
    exact: true,
    path: "/config",
    main: <ConfigMain />
  }
]

export default Routes
