import React, { useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
// import { translate } from "@fulcrum/lib";
require("@connect/user-picker/build/user-picker")

export interface IUserPicker {
  onChange: (event: Event, which: string) => void
  selectionList: string[]
  id: string
  apiUrl: string
}

const UserPicker = ({ onChange, apiUrl, selectionList, id }: IUserPicker) => {
  const ref = useRef<HTMLInputElement>(null)
  const ACCESS_TOKEN = localStorage.getItem("tc-access-token")

  useEffect(() => {
    //Hook's version of ComponentDidMount
    if (ref.current) {
      ref.current.addEventListener("selection-changed", (e: Event) => onChange(e, "user-picker"))
    }

    //Hook's version of ComponentWillUnmount
    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current.removeEventListener("selection-changed", (e: Event) => onChange(e, "user-picker"))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation("fromconnect")

  const translationSource = [
    {
      type: "DICTIONARY",
      value: {
        Label: t("user.label"),
        SearchPlaceHolder: t("user.searchPlaceHolder"),
        LastUpdated: t("user.lastUpdated"),
        NoResults: t("user.noResults")
      }
    }
  ]

  return (
    <user-picker
      ref={ref}
      selection-list={`${JSON.stringify(selectionList)}`}
      id={id}
      api-url={apiUrl}
      access-token={ACCESS_TOKEN}
      translation-source={JSON.stringify(translationSource)}
    />
  )
}

export default React.memo(UserPicker)
