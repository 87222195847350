import { ApolloError } from "@apollo/client"

export const exceptionFromApolloError = (apolloError: ApolloError) => {
  console.log("Converting error!")
  if (apolloError.networkError) {
    const networkError = apolloError.networkError as any
    return new Error(
      `Network Error when calling GraphQL. Status Code: ${networkError.statusCode}, Description: ${networkError.bodyText}`
    )
  } else {
    return new Error(apolloError.message)
  }
}
