import Present3D from "./Present3D_svg.svg"
import ModelingBuilding from "./ModelingBuilding_svg.svg"
import NovapointAirportMarking from "./NovapointAirportMarking_svg.svg"
import ModelingAlignment from "./ModelingAlignment_svg.svg"
import HomeAttachment from "./HomeAttachment_svg.svg"
import NovapointBridge from "./NovapointBridge_svg.svg"
import ModelingLocateAlignNetwork from "./ModelingLocateAlignNetwork_svg.svg"
import ModelingExtrude from "./ModelingExtrude_svg.svg"
import ModelingSweep from "./ModelingSweep_svg.svg"
import PresentCrossSection from "./PresentCrossSection_svg.svg"
import HomeClassification from "./HomeClassification_svg.svg"
import HomeClass from "./HomeClass_svg.svg"
import Connectors_Design from "./Connectors_Design_svg.svg"
import ModelingGeometryCleanup from "./ModelingGeometryCleanup_svg.svg"
import ExplorerBinder from "./ExplorerBinder_svg.svg"
import ExplorerUncategorizedObjects from "./ExplorerUncategorizedObjects_svg.svg"
import HomeCollection from "./HomeCollection_svg.svg"
import InsertImportFiles from "./InsertImportFiles_svg.svg"
import NovapointIntersection from "./NovapointIntersection_svg.svg"
import NovapointLandUsePlanning from "./NovapointLandUsePlanning_svg.svg"
import PresentLongSection from "./PresentLongSection_svg.svg"
import ModelingMoveVertically from "./ModelingMoveVertically_svg.svg"
import NovapointNoise from "./NovapointNoise_svg.svg"
import PresentPlan from "./PresentPlan_svg.svg"
import OutputExportToFile from "./OutputExportToFile_svg.svg"
import HomeProjectRules from "./HomeProjectRules_svg.svg"
import NovapointRailway from "./NovapointRailway_svg.svg"
import NovapointRoad from "./NovapointRoad_svg.svg"
import NovapointRoadMarking from "./NovapointRoadMarking_svg.svg"
import NovapointRoadSign from "./NovapointRoadSign_svg.svg"
import ExplorerTaskProcess from "./ExplorerTaskProcess_svg.svg"
import NovapointSlopeSurface from "./NovapointSlopeSurface_svg.svg"
import ModelingSubSurface from "./ModelingSubSurface_svg.svg"
import ExplorerGroundSurface from "./ExplorerGroundSurface_svg.svg"
import PresentTable from "./PresentTable_svg.svg"
import ModelingElevationFromSurface from "./ModelingElevationFromSurface_svg.svg"
import NovapointTerrainShaping from "./NovapointTerrainShaping_svg.svg"
import NovapointTunnel from "./NovapointTunnel_svg.svg"
import NovapointTunnelGeoRock from "./NovapointTunnelGeoRock_svg.svg"
import NovapointTunnelScanData from "./NovapointTunnelScanData_svg.svg"
import NovapointTunnelShiftLog from "./NovapointTunnelShiftLog_svg.svg"
import PresentSaveAsViewpoint from "./PresentSaveAsViewpoint_svg.svg"
import ModelingVolumes from "./ModelingVolumes_svg.svg"
import NovapointWaterAndSewer from "./NovapointWaterAndSewer_svg.svg"
import NovapointWaterway from "./NovapointWaterway_svg.svg"
import SummaryTask from "../quadri/SummaryTask_24.svg"
const iconBindings = (tag: string) => {
  switch (tag) {
    case "3D_PRESENTATION":
      return Present3D
    case "3DBuilding":
      return ModelingBuilding
    case "AirportMarking":
      return NovapointAirportMarking
    case "AlignmentDesign":
      return ModelingAlignment
    case "AttachFiles":
      return HomeAttachment
    case "BridgeDesign":
      return NovapointBridge
    case "CalcualteSurfaceIntersectionLines":
      return ModelingVolumes
    case "ClassificationConnectToNetwork":
      return ModelingLocateAlignNetwork
    case "Collection":
      return HomeCollection
    case "CreateSolidExtrudeInbox":
      return ModelingExtrude
    case "CreateSolidSweepInbox":
      return ModelingSweep
    case "CROSS_SECTION_PRESENTATION":
      return PresentCrossSection
    case "ExportAsBaseline":
      return OutputExportToFile
    case "ExportProcessTask":
      return OutputExportToFile
    case "FieldCode":
      return HomeClassification
    case "FieldCodeSet":
      return HomeClass
    case "GeneralDesign":
      return Connectors_Design
    case "GeometryCleanup":
      return ModelingGeometryCleanup
    case "GeoSuiteFeatureContainer":
      return ExplorerBinder
    case "Group":
      return ExplorerUncategorizedObjects
    case "Highlight":
      return HomeCollection
    case "ImportProcessTask":
      return InsertImportFiles
    case "IntersectionDesign":
      return NovapointIntersection
    case "LandUsePlanning":
      return NovapointLandUsePlanning
    case "LONG_SECTION_PRESENTATION":
      return PresentLongSection
    case "MoveVerticallyInbox":
      return ModelingMoveVertically
    case "Noise":
      return NovapointNoise
    case "PLAN_PRESENTATION":
      return PresentPlan
    case "PRESENT_AS_DGN_TOOL":
      return OutputExportToFile
    case "PRESENT_AS_DWG_TOOL":
      return OutputExportToFile
    case "PRESENT_AS_FBX_TOOL":
      return OutputExportToFile
    case "PRESENT_AS_PDF3D_TOOL":
      return OutputExportToFile
    case "PRESENT_AS_TRIMBIM_TOOL":
      return OutputExportToFile
    case "ProjectRules":
      return HomeProjectRules
    case "RailwayDesign":
      return NovapointRailway
    case "RoadDesign":
      return NovapointRoad
    case "RoadMarking":
      return NovapointRoadMarking
    case "RoadSign":
      return NovapointRoadSign
    case "ServerProject":
      return ExplorerTaskProcess
    case "SlopeSurfaceDesign":
      return NovapointSlopeSurface
    case "SubSurface":
      return ModelingSubSurface
    case "SurfaceTool":
      return ExplorerGroundSurface
    case "TABLE_PRESENTATION":
      return PresentTable
    case "TerrainDefinitionDesign":
      return ModelingElevationFromSurface
    case "TerrainShaping":
      return NovapointTerrainShaping
    case "TinIntersection":
      return ModelingVolumes
    case "TunnelDesign":
      return NovapointTunnel
    case "TunnelGeologyAndRockSupport":
      return NovapointTunnelGeoRock
    case "TunnelImportScannerData":
      return NovapointTunnelScanData
    case "TunnelScannerData":
      return NovapointTunnelScanData
    case "TunnelShiftLog":
      return NovapointTunnelShiftLog
    case "VIEWPOINT_PRESENTATION":
      return PresentSaveAsViewpoint
    case "VolumeAnalysisDesign":
      return ModelingVolumes
    case "VolumeBetweenSurfaces":
      return ModelingVolumes
    case "VolumeCalculation":
      return ModelingVolumes
    case "VolumeCreator":
      return ModelingVolumes
    case "VolumeSurface":
      return ModelingVolumes
    case "WaterSewerDesign":
      return NovapointWaterAndSewer
    case "WaterwayDesign":
      return NovapointWaterway
  }
  return SummaryTask // If no specific hit, use folder icon
}
export { iconBindings }
