import React from "react"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css"
import get from "lodash.get"

export type IPositions =
  | "top-start"
  | "top-end"
  | "right"
  | "right-start"
  | "right-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end"
  | "auto"
  | "auto-start"
  | "auto-end"

interface IProps {
  title: string
  position?: IPositions
  notDynamic?: true
}

class CustomTooltip extends React.Component<IProps> {
  public isOverFlow = (el: any) => {
    if (this.props.notDynamic) return
    const scrollWidth = get(el, "reference.scrollWidth", 0)
    const offsetWidth = get(el, "reference.offsetWidth", 0)
    if (!!scrollWidth && !!offsetWidth && scrollWidth === offsetWidth) {
      return false
    }
  }

  public render() {
    const { title, position = "top" } = this.props
    return (
      <Tippy content={title} arrow={true} placement={position} onShow={(instance) => this.isOverFlow(instance)}>
        {this.props.children as React.ReactElement<any>}
      </Tippy>
    )
  }
}

export default CustomTooltip
