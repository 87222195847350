import React from "react"

interface CardProps extends React.HTMLProps<HTMLDivElement> {
  header?: string
  children?: React.ReactNode
}

export const Card = (props: CardProps) => {
  const { header, children, ...divProps } = props
  return (
    <section className="module mb-3 p-4" {...divProps}>
      {props.header && (
        <header>
          <h3>{props.header}</h3>
        </header>
      )}
      <div className="row">{props.children}</div>
    </section>
  )
}
