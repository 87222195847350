import * as React from "react"

export interface IProps {
  show: boolean
  message?: string
}

const Spinner: React.FunctionComponent<IProps> = (props: IProps) => {
  return props.show ? (
    <div className="empty">
      <div className="center">
        <i className="icon-font tc-icon-spinner-dark"></i>
        {props.message && <p className="text-meta ml-1">{props.message}</p>}
      </div>
    </div>
  ) : null
}

export default Spinner
