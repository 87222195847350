import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { useState } from "react"
import { GET_ACTIVITIES } from "../../api/QuadriApi"
import { UserRow } from "../UserRow"
import { formatDateAsConnect } from "../../util/dateTools"
import { useRecoilValue, useRecoilValueLoadable } from "recoil"
import DateRangePicker from "../FromConnect/DateRangePicker"
import UserPicker from "../FromConnect/UserPicker"
import { getOnFilterChanged } from "../../util/filtering"
import { Activity, ActivitiesFilter } from "../../types"
import { projectOriginState, quadriProjectIdState, usersByTidUuidState } from "../StateSelectors"

interface ActivitiesProps {
  projectId: string
  taskId: number | undefined
}

const TasksActivities = (props: ActivitiesProps) => {
  const projectId = props.projectId
  const projectOrigin = useRecoilValue(projectOriginState)
  const taskId = props.taskId
  const quadriProjectId = useRecoilValue(quadriProjectIdState)
  const users = useRecoilValueLoadable(usersByTidUuidState)
  const [userFilter, setUserFilter] = useState({} as ActivitiesFilter)
  const { t } = useTranslation("tasks")

  const datePickerDefaultValue = {
    from: null,
    to: null
  }

  const filter = getDefaultFilter(taskId)

  const {
    loading: actLoading,
    error: actError,
    data: actData
  } = useQuery(GET_ACTIVITIES, {
    variables: { quadriProjectId, filter },
    errorPolicy: "all"
  })

  const _onFilterChange = getOnFilterChanged(setUserFilter)

  const onFilterChange = (event: any, source: string) => {
    _onFilterChange(event, source)
  }

  function filterCheck(entry: Activity, theFilter: ActivitiesFilter) {
    const latestChangeDate = new Date(entry.time)
    const fromDate = new Date(theFilter.startDate ?? "1990-01-01")
    const toDate = new Date(theFilter.endDate ?? "2990-01-01")
    if (fromDate > latestChangeDate || toDate < latestChangeDate) {
      return false
    }
    if (theFilter.tidUserIds?.length) {
      if (!theFilter.tidUserIds?.find((uId: any) => uId === entry.userId)) return false
    }
    return true
  }

  if (actLoading) {
    return <div>{t("tasksActivities.loading")}</div>
  } else if (actError) {
    return (
      <div>
        <p>{t("tasksActivities.errLoading")}</p>
        <p>{actError.message}</p>
      </div>
    )
  }
  const usersApiUrl = `${projectOrigin}/projects/${projectId}/users?includesRemoved=false`

  let mapKey = 0 // Difficult to find a guaranteed unique key in the listed items

  return (
    <div>
      <div>
        <div className="filters border-bottom">
          <ul>
            <li className="filter-type lift-bottom">
              <UserPicker onChange={onFilterChange} apiUrl={usersApiUrl} id="activity-user-picker" selectionList={[]} />
            </li>
            <li className="filter-type">
              <DateRangePicker
                onChange={onFilterChange}
                id="activity-date-range-picker"
                selection={datePickerDefaultValue}
              />
            </li>
          </ul>
        </div>
        <ul className="list">
          {actData?.getActivities.map((item: Activity) =>
            filterCheck(item, userFilter) ? (
              <li className="list-item group selectable pl-2 pt-1 border-bottom" key={++mapKey}>
                <p className="pb-1 small">{formatDateAsConnect(item.time)}</p>
                <div className="activity">
                  <UserRow connectProjectId={projectId} user={users.contents[item.userId]} />
                </div>
                <p className="pt-1 pb-1 small">{item.type + ": " + item.description}</p>
              </li>
            ) : (
              ""
            )
          )}
        </ul>
      </div>
    </div>
  )
}
const getDefaultFilter = (taskId: any) => ({
  taskId: taskId
})

export default TasksActivities
