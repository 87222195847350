import React from "react"
import { useTranslation } from "react-i18next"
import Environment from "../../enviroments"
import CheckBoxGroup from "../CheckBoxGroup"
import Modal from "../Modal"

interface DeleteModalProps {
  closeModal: () => void
  deleteProject: (purge: boolean) => void
}

const DeleteModal = (props: DeleteModalProps) => {
  const { closeModal, deleteProject } = props

  const { t } = useTranslation("config")

  const [checkedSet, setCheckedSet] = React.useState({ purge: false } as { [key: string]: boolean })
  const toggleChecked = (key: string) =>
    setCheckedSet((s) => ({
      ...s,
      [key]: s[key] ? false : true
    }))

  console.log("purge is set: ", checkedSet["purge"] && true)

  const onClickDisable = () => {
    const purge = checkedSet["purge"] || false
    // // TODO: Replace with actual delete
    // console.log("Disabling quadri with purge = ", purge)
    deleteProject(purge)
    closeModal()
  }

  const checkBoxes = [{ checkKey: "purge", description: t("deleteModal.purgeQuadriData") }]

  return (
    <Modal
      closeModal={props.closeModal}
      heading={t("deleteModal.disableQuadri")}
      submitButtonText={t("deleteModal.disable")}
      submitButtonClasses="warning"
      onSubmitButtonClicked={onClickDisable}
    >
      {t("deleteModal.deletePrompt")}
      {Environment.allowPurge && (
        <div className="disabled">
          <div className="my-2">
            <CheckBoxGroup {...{ checkedSet, toggleChecked, checkBoxes, checkGroupKey: "purge-check-group" }} />
          </div>
          <b>{t("deleteModal.warning")}:</b> {t("deleteModal.purgeWarning")}
        </div>
      )}
    </Modal>
  )
}

export default DeleteModal
