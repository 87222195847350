import { convertFromDate, convertToDate } from "./dateTools"

export const getOnFilterChanged =
  (setFilter: (filter: any) => void, typeKey: string = "types") =>
  (event: Event, which: string) => {
    event.preventDefault()

    switch (which) {
      case "type-picker": {
        const eventData = getEventData(event)
        setFilter((f: any) => ({ ...f, [typeKey]: eventData.map((type: any) => type.value) }))
        break
      }
      case "user-picker": {
        const eventUserData = getEventUserData(event)
        setFilter((f: any) => ({
          ...f,
          tidUserIds: eventUserData?.map((u: any) => u.tiduuid) || [],
          connectUserIds: eventUserData?.map((u: any) => u.id) || []
        }))
        break
      }
      case "date-range-picker": {
        const eventData = getEventData(event)
        setFilter((f: any) => ({
          ...f,
          startDate: convertFromDate(eventData.from),
          endDate: convertToDate(eventData.to)
        }))
        break
      }
      default:
        console.warn("received invalid filter event type: ", which)
    }
  }

const getEventData = (event: any) => (event as any).detail.data

const getEventUserData = (event: any) => (event as any).detail.userData
