// TODO: Make this selected by the environment and not parsing the URL

type EnvironmentType = "LOCAL" | "DEV" | "DEV_AZURE" | "STAGING" | "PROD_EU" | "PROD_US"

interface EnvironmentVariables {
  urlMatch?: string
  quadriConfigurationUiUrl: string
  quadriGraphQlUrl: string
  signalrBaseUrl?: string
  showVersionHeader: boolean
  envName?: string
  allowPurge: boolean
  /** Not used when integrated with Trimble Connect */
  clientId: string
  /** Not used when integrated with Trimble Connect */
  authority: string
  /** Not used when integrated with Trimble Connect */
  scope?: string
  /** Not used when integrated with Trimble Connect */
  response_type?: string
}

type EnviromentVariablesMap = {
  [key in EnvironmentType]: EnvironmentVariables
}

const Environments: EnviromentVariablesMap = {
  LOCAL: {
    clientId: "ce7256f6-90e4-4ad2-9775-05199df3d48d",
    quadriConfigurationUiUrl: "https://quadricommunitywebqa.z6.web.core.windows.net",
    quadriGraphQlUrl: "https://graphql-az.quadri.cc/graphql",
    // quadriGraphQlUrl: "http://localhost/graphql", // REJECT PR IF THIS IS NOT COMMENTED!
    signalrBaseUrl: "https://ddm-realtime-service-az.quadri.cc",
    authority: "https://id.trimble.com",
    urlMatch: "localhost",
    scope: "openid Shared-Model-LOCAL",
    response_type: "code",
    showVersionHeader: false,
    envName: "Local (Az)",
    allowPurge: true
  },
  DEV: {
    clientId: "ded543b7-5007-4d90-b7f0-24d14a6912b7",
    quadriConfigurationUiUrl: "https://quadricommunitywebqa.z6.web.core.windows.net",
    quadriGraphQlUrl: "https://graphql.quadri.cc/graphql",
    signalrBaseUrl: "https://ddm-realtime-service.quadri.cc",
    authority: "https://id.trimble.com",
    urlMatch: "shared-model.quadri.cc",
    scope: "openid Shared-Model-DEV",
    response_type: "code",
    showVersionHeader: true,
    envName: "Dev (AWS)",
    allowPurge: true
  },
  DEV_AZURE: {
    clientId: "ded543b7-5007-4d90-b7f0-24d14a6912b7", // This is not used/can be the same...
    quadriConfigurationUiUrl: "https://quadricommunitywebqa.z6.web.core.windows.net",
    quadriGraphQlUrl: "https://graphql-az.quadri.cc/graphql",
    signalrBaseUrl: "https://ddm-realtime-service-az.quadri.cc",
    authority: "https://id.trimble.com",
    urlMatch: "model-server-az.quadri.cc",
    scope: "openid Shared-Model-DEV", // This is not used...
    response_type: "code", // Auth info is not used when app is inside Connect
    showVersionHeader: true,
    envName: "Dev (Az)",
    allowPurge: true
  },
  STAGING: {
    clientId: "ded543b7-5007-4d90-b7f0-24d14a6912b7",
    quadriConfigurationUiUrl: "https://quadricommunitywebstg.z6.web.core.windows.net",
    quadriGraphQlUrl: "https://graphql.az.quadri-stg.trimble.com/graphql",
    signalrBaseUrl: "https://realtime.az.quadri-stg.trimble.com/",
    authority: "https://id.trimble.com",
    urlMatch: "quadri-stg.trimble.com",
    scope: "openid Shared-Model-DEV",
    response_type: "code",
    showVersionHeader: true,
    envName: "Stg",
    allowPurge: false
  },
  PROD_EU: {
    clientId: "ded543b7-5007-4d90-b7f0-24d14a6912b7",
    quadriConfigurationUiUrl: "https://configuration.quadri.trimble.com",
    quadriGraphQlUrl: "https://graphql.az.quadri.trimble.com/graphql",
    signalrBaseUrl: "https://realtime.az.quadri.trimble.com/",
    authority: "https://id.trimble.com",
    urlMatch: "quadri.trimble.com",
    scope: "openid Shared-Model-DEV",
    response_type: "code",
    showVersionHeader: false,
    allowPurge: false
  },
  PROD_US: {
    clientId: "ded543b7-5007-4d90-b7f0-24d14a6912b7",
    quadriConfigurationUiUrl: "https://configuration.quadri.trimble.com",
    quadriGraphQlUrl: "https://graphql.az.quadri-us.trimble.com/graphql",
    signalrBaseUrl: "https://realtime.az.quadri-us.trimble.com/",
    authority: "https://id.trimble.com",
    urlMatch: "quadri-us.trimble.com",
    scope: "openid Shared-Model-DEV",
    response_type: "code",
    showVersionHeader: false,
    allowPurge: false
  }
}

const getRegion = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const region = urlParams.get("region")
  console.log("region = ", region)
  return region
}

const getEnvironment = (specifyEnvironment?: EnvironmentType): EnvironmentVariables => {
  if (specifyEnvironment) {
    return Environments[specifyEnvironment]
  } else {
    // If there is a region defined in the query parameters, use the prod version of that region
    const region = getRegion()
    if (region) {
      console.log("Using region `", region, "` to specify environment")
      switch (region) {
        case "eu":
          return Environments.PROD_EU
        case "us":
          return Environments.PROD_US
        default:
          console.error("Region has invalid value. It should be `eu` or `us`")
          return Environments.PROD_EU
      }
    } else {
      console.log("No region defined in query parameters, using url to match the environment")
      // If no region is specified, then find the environment based on URL
      const environmentMatch = Object.values(Environments).find(
        (env) => env.urlMatch && window.location.origin.includes(env.urlMatch)
      )

      return environmentMatch || Environments.DEV
    }
  }
}

// If environment needs to be overridden and not detected, do it here
const Environment = getEnvironment()
// const Environment = getEnvironment("DEV");

console.log("environment = ", Environment)

export default Environment
