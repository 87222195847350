import { useQuery } from "@apollo/client"
import React from "react"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"
import { useTranslation } from "react-i18next"
import { COMPATIBLE_FEATURE_CATALOGS, FEATURE_CATALOGS, FEATURE_CATALOG_STANDARDS } from "../../api/QuadriApi"
import Environment from "../../enviroments"
import { CoordinateSystemUnit, FeatureCatalogInfo, FeatureCatalogStandard, ProjectStatus } from "../../types"
import { exceptionFromApolloError } from "../../util/apolloError"
import { versionIsSameOrHigher } from "../../util/fcVersionCompare"
import { Card } from "../Card"
import Oops from "../Oops/Oops"
import { Select } from "../Select"
import { ConfigStatus } from "./ConfigMain"

interface QuadriProjectSettingsProps {
  existingFcId: string | null
  fcStandard?: string
  setFcStandard: (std: string) => void
  featureCatalogId?: string
  setFeatureCatalogId: (id?: string) => void
  coordinateSystemUnits: CoordinateSystemUnit
  setCoordinateSystemUnits: (csu: CoordinateSystemUnit) => void
  disableEdit?: boolean
  configStatus: ConfigStatus // TODO: Is this used???
  projectStatus?: ProjectStatus
  region?: string
}

// The card for selecting feature catalogs. The top level component is responsible
// for error handling. Errors will end up inside the card and not crashing the whole
// page.
const QuadriProjectSettings = (props: QuadriProjectSettingsProps) => {
  const { t } = useTranslation("config")
  return (
    <div className="row">
      <div className="col-12">
        <Card header={t("quadriProjectSettings.extensionSettings")}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <QuadriProjectSettingsInner {...props} />
          </ErrorBoundary>
        </Card>
      </div>
    </div>
  )
}

const QuadriProjectSettingsInner = (props: QuadriProjectSettingsProps) => {
  const { fcStandard, setFcStandard } = props
  const { featureCatalogId, setFeatureCatalogId } = props
  const { coordinateSystemUnits, setCoordinateSystemUnits } = props
  const { disableEdit, projectStatus, existingFcId } = props
  const { t } = useTranslation("config")

  // If the project is active, then only get compatible feature catalogs
  const getOnlyCompatible = projectStatus === "Active"

  const tags = getFcTags(props.region)
  const standardsQuery = useQuery(FEATURE_CATALOG_STANDARDS, { variables: { tags } })

  // Use "skip" in useQuery to either get all catalogs or only compatible
  const allCatalogsQuery = useQuery(FEATURE_CATALOGS, {
    variables: { tags },
    skip: getOnlyCompatible
  })
  const compatibleQuery = useQuery(COMPATIBLE_FEATURE_CATALOGS, {
    variables: { id: existingFcId, tags },
    skip: !getOnlyCompatible
  })

  // Choose the applicable catalogs query going forward
  const catalogsQuery = getOnlyCompatible ? compatibleQuery : allCatalogsQuery

  // Get list of feature catalogs to display
  const availableCatalogs = getOnlyCompatible
    ? compatibleQuery.data?.compatibleFeatureCatalogs
    : allCatalogsQuery.data?.featureCatalogs

  // Effect to update the selected standard
  React.useEffect(() => {
    if (featureCatalogId && availableCatalogs && availableCatalogs.length > 0) {
      const catalog = availableCatalogs.find((c: any) => c.id === featureCatalogId)
      if (catalog && catalog.standard) {
        setFcStandard(catalog.standard)
      }
    }
  }, [availableCatalogs, featureCatalogId, setFcStandard])

  // Propagate GraphQL errors
  if (standardsQuery.error) {
    throw exceptionFromApolloError(standardsQuery.error)
  }

  if (catalogsQuery.error) {
    throw exceptionFromApolloError(catalogsQuery.error)
  }

  const loading = standardsQuery.loading || catalogsQuery.loading

  const standards = standardsQuery.data ? standardsQuery.data.featureCatalogStandards : []
  const versions = availableCatalogs || []

  const standardData = fcStandard ? standards.find((s: any) => s.id === fcStandard) : undefined

  return (
    <>
      <div className="col-12 col-lg-4">
        <p className="text-meta display-linebreak">
          {t("quadriProjectSettings.description")}&nbsp;
          <a href={Environment.quadriConfigurationUiUrl} target="_blank" rel="noreferrer">
            {t("quadriProjectSettings.quadriConfiguration")}
          </a>
          .
        </p>
      </div>

      <div className="col-12 col-lg-8">
        <Select
          label={t("quadriProjectSettings.featureCatalog")}
          value={fcStandard}
          onChange={(e: any) => {
            setFcStandard(e.target.value)
            // updateQuadriProject({ featureCatalogId: undefined })
            setFeatureCatalogId(undefined)
          }}
          options={standards.map((e: FeatureCatalogStandard) => ({ value: e.id, label: e.name })).reverse()}
          disabled={disableEdit}
          required={true}
          loading={loading}
        />

        <Select
          label={t("quadriProjectSettings.featureCatalogVersion")}
          value={featureCatalogId}
          onChange={(e: any) => setFeatureCatalogId(e.target.value)}
          options={versions
            .filter((e: FeatureCatalogInfo) => e.standard === fcStandard)
            .sort(fcSort)
            .map((j: FeatureCatalogInfo) => ({
              value: j.id,
              // label: `${j.version} - ${j.date}`
              label: fcVersionString(standardData?.name || "", j.version, formatDate(j.date), j.id)
            }))
            .reverse()}
          disabled={disableEdit || !fcStandard}
          required={true}
          loading={loading}
        />

        <Select
          label={t("quadriProjectSettings.crsUnits")}
          value={coordinateSystemUnits}
          onChange={(e: any) => setCoordinateSystemUnits(e.target.value)}
          options={availableCoordinateSystemUnits.map((j: { translationKey: string; value: CoordinateSystemUnit }) => ({
            value: j.value,
            label: t(j.translationKey)
          }))}
          disabled={disableEdit}
          required={true}
          loading={loading}
        />
      </div>
    </>
  )
}

// Custom error fallback to make it fit in a card with proper formatting
const ErrorFallback = (props: FallbackProps) => (
  <div className="col-12 col-lg-4 align-center">
    <Oops body={props.error.message} />
  </div>
)

const dateStyleOpts: any = {
  year: "numeric",
  month: "2-digit",
  day: "numeric"
}
const formatDate = (date: string) => new Date(date).toLocaleString(undefined, dateStyleOpts)

const fcSort = (a: FeatureCatalogInfo, b: FeatureCatalogInfo) => (versionIsSameOrHigher(a.version, b.version) ? 1 : -1)

const fcVersionString = (stdName: string, version: string, dateString: string, pid: string | number) =>
  `${stdName} ${version} - ${dateString} [${pid}]`

const availableCoordinateSystemUnits: { translationKey: string; value: CoordinateSystemUnit }[] = [
  {
    translationKey: "quadriProjectSettings.meter",
    value: "Meter"
  },
  {
    translationKey: "quadriProjectSettings.internationalFoot",
    value: "InternationalFoot"
  },
  {
    translationKey: "quadriProjectSettings.usSurveyFoot",
    value: "UsSurveyFoot"
  }
]

const getFcTags = (region?: string) => (region ? `region/${region}` : undefined)

export default QuadriProjectSettings
