// import { translate } from "@fulcrum/lib";
import get from "lodash.get"
import React, { ComponentType } from "react"
import { AutoSizer, Index, InfiniteLoader, List, WindowScroller } from "react-virtualized"
import CustomTooltip from "../CustomTooltip"
import Empty from "../Empty"
import Spinner from "../Spinner"
import CustomColumnHeader from "./CustomColumnHeader"
import TableRow from "./TableRow"
import "./_commontable.css"

/* CommonTable default config properties*/
export interface ICTDefault {
  /* Row height either can be fixed number or Map set with <row_index, height>. Map set can also have a default fixed row height using default as a key*/
  rowHeight: number | Map<number | string, number>
  /* Pre-scan rows count from the scroll position*/
  preScanRows: number
  /* Pre-fetch rows count from the scroll position for layzloading*/
  preFetchRows: number
  /* Table minimum height*/
  minHeight: number
  /* CSS class for the table div*/
  tableClass: string
  /* Default table height for UT*/
  defaultHeight: number
  /* Default table width for UT*/
  defaultWidth: number
}

/* CommonTable default config values*/
const defaultConfig: ICTDefault = {
  rowHeight: 64,
  preScanRows: 5,
  preFetchRows: 5, // Default value - 15 not working for higher resolution.
  minHeight: 385,
  tableClass: "List",
  defaultHeight: 500,
  defaultWidth: 500
}

/* wrapperTag properties*/
export interface WrapperTag {
  tag: "div" | "span" //You can add the possible wrapper tag name here.
  class?: string
  props?: any
}

export interface ICustomAttributeColumn {
  /* flag to indicate that the column is hidden - Used only for column swap functionality */
  isHidden?: boolean
  /* flag to indicate that the column can be swapped - Used only for column swap functionality */
  canSwap?: boolean
  /* flag to indicate that the column is custom attribute column - Used only for column swap functionality */
  isCustomAttr?: boolean
}

/* CommonTable column properties*/
export interface IColumn extends ICustomAttributeColumn {
  /* Column heading (th)*/
  label: string
  /* Table header (th) can be a react component, if you want pack more inside th*/
  labelComponent?: React.ReactNode
  /* Loadash string from the row object*/
  field: string
  /* Sortable either true or false*/
  isSort: boolean
  /* Sort label for highlighting the tabel head*/
  sortLable?: string
  /* Column(th) css class*/
  colClass?: string
  /* Table cell(td) css class*/
  fieldClass?: string
  /* Table cell can be a react component, if you want pack more inside a cell*/
  component?: React.ReactNode
  /* Table cell component default props (kind of pass-on prop)*/
  componentProps?: any
  /* Enable/Disable double for this column */
  skipDbClick?: boolean
  /* if you need event handling with extra props then consider using component prop*/
  wrapperTag?: WrapperTag
  /* skip tooltip flag. it will only work for non-component columns */
  noTooltip?: boolean
}

/* CommonTable sort object*/
export interface ISortOptions {
  /* field identifier*/
  col: string
  /* sort type either can be 0 (ascending) or 1 (descending)*/
  type: number
}

export interface ICTComponentEvent {
  rowIndex: number
  event: React.RefObject<HTMLDivElement> | null
}

/* Table cell component props properties */
export interface ICTComponent<IRow = any> {
  /* Row object */
  row: IRow
  /* Row index */
  index: number
  /* is current row is selected*/
  isSelected: boolean
  /* onClick event handler for the component*/
  handleClick: (action: string, e?: React.MouseEvent<HTMLElement>, cmRef?: React.RefObject<HTMLDivElement>) => void
  /* selected rows index */
  selectedRows: number[]
  /* props for detect on outside click*/
  selectedComponent?: ICTComponentEvent
  /* Table cell component default props (kind of pass-on prop)*/
  componentProps?: any
}

export interface ICTCustomColumnProps {
  /* flag to display the column swap dropdown */
  hasCustomColumns?: boolean
  /* Array of column objects including hidden columns which can swapped */
  allColumns?: IColumn[]
  /* Callback for column change */
  onColumnChange?: (newColumn: IColumn, oldColumn: IColumn) => void
}

/* Table heading (th) component properties */
export interface ICTHComponent extends ICTCustomColumnProps {
  /* TH index from columns array */
  thIndex: number
  /* The column of the th */
  col: IColumn
  /* onThClick callback, onHeadingClick prop required for this action*/
  onClick?: (thIndex: number, e?: React.MouseEvent<HTMLElement>) => void
}

/* CommonTable props properties*/
export interface ICTProps<IRow> extends ICTCustomColumnProps {
  /* Array of column objects */
  columns: IColumn[]
  /* Has lazyloading, (needs to be false after the lazyloding fully completed) */
  lazyLoad: boolean
  /* Callback for layzloading to load more rows, will trigger when the scroll position reaches bottom of the table */
  loadMore: () => Promise<null>
  /* Array of objects need to render in the table*/
  list: IRow[]
  /* flag to display the loading spinner, if it's ture then will override the entire table with a spinner*/
  isLoading: boolean
  /* Error message when table has data to load or network failer*/
  errMessage?: {
    /* Title of the error message*/
    title: string
    /* Actual message*/
    message: string
    /* Full icon class for the error*/
    icon: string
  }
  /* Callback perform when the column header is clicked (if isSort is true) */
  doSort?: (col: string) => void
  /* Sort config */
  sortOptions?: ISortOptions
  /*Callback perform when a table heading (th) is clicked*/
  onHeadingClick?: (thIndex: number, e?: React.MouseEvent<HTMLElement>) => void
  /* Callback perform when a row is clicked but it requires next prop selectedRows as mandatory*/
  onRowSelect?: (
    index: number,
    e?: React.MouseEvent<HTMLElement> | KeyboardEvent,
    action?: string,
    componentRef?: React.RefObject<HTMLDivElement>
  ) => void
  /* Callback for row double click handler*/
  onRowDbClick?: (index: number) => void
  /* Used to define custom row props */
  getRowProps?: (index: number) => void
  /* Array of rows index which are selected*/
  selectedRows?: number[]
  /* React ref for scrollbar config (based on this element only the scroll will work. default is entire window)*/
  scrollElement?: React.RefObject<HTMLDivElement>
  /* Total count of the rows (from content-range header)*/
  totCount?: number
  /* Flag for hide the table header (th)*/
  hideCol?: boolean
  /* Render props for the responsive header (th)*/
  responsiveHeader?: () => React.ReactNode
  /* Table row css class (tr)*/
  rowClass?: string
  /* For outside click listen of the table cell coponent*/
  selectedComponent?: ICTComponentEvent
  /* Override the default config values of the table such as pre-scan, pre-fetch etc..*/
  defaultConfig?: Partial<ICTDefault>
  /* Static table width for the UT*/
  staticWidth?: number
  /* displaying table header as static while sort*/
  staticHeader?: boolean
}

/* Error message type */
export interface IErrorMessage {
  title: string
  message: string
  icon: string
}

interface IState {
  rowFrom: number
  rowAlpha: number
  rowTo: number
}

class CommonTable<IRow> extends React.Component<ICTProps<IRow>, IState> {
  public infiniteLoaderRef: React.RefObject<InfiniteLoader>
  public scrollDiv: React.RefObject<HTMLDivElement>
  private default: ICTDefault
  private delay: number
  private timer: number
  private prevent: boolean

  public constructor(props: ICTProps<IRow>) {
    super(props)
    this.infiniteLoaderRef = React.createRef()
    this.scrollDiv = React.createRef()
    this.state = {
      rowFrom: 0,
      rowAlpha: -1,
      rowTo: 0
    }
    this.default = { ...defaultConfig, ...props.defaultConfig }
    this.timer = 0
    this.delay = 300
    this.prevent = false
  }

  public componentDidMount() {
    window.addEventListener("keydown", (e: KeyboardEvent) => this.handleKeyPress(e))
  }

  public componentWillUnmount() {
    window.removeEventListener("keydown", (e: KeyboardEvent) => this.handleKeyPress(e))
  }

  private selectUp = (index: number, e: KeyboardEvent): void => {
    const { list }: ICTProps<IRow> = this.props
    const { rowAlpha }: IState = this.state
    if (rowAlpha !== list.length - 1) {
      index += rowAlpha > index ? 0 : 1
    }
    this.setState({ rowFrom: index })
    this.onRowSelect(index, e)
  }

  private selectDown = (index: number, e: KeyboardEvent): void => {
    const { rowAlpha }: IState = this.state
    if (rowAlpha !== 0) {
      index -= rowAlpha < index ? 0 : 1
    }
    this.setState({ rowTo: index })
    this.onRowSelect(index, e)
  }

  private noSelectionBehavior = (e: KeyboardEvent): boolean => {
    const { selectedRows }: ICTProps<IRow> = this.props
    const { rowAlpha } = this.state
    return !selectedRows || !this.props.onRowSelect || rowAlpha === -1 || selectedRows.length === 0 || !e.shiftKey
  }

  private handleKeyPress = (e: KeyboardEvent): void | boolean => {
    const { selectedRows = [], list }: ICTProps<IRow> = this.props
    const { rowAlpha } = this.state
    if (document.activeElement !== document.body) return
    if (this.noSelectionBehavior(e)) {
      return false
    }
    const selectedCount = selectedRows.length
    const lastSelectedIndex = selectedRows[selectedCount - 1]
    const index = lastSelectedIndex

    if (e.keyCode === 40 && rowAlpha <= list.length - 1) {
      this.selectUp(index, e)
    } else if (e.keyCode === 38 && rowAlpha >= 0) {
      this.selectDown(index, e)
    }
  }

  private getCellValue = (col: IColumn, row: IRow) => {
    const { field, noTooltip = false } = col
    const value =
      field.indexOf(",") !== -1
        ? field.split(",").reduce((a: string, c: string) => `${a} ${get(row, c, "")}`, "")
        : get(row, field, "")

    return noTooltip ? (
      <span className="text-ellipsis">{value}</span>
    ) : (
      <CustomTooltip title={value} position="left">
        <span className="text-ellipsis">{value}</span>
      </CustomTooltip>
    )
  }

  public getThComponent = (col: IColumn, index: number): React.ReactNode => {
    const Component: ComponentType<ICTHComponent> = col.labelComponent as ComponentType<ICTHComponent>
    return (
      <Component
        allColumns={this.props.allColumns}
        onColumnChange={this.props.onColumnChange}
        col={col}
        thIndex={index}
        onClick={this.props.onHeadingClick}
      />
    )
  }

  private getDefaultTh = (col: IColumn): React.ReactNode => {
    // const colLabel = col.label ? translate(col.label) : "";
    const colLabel = col.label
    return col.canSwap && this.props.hasCustomColumns ? (
      <CustomColumnHeader
        currentColumn={col}
        columns={this.props.allColumns}
        sortOptions={this.props.sortOptions}
        onColumnChange={this.props.onColumnChange}
      />
    ) : (
      <CustomTooltip title={colLabel}>
        <span className="text-ellipsis">{colLabel}</span>
      </CustomTooltip>
    )
  }

  public getComponent = (col: IColumn, row: IRow, rowIndex: number): React.ReactNode => {
    const Component: ComponentType<ICTComponent<IRow>> = col.component as ComponentType<ICTComponent<IRow>>
    return (
      <Component
        row={row}
        index={rowIndex}
        isSelected={!!this.props.selectedRows ? this.props.selectedRows.includes(rowIndex) : false}
        selectedRows={this.props.selectedRows ? this.props.selectedRows : []}
        handleClick={(action: string, e?: React.MouseEvent<HTMLElement>, cmRef?: React.RefObject<HTMLDivElement>) => {
          if (!!action) {
            if (this.props.onRowSelect) {
              e && this.updateSelectionRows(e, rowIndex)
              this.props.onRowSelect(rowIndex, e, action, cmRef)
            }
          }
        }}
        selectedComponent={this.props.selectedComponent}
        componentProps={col.componentProps}
      />
    )
  }

  private getSortIcon = (type: number): React.ReactNode => {
    return type === 0 ? (
      <i className="icon-font i16 tc-icon-arrow-line-up" />
    ) : (
      <i className="icon-font i16 tc-icon-arrow-line-down" />
    )
  }

  private getSortIcons = (col: IColumn): React.ReactNode | void => {
    const { sortOptions, list } = this.props
    const sortable = list.length === 1 ? false : col.isSort
    if (!!sortOptions && sortable) {
      const field = col.sortLable ? col.sortLable : col.field
      return (
        <button className="button icon-small icon-circle tertiary">
          {sortOptions.col === field && this.getSortIcon(sortOptions.type)}
        </button>
      )
    }
  }

  private getColumn = (col: IColumn, key: number): React.ReactNode => {
    const { sortOptions, list } = this.props
    const sortField = col.sortLable ? col.sortLable : col.field
    const sortable = list.length === 1 ? false : col.isSort
    return (
      <div
        key={`th-${key}`}
        className={get(col, "colClass", "")}
        onClick={() => (!!this.props.doSort && sortable ? this.props.doSort(col.field) : null)}
        style={col.canSwap && this.props.hasCustomColumns ? { overflow: "visible" } : {}}
      >
        <div
          className={
            sortable
              ? `sort-head sort-toggle ${sortOptions && sortOptions.col === sortField ? "highlight" : ""}`
              : "sort-head no-sort"
          }
          style={col.canSwap && this.props.hasCustomColumns ? { overflow: "visible" } : {}}
        >
          {col.labelComponent ? this.getThComponent(col, key) : this.getDefaultTh(col)}
          {this.getSortIcons(col)}
        </div>
      </div>
    )
  }

  private getColumns = (): React.ReactNode => {
    return (
      <div className="sticky-header">
        {this.props.responsiveHeader && this.props.responsiveHeader()}
        <div className="row table-list-item table-list-head">
          {this.props.columns.map((col, i) => this.getColumn(col, i))}
        </div>
      </div>
    )
  }

  private onRowSelect = (index: number, e: React.MouseEvent<HTMLElement> | KeyboardEvent): void => {
    this.props.onRowSelect && this.props.onRowSelect(index, e)
  }

  private setRowLimit = (index: number): void => {
    const { rowAlpha } = this.state
    if (rowAlpha > 0) {
      if (index > rowAlpha) {
        //down
        this.setState({ rowTo: index })
      } else if (index < rowAlpha) {
        //up
        this.setState({ rowFrom: index })
      }
    }
  }

  public updateSelectionRows = (e: React.MouseEvent<HTMLElement>, index: number): void => {
    const { selectedRows = [] } = this.props
    if (!e.shiftKey) {
      if (selectedRows.indexOf(index) === -1) {
        this.setState({ rowAlpha: index, rowFrom: 0, rowTo: 0 })
      } else if (selectedRows.length > 1) {
        const lastSelectedIndex = selectedRows[selectedRows.length - 2]
        this.setState({ rowAlpha: lastSelectedIndex, rowFrom: 0, rowTo: 0 })
      }
    } else {
      this.setRowLimit(index)
    }
  }

  public onMouseRowSelect = (event: React.MouseEvent<HTMLElement>, index: number): void => {
    const e: React.MouseEvent<HTMLElement> = { ...event }
    this.timer = window.setTimeout(() => {
      if (this.prevent) return
      const { list, lazyLoad, selectedRows } = this.props
      if (!selectedRows) {
        return
      }
      this.updateSelectionRows(e, index)
      this.onRowSelect(index === list.length - 1 && lazyLoad ? -1 : index, e)
    }, this.delay)
  }

  public onRowDoubleClick = (index: number) => {
    window.clearTimeout(this.timer)
    this.prevent = true
    this.props.onRowDbClick && this.props.onRowDbClick(index)
    setTimeout(() => {
      this.prevent = false
    }, this.delay)
  }

  public renderRow = ({ index, style }: any): React.ReactNode => (
    <TableRow<IRow>
      key={`row-${index}`}
      index={index}
      style={style}
      columns={this.props.columns}
      list={this.props.list}
      selectedRows={this.props.selectedRows || []}
      lazyLoad={this.props.lazyLoad}
      rowClass={this.props.rowClass || ""}
      onRowSelect={this.onMouseRowSelect}
      onRowDbClick={this.onRowDoubleClick}
      getComponent={this.getComponent}
      getCellValue={this.getCellValue}
      getRowProps={this.props.getRowProps}
    />
  )

  public renderListWithResize = (listProps: any): React.ReactNode => {
    return !this.props.staticWidth ? (
      <AutoSizer disableHeight defaultWidth={this.default.defaultWidth} defaultHeight={this.default.defaultHeight}>
        {({ width }) => <List {...{ ...listProps, width }} />}
      </AutoSizer>
    ) : (
      <List {...{ ...listProps, width: this.props.staticWidth }} />
    )
  }

  public getRowHeight = ({ index }: Index) => {
    if (this.default.rowHeight instanceof Map) {
      const defaultRowHeight = this.default.rowHeight.get("default")
      let height = this.default.rowHeight.get(index)
      height = height ? height : defaultRowHeight
      return height ? height : defaultConfig.rowHeight
    } else {
      return defaultConfig.rowHeight
    }
  }

  public renderTable = () => {
    const { list, isLoading, errMessage, staticHeader = false, lazyLoad } = this.props
    const listProps = {
      className: this.default.tableClass,
      rowHeight: this.default.rowHeight instanceof Map ? this.getRowHeight : this.default.rowHeight,
      rowRenderer: ({ index, style }: any) => this.renderRow({ index, style }),
      overscanCount: this.default.preScanRows,
      rowCount: list.length
    }
    const showCol = !get(this.props, "hideCol", false)

    if (list.length === 0 && isLoading) {
      return <Spinner show={true} />
    } else if (list.length === 0) {
      return !!errMessage && !lazyLoad ? (
        <div className="mt-5">
          <Empty {...{ ...errMessage }} />
        </div>
      ) : null
    } else {
      return (
        <React.Fragment>
          {!staticHeader && showCol && this.getColumns()}
          <InfiniteLoader
            isRowLoaded={({ index }: { index: number }) => !!list[index]}
            rowCount={!!this.props.totCount ? this.props.totCount : 10000}
            loadMoreRows={this.props.loadMore || null}
            threshold={this.default.preFetchRows}
            ref={this.infiniteLoaderRef}
          >
            {({ onRowsRendered, registerChild }) => (
              <WindowScroller
                scrollElement={
                  this.props.staticWidth
                    ? window
                    : !!this.props.scrollElement && this.props.scrollElement.current
                    ? (this.props.scrollElement.current as Element)
                    : this.scrollDiv.current
                    ? (this.scrollDiv.current as Element)
                    : window
                }
              >
                {({ height, isScrolling, onChildScroll, scrollTop }) =>
                  height
                    ? this.renderListWithResize({
                        ...listProps,
                        onRowsRendered: onRowsRendered,
                        ref: registerChild,
                        onScroll: onChildScroll,
                        height,
                        isScrolling,
                        scrollTop,
                        autoHeight: true
                      })
                    : null
                }
              </WindowScroller>
            )}
          </InfiniteLoader>
        </React.Fragment>
      )
    }
  }

  public render() {
    const showCol = !get(this.props, "hideCol", false)
    return (
      <React.Fragment>
        <div
          className="table-list"
          ref={!!this.props.scrollElement ? null : this.scrollDiv}
          style={{ minHeight: `${!!this.props.scrollElement ? `${this.default.minHeight}px` : "auto"}` }}
        >
          {this.props.staticHeader && showCol && this.getColumns()}
          {this.renderTable()}
        </div>
      </React.Fragment>
    )
  }
}

export default CommonTable
