// import { translate } from "@fulcrum/lib";
import React from "react"
import CustomTooltip from "../CustomTooltip"
import PortalWrapper from "../PortalWrapper"
import { ISortOptions } from "./CommonTable"
import { IRColumn } from "./CommonTableRsCol"

interface IProps {
  currentColumn: IRColumn
  columns?: IRColumn[]
  sortOptions?: ISortOptions
  onColumnChange?: (newCol: IRColumn, oldCol: IRColumn) => void
  colIndex?: number
  addonIcons?: (col: IRColumn, onlyIcon?: boolean) => React.ReactNode
}

interface IState {
  isOpen: boolean
}

export default class CustomColumnHeader extends React.PureComponent<IProps, IState> {
  private dropDownRef: React.RefObject<HTMLDivElement> = React.createRef()
  public state: IState = {
    isOpen: false
  }

  public componentDidMount() {
    document.addEventListener("click", this.handleDropDownOutsideClick, false)
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.handleDropDownOutsideClick, false)
  }

  private handleDropDownOutsideClick = (e: Event): void => {
    if (this.dropDownRef && this.dropDownRef.current && this.dropDownRef.current.contains(e.target as Node)) return
    this.setState({ isOpen: false })
  }

  private onColumnChange = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, col: IRColumn): void => {
    e.stopPropagation()
    if (this.props.onColumnChange && col.isHidden) {
      this.props.onColumnChange(col, this.props.currentColumn)
    }
  }

  private onToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    this.setState({ isOpen: !this.state.isOpen })
  }

  private getSortIcon = (type: number): React.ReactNode => {
    return type === 0 ? (
      <i className="icon-font i16 tc-icon-arrow-line-up sort-icon" />
    ) : (
      <i className="icon-font i16 tc-icon-arrow-line-down sort-icon" />
    )
  }

  public render() {
    const { colIndex = 0, currentColumn } = this.props
    const dummyId = colIndex ? `ct-dummy-header-${colIndex}` : `ct-dummy-header-${this.props.currentColumn.field}`

    if (!this.props.columns || !this.props.onColumnChange) return null
    // const colLabel = this.props.currentColumn.isCustomAttr ? this.props.currentColumn.label : translate(this.props.currentColumn.label);
    const colLabel = this.props.currentColumn.label
    return (
      <div
        className={`column-select-menu select-menu dropdown-pane-container m-width-0 ${
          this.state.isOpen ? "active" : ""
        }`}
        ref={this.dropDownRef}
      >
        <div className="name dropdownpane-link">
          <div className="text-ellipsis">
            <CustomTooltip title={colLabel}>
              <div>{colLabel}</div>
            </CustomTooltip>
          </div>
          <div onClick={this.onToggle}>
            <i className="icon-font tc-icon-arrow-drop-down"></i>
            {this.props.addonIcons && this.props.addonIcons(currentColumn, true)}
          </div>
        </div>

        <div className="dropdown-pane w-auto is-open vhidden" id={dummyId}></div>

        {this.state.isOpen && (
          <PortalWrapper cloneId={dummyId} scrollContainerId="droppable-container">
            <div id="column-picker-options" className={`dropdown-pane dropdown ${this.state.isOpen ? "is-open" : ""}`}>
              <ul className="dropdown-list">
                {this.props.columns
                  .filter((col) => col.canSwap)
                  .map((col) => (
                    <li
                      className={this.props.currentColumn.field === col.field ? "active" : ""}
                      key={col.field}
                      onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => this.onColumnChange(e, col)}
                    >
                      {this.props.sortOptions &&
                        this.props.sortOptions.col === col.field &&
                        this.getSortIcon(this.props.sortOptions.type)}
                      <label
                        className={`text-ellipsis pointer ${
                          !col.isHidden && this.props.currentColumn.field !== col.field ? "text-muted" : ""
                        }`}
                      >
                        {/* {col.isCustomAttr ? col.label : translate(col.label)} */}
                        {col.label}
                      </label>
                      {this.props.currentColumn.field === col.field && <i className="icon-font tc-icon-check"></i>}
                    </li>
                  ))}
              </ul>
            </div>
          </PortalWrapper>
        )}
        <div className="line"></div>
      </div>
    )
  }
}
