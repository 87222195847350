import React from "react"
import ReactDOM from "react-dom"

export interface IPortalProps {
  cloneId?: string
  scrollContainerId?: string
  leftExtra?: number
  topExtra?: number
}

class PortalWrapper extends React.Component<IPortalProps> {
  private el = document.createElement("div")
  private cloneTarget: HTMLElement | null
  private scrollContainer: HTMLElement | null
  private top: number

  public constructor(props: IPortalProps) {
    super(props)
    this.el.style.position = "relative"
    this.el.style.zIndex = "9999"
    this.cloneTarget = null
    this.scrollContainer = null
    this.top = 0
  }

  public updatePosition = () => {
    const { leftExtra = 0, topExtra = 0 } = this.props
    if (this.cloneTarget) {
      const position = this.cloneTarget.getBoundingClientRect()
      if (this.top === position.top) return
      this.top = position.top
      this.el.style.top = `${position.top + topExtra}px`
      this.el.style.left = `${position.left + leftExtra}px`
      this.el.style.width = `${position.width}px`
    }
  }

  public componentDidMount() {
    const { cloneId, scrollContainerId } = this.props
    if (cloneId) {
      this.cloneTarget = document.getElementById(cloneId)
      this.updatePosition()
      if (this.cloneTarget) {
        this.cloneTarget.style.visibility = "hidden"
      }

      if (scrollContainerId) {
        this.scrollContainer = document.getElementById(scrollContainerId)
        if (this.scrollContainer) {
          this.scrollContainer.onscroll = this.updatePosition
          this.scrollContainer.dataset.cloneId = this.props.cloneId
        }
      }
      window.onresize = this.updatePosition
    }
    document.body.prepend(this.el)
  }

  public componentWillUnmount() {
    if (this.scrollContainer && this.scrollContainer.dataset.cloneId === this.props.cloneId) {
      this.scrollContainer.onscroll = null
    }
    document.body.removeChild(this.el)
    window.onresize = null
  }

  public render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export default PortalWrapper
