import * as oidc from "oidc-client"
import Environment from "../enviroments"

const callbackUrl = `${window.location.origin}/tidauth`

export const TidSettings: oidc.UserManagerSettings = {
  authority: Environment.authority,
  client_id: Environment.clientId,
  redirect_uri: callbackUrl,
  response_type: Environment.response_type || "id_token token",
  scope: Environment.scope || "openid",

  // filterProtocolClaims: true,

  loadUserInfo: false,
  automaticSilentRenew: false, // manually do this to capture the new user for rest of the application

  // accessTokenExpiringNotificationTime: 60, // default
  // accessTokenExpiringNotificationTime: 3560, // To debug silent refresh

  // monitorSession: false,
  // includeIdTokenInSilentRenew: false,
  silent_redirect_uri: callbackUrl,

  userStore: new oidc.WebStorageStateStore({
    prefix: "oidc",
    store: window.localStorage
  })
}
