import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { QuadriApi } from "./api/QuadriApi"
import { ApolloProvider } from "@apollo/client"
import Layout from "./components/Layout"
import Routes, { RoutesType } from "./components/Routes"
import ConnectExtension from "./components/ConnectExtension"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"
import Oops from "./components/Oops/Oops"
import { SignalrClient } from "./components/SignalrClient"
import i18n from "./i18n"
import { I18nextProvider } from "react-i18next"
// import { MockedProvider } from "@apollo/client/testing"
// import { mocks } from "./__mocks__/ConfigMain.mock"

const App = () => {
  const quadriApi = new QuadriApi()

  const isNotIframe = window === window.parent

  return (
    <ApolloProvider client={(quadriApi as QuadriApi).getClient()}>
      <I18nextProvider i18n={i18n}>
        {/* <MockedProvider mocks={mocks} addTypename={false}> */}
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ConnectExtension>
            <SignalrClient>
              <Switch>
                {Routes.map((r: RoutesType, i: number) => (
                  <Route key={`app-route-${i}`} exact={r.exact} path={r.path}>
                    <Layout>{r.main}</Layout>
                  </Route>
                ))}
                {isNotIframe && (
                  <Route>
                    <Redirect to="/config" />
                  </Route>
                )}
              </Switch>
            </SignalrClient>
          </ConnectExtension>
        </ErrorBoundary>
        {/* </MockedProvider> */}
      </I18nextProvider>
    </ApolloProvider>
  )
}

// Custom error fallback to make it fit in a card with proper formatting
const ErrorFallback = (props: FallbackProps) => (
  <div className="col-12 align-center" style={{ height: "100%" }}>
    <Oops body={props.error.message} />
  </div>
)

export default App
