import React from "react"
import { useQuery } from "@apollo/client"
import { useHistory } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { connect, TCExtensionAPI } from "trimble-connect-project-workspace-api"
import { TidSettings } from "../auth/TidSettings"
import { TokenProvider } from "../auth/TokenProvider"
import { ITokenProvider } from "../types"
import locationToOrigin from "../util/locationToOrigin"
import { extensionApiState, projectIdState, projectOriginState, quadriProjectIdState } from "./StateSelectors"
import { PendingSpinner } from "./Pending"
import { GET_PROJECT_STATUS } from "../api/QuadriApi"
import Oops from "./Oops/Oops"
import { getMenu } from "../util/getMenu"
import { useTranslation } from "react-i18next"
import { exceptionFromApolloError } from "../util/apolloError"

interface ConnectExtensionProps {
  children: React.ReactElement
}

const ConnectExtension = (props: ConnectExtensionProps) => {
  const [extensionApi, setExtensionApi] = useRecoilState(extensionApiState)
  const [projectId, setProjectId] = useRecoilState(projectIdState)
  const setProjectOrigin = useSetRecoilState(projectOriginState)
  const { i18n } = useTranslation()

  const [reloading, setReloading] = React.useState(false)

  const isIframe = window !== window.parent

  const history = useHistory()

  const handleCommand = async (command: string) => {
    switch (command) {
      case "config":
        history.replace("/config")
        // Workround for missing notifications from Connect
        window.location.reload()
        setReloading(true)
        break
      case "top_menu":
        // history.replace("/config") // Should the top menu do anything?
        break
      case "tasks":
        history.replace("/tasks")
        break
      case "timeline":
        history.replace("/timeline")
        break
      case "worksets":
        history.replace("/worksets")
        break
      default:
        console.log("Got unknown command: ", command)
    }
  }

  const setAccessToken = (accessToken: string) => {
    localStorage.setItem("tc-access-token", accessToken)
  }

  React.useEffect(() => {
    const asyncStuff = async () => {
      // If in iframe, assume we are in trimble connect and can use extension framework
      if (isIframe) {
        const extApi = await connect(
          window.parent,
          (event, args) => {
            console.log("Extensions API event: ", event, " With args: ", args)
            switch (event) {
              case "extension.command":
                handleCommand(args.data)
                break
              case "extension.accessToken":
                console.log("Got access token refresh event!")
                setAccessToken(args.data)
                break
              case "extension.userSettingsChanged":
                //"User settings changed!"
                break
            }
          },
          30000
        )
        setExtensionApi(extApi)
        // TODO: Is this an error in the Typescript files, or in the documentation. See
        // https://components.connect.trimble.com/trimble-connect-project-workspace-api/docs/index.html
        // Confirmed that this is an error in the documentation
        const accessTokenPromise = extApi.extension.getPermission("accesstoken") as unknown as Promise<string>
        const projectPromise = extApi.project.getCurrentProject()
        const languagePromise = extApi.user.getUserSettings().then((s: any) => {
          console.log("user settings: ", s)
          return i18n.changeLanguage(getLangCodeIso639_1(s.language))
        })

        const [accessToken, project] = await Promise.all([accessTokenPromise, projectPromise, languagePromise])

        setAccessToken(accessToken)
        setProjectId(project.id)
        if (project.location) {
          setProjectOrigin(locationToOrigin(project.location))
        }
      } else {
        const tokenProvider = new TokenProvider(TidSettings)
        await tokenProvider.signIn(async (u: ITokenProvider) => {
          setAccessToken(u.accessToken)
        })
      }
    }

    asyncStuff()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Check if we have a token yet
  const token = localStorage.getItem("tc-access-token")

  return isIframe && token && extensionApi && projectId && !reloading ? ( // For running in Connect
    <ConnectExtensionInner extensionApi={extensionApi} content={props.children} />
  ) : (
    <PendingSpinner />
  )
}

interface ConnectExtensionInnerProps {
  extensionApi: TCExtensionAPI
  content: React.ReactElement
}

const ConnectExtensionInner = (props: ConnectExtensionInnerProps) => {
  const projectId = useRecoilValue(projectIdState)
  const { t } = useTranslation("common")

  if (!projectId) {
    throw new Error("Connect project ID need to be set before loading project status")
  }

  const [, setQuadriProjectId] = useRecoilState(quadriProjectIdState)
  const [loaded, setLoaded] = React.useState(false)

  const history = useHistory()

  React.useEffect(() => {
    history.listen((location: any, action: any) => {
      // The below way of setting the active menu item does not work, because not only
      // does it set the active menu item, but it also navigates to that submenu. We need
      // a way to just update the UI without affecting internal page navigation
      // // command, ie "tasks"
      // const command = location.pathname.split("/")[1]
      // props.extensionApi.ui.setActiveMenuItem(command)
    })
  }, [history])

  const { error, data } = useQuery(GET_PROJECT_STATUS, {
    variables: { id: projectId },
    onCompleted: (data) => {
      // This query sets the `quadri project ID` used in subsequent queries to GraphQL
      const response = data.tryGetConnectQuadriProject
      if (response.found) {
        setQuadriProjectId(response.project.quadriProjectId)
        console.log("Set Quadri Project Id: ", response.project.quadriProjectId)

        const status = response.project.projectStatus
        if (status === "Active" || status === "Updating") {
          props.extensionApi.ui.setMenu(getMenu(t))
        }
      }
      setLoaded(true) // This can only be set after quadri project id.
    }
  })

  if (error) {
    const errorMessage = exceptionFromApolloError(error).message
    console.error(errorMessage)
    props.extensionApi.extension.setStatusMessage(t("failedToFetchStatus"))
    return <Oops body={errorMessage} />
  } else if (!loaded) {
    props.extensionApi.extension.setStatusMessage(t("fetchingStatus"))
    return <PendingSpinner />
  } else {
    const isFound = data.tryGetConnectQuadriProject?.found
    const projectStatus = data.tryGetConnectQuadriProject?.project?.projectStatus

    if (isFound && projectStatus) {
      props.extensionApi.extension.setStatusMessage(t("projectStatus." + projectStatus))
    } else {
      props.extensionApi.extension.setStatusMessage(t("readyToEnable"))
    }
    return props.content
  }
}

const getLangCodeIso639_1 = (language: string) => language.split("-")[0]

export default ConnectExtension
