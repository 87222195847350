import { useQuery } from "@apollo/client"
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState, useRecoilState } from "recoil"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import DateRangePicker from "../FromConnect/DateRangePicker"
import UserPicker from "../FromConnect/UserPicker"
import CommonTable from "../FromConnect/CommonTable/CommonTable"
import {
  projectIdState,
  projectOriginState,
  quadriProjectIdState,
  sidebarTaskState,
  usersByTidUuidState,
  selectedTaskRow
} from "../StateSelectors"
import { GET_ROOTTASKS, GET_TASKS, HAS_ACCESS } from "../../api/QuadriApi"
import { PendingSpinner } from "../Pending"
import Oops from "../Oops/Oops"
import NoData from "../NoData/NoData"
import { formatDateAsConnect, formatDateOnlyAsConnect } from "../../util/dateTools"
import { iconBindings } from "../../assets/icons/quadriWindows/iconBindings"
import { userFullName } from "../UserRow"
import { getOnFilterChanged } from "../../util/filtering"
import TasksBreadCrumbs from "./TasksBreadCrumbs"
import { useHistory } from "react-router-dom"
import { TasksFilter } from "../../types"
import { useTranslation } from "react-i18next"

interface Task {
  icon: any
  taskName: string
  subTasks: string
  createdDate: string
  latestModderName: string
  latestDateMod: string
  latestModderId: string
}

enum SortDir {
  Ascending = 0,
  Descending = 1
}

function makeDatePersonEntry(theDate: string, thePerson: string, by: string, noData: string) {
  const formattedDate = formatDateOnlyAsConnect(theDate)
  const personEntry = thePerson ? by + thePerson : ""
  const returnValue = theDate === undefined ? noData : formattedDate + personEntry
  return returnValue
}
const TasksMain = () => {
  useEffect(() => {
    if (sidebarToggle && sidebarToggle.clickedTaskId > 0)
      setSelectedTask({
        taskName: sidebarToggle.taskName,
        taskId: sidebarToggle.taskId,
        subTasks: sidebarToggle.subTasks,
        clickedTaskId: -1,
        createdData: sidebarToggle.createdData,
        lastModifiedData: sidebarToggle.lastModifiedData
      })
  })

  const quadriProjectId = useRecoilValue(quadriProjectIdState)
  const projectOrigin = useRecoilValue(projectOriginState)
  const projectId = useRecoilValue(projectIdState)
  const users = useRecoilValueLoadable(usersByTidUuidState)
  const [taskRowSelected, setTaskRowSelected] = useRecoilState(selectedTaskRow)

  let loadingStatus = false // Keeps track of whether any of the http-requests in this render are loading
  let errorStatus = 0 // Keeps track of which, if any, of the http-requests in this render was the first that generated an error
  let apolloMessage = "" // Error message from apollo to go with errorStatus.

  const [sortConfig, setSortConfig] = useState({ col: "taskName", type: SortDir.Ascending })
  const [filter, setFilter] = useState({} as TasksFilter)
  const [prevTask, setPrevTask] = useState(null as any)
  const { t } = useTranslation("tasks")
  const cBy = t("runtimeTexts.by")
  const cNone = t("runtimeTexts.noData")
  const columnsSet = [
    {
      label: "",
      field: "icon",
      isSort: false,
      colClass: "iconColWidth",
      fieldClass: "iconMarginWidth"
    },
    {
      label: t("tasksGrid.name"),
      field: "taskName",
      isSort: true,
      colClass: "col col-sm-4 col-md-4 col-lg-4 taskHeadingMarginChop",
      fieldClass: "col col-sm-5 col-md-4 col-lg-4 taskNamePaddingChop text-ellipsis"
    },
    {
      label: t("tasksGrid.subCount"),
      field: "subTasksCount",
      isSort: false,
      colClass: "col col-sm-3 col-md-2 col-lg-1 list-data",
      fieldClass: "col col-sm-3 col-md-2 col-lg-1 list-data"
    },
    {
      label: t("tasksGrid.latestModder"),
      field: "latestModderName",
      isSort: false,
      colClass: "col col-sm-5 col-md-4 col-lg-3 list-data",
      fieldClass: "col col-sm-5 col-md-4 col-lg-3 list-data"
    },
    {
      label: t("tasksGrid.latestModTime"),
      field: "latestDateMod",
      isSort: false,
      colClass: "col col-sm-5 col-md-4 col-lg-3 list-data",
      fieldClass: "col col-sm-5 col-md-4 col-lg-3 list-data"
    }
  ]

  const errMessages = [
    t("runtimeTexts.accessCheckFailed"),
    t("runtimeTexts.rootTasksNotRead"),
    t("runtimeTexts.userCollNotRead")
  ]
  const onSort = (col: string) => {
    setSortConfig({
      ...sortConfig,
      type: sortConfig.type === SortDir.Ascending ? SortDir.Descending : SortDir.Ascending
    })
    invalidateDetailsPane()
  }

  const setSelectedTask = useSetRecoilState(sidebarTaskState)
  const sidebarToggle = useRecoilValue(sidebarTaskState)

  // ************************** HAS_ACCESS *******************************
  const { loading: accessLoading, error: accessError, data: accessData } = useQuery(HAS_ACCESS)

  if (accessLoading) {
    loadingStatus = true
  } else if (accessError || !(accessData && accessData?.hasAccess?.access)) {
    errorStatus = 1
    apolloMessage = accessError ? accessError.message : t("runtimeTexts.accessNotGranted")
  }

  const _onFilterChange = getOnFilterChanged(setFilter)
  const onFilterChange = (event: any, source: string) => {
    _onFilterChange(event, source)
  }

  const onSearchEnter = (e: any) => {
    e.preventDefault()
    setFilter({ ...filter, nameSearch: e.target.value })
    invalidateDetailsPane()
  }

  // ************************ GET_ROOTTASKS / GET_TASKS *****************************
  const routeParams: any = useParams()
  const isRoot = !("subTaskId" in routeParams)
  const taskIds = [isRoot ? [] : routeParams["subTaskId"]] // This task will go into the page heading
  const subSub = [isRoot ? [] : routeParams["subSubTaskId"]] // This task and any sibling task(s) will go into the main grid
  const callName = isRoot ? GET_ROOTTASKS : GET_TASKS
  const input = isRoot ? { quadriProjectId } : { quadriProjectId, taskIds }
  let entryPosition = -1 // Determines which task, if any, is selected in the main grid. Count from top of grid. Default -1 = No task selected.
  const {
    loading: rootLoading,
    error: rootError,
    data: rootData
  } = useQuery(callName, {
    variables: input,
    errorPolicy: "all"
  })

  const history = useHistory()

  if (rootLoading) {
    loadingStatus = true
  } else if (rootError && errorStatus === 0 && !loadingStatus) {
    errorStatus = 2
    apolloMessage = rootError.message
  }
  // ******************** USERS (for filtering) *************************
  if (users.state === "loading") loadingStatus = true
  else if (users.state === "hasError") {
    errorStatus = 3
    apolloMessage = "" // No apollo message for this
  }

  if (errorStatus > 0) {
    return <Oops body={apolloMessage} heading={errMessages[errorStatus - 1]} />
  }

  if (loadingStatus) return <PendingSpinner />
  // -------------------------------------------------------------- Loading finished ---------------------------------------------------------------------------------
  const sortedFilteredList = (isRoot ? rootData.getProjectRootTasks : rootData.getTasks[0].subTasks)
    .map(function (item: any) {
      return {
        taskId: item.id,
        icon: <img src={iconBindings(item.type)} width="24" height="24" alt="a" />,
        taskName: item.name,
        subTasks: item.subTasks,
        subTasksCount: item.subTasks.length,
        createdTime: item.createdTime,
        latestModderName: userFullName(users.contents[item.lastModifiedBy]),
        latestDateMod: formatDateAsConnect(item.lastModifiedTime),
        latestModderId: users.contents[item.lastModifiedBy]?.id
      }
    })
    .filter(function (item: any) {
      return filterCheck(item, filter)
    })
    .sort((a: any, b: any) =>
      sortConfig.type ? b.taskName.localeCompare(a.taskName) : a.taskName.localeCompare(b.taskName)
    )
  const onRowClick = (index: number) => {
    const task = sortedFilteredList[index]
    const taskUnChanged = task.taskName === prevTask?.taskName
    setSelectedTask(
      // Switches the sidebar on if it is off, and the user clicks on a task in the main grid.
      // Switches the sidebar off if it is on, and the user clicks on the already selected task in the main grid
      // Lets the sidebar remain on, if the user clicks on a currently un-selected task in the main grid. Then also switches contents to show the new tasks' subtasks
      taskUnChanged && sidebarToggle
        ? null
        : {
            taskName: task.taskName,
            taskId: task.taskId,
            subTasks: task.subTasks,
            clickedTaskId: -1,
            createdData: makeDatePersonEntry(task.createdTime, "", cBy, cNone),
            lastModifiedData: makeDatePersonEntry(task.latestDateMod, task.latestModderName, cBy, cNone)
          }
    )
    setTaskRowSelected([index])
    entryPosition = index // Set to correct position when user clicks on a task in the main grid
    setPrevTask(task)
  }
  // If user clicked on a task in the sidebar (causing subSub.length > 0), the sidebar task list is "moved" to the main grid. Go through the main grid list to re-locate and mark it
  for (let iCount = 0; iCount < sortedFilteredList.length && subSub.length; iCount++) {
    if (sortedFilteredList[iCount].taskId === sidebarToggle?.taskId) {
      entryPosition = iCount // Set to correct position when user clicks on a task in the side panel
      // When user clicks subtask in sidebar, subtasks (to the tasks displayed in the sidebar) is null
      if (!sidebarToggle?.subTasks) {
        setSelectedTask({
          taskName: sortedFilteredList[iCount].taskName,
          taskId: sortedFilteredList[iCount].taskId,
          subTasks: sortedFilteredList[iCount].subTasks,
          clickedTaskId: -1,
          createdData: makeDatePersonEntry(sortedFilteredList[iCount].createdTime, "", cBy, cNone),
          lastModifiedData: makeDatePersonEntry(
            sortedFilteredList[iCount].latestDateMod,
            sortedFilteredList[iCount].latestModderName,
            cBy,
            cNone
          )
        })
      }
      break
    }
  }

  const onRowDbClick = (index: number) => {
    const task = sortedFilteredList[index]
    const changeTo = "/tasks/" + task.taskId
    setSelectedTask(null)
    history.push(changeTo)
  }

  const invalidateDetailsPane = () => {
    setTaskRowSelected([])
    setSelectedTask(null)
  }

  const usersApiUrl = `${projectOrigin}/projects/${projectId}/users?includesRemoved=false`

  return (
    <>
      <div className="row">
        <div className="col-12 head-container">
          <div className="page-heading">
            <TasksBreadCrumbs rootData={rootData} />
            {/* <TaskActions /> This is appparently not used for anything */}
          </div>
        </div>
      </div>
      <div className="toolbar-section no-gap">
        <div className="w-100">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-9 filters">
              <div>
                <div className="filter-type">
                  <UserPicker
                    onChange={onFilterChange}
                    apiUrl={usersApiUrl}
                    id="tasks-user-picker"
                    selectionList={[]}
                  />
                </div>
                <div className="filter-type">
                  <DateRangePicker
                    onChange={onFilterChange}
                    id="tasks-date-range-picker"
                    selection={datePickerDefaultValue}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="input-button-group search">
                <button className="button default hollow icon-medium icon-circle">
                  <i className="icon-font tc-icon-search"></i>
                </button>
                <input type="text" placeholder="Search for task name..." onChange={onSearchEnter} />
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {sortedFilteredList.length ? (
        <div className="row">
          <div className="col-12 col-md-12">
            <CommonTable<Task>
              columns={columnsSet}
              sortOptions={sortConfig}
              lazyLoad={false}
              loadMore={async () => null}
              list={sortedFilteredList}
              isLoading={false}
              onRowSelect={onRowClick}
              onRowDbClick={onRowDbClick}
              selectedRows={[entryPosition]}
              doSort={onSort}
            />
          </div>
        </div>
      ) : (
        <NoData
          icon="tc-icon-libraries"
          heading={t("runtimeTexts.noTasks")}
          message={t("runtimeTexts.doCreateTasks")}
        />
      )}
    </>
  )
}

function filterCheck(entry: Task, theFilter: TasksFilter) {
  const latestChangeDate = new Date(entry.latestDateMod)
  const fromDate = new Date(theFilter.startDate ?? "1990-01-01")
  const toDate = new Date(theFilter.endDate ?? "2990-01-01")
  if (fromDate > latestChangeDate || toDate < latestChangeDate) {
    return false
  }

  if (theFilter.connectUserIds?.length) {
    if (!theFilter.connectUserIds?.find((uId) => uId === entry.latestModderId)) return false
  }

  const fLen = (theFilter.nameSearch ?? "").length

  if (!fLen) {
    // Filterstring not set?
    return true
  }

  if (fLen > entry.taskName.length) {
    // Filterstring longer than entry?
    return false
  }

  if (
    theFilter.nameSearch &&
    entry.taskName.toLocaleUpperCase().indexOf(theFilter.nameSearch.toLocaleUpperCase()) >= 0
  ) {
    // Case-insensitive comparison between filterstring and entry found a match?
    return true
  }

  return false
}

const datePickerDefaultValue = {
  from: null,
  to: null
}

export default TasksMain
export { makeDatePersonEntry }
