import React from "react"
import { useTranslation } from "react-i18next"

export interface ModalProps {
  heading: string
  children: React.ReactNode
  closeModal: () => void
  submitButtonText: string
  submitButtonClasses?: string
  onSubmitButtonClicked: () => void
}

const Modal = (props: ModalProps) => {
  return (
    <div className="reveal-overlay d-block">
      <div className="reveal-container">
        <div className="reveal null" role="dialog">
          <ModalHeading {...props} />
          <div className="scroll-section null">{props.children}</div>
          <ModalFooter {...props} />
        </div>
      </div>
    </div>
  )
}

const ModalHeading = (props: ModalProps) => (
  <header className="align-items-start">
    <div>
      <h2>{props.heading}</h2>
    </div>
    <button type="button" className="button icon-circle icon-medium tertiary" onClick={props.closeModal}>
      <i className="icon-font tc-icon-content-close"></i>
    </button>
  </header>
)

const ModalFooter = (props: ModalProps) => {
  const { closeModal, submitButtonText, submitButtonClasses, onSubmitButtonClicked } = props
  const classes = submitButtonClasses || "primary"
  const { t } = useTranslation("common")

  return (
    <footer>
      <hr />
      <div className="wrap-buttons">
        <button className="button link-secondary" onClick={closeModal}>
          {t("cancel")}
        </button>
        <button className={`button ${classes}`} disabled={undefined} onClick={onSubmitButtonClicked}>
          {submitButtonText}
        </button>
      </div>
    </footer>
  )
}

export default Modal
