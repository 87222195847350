import React from "react"
import * as signalR from "@microsoft/signalr"
import { quadriProjectIdState } from "./StateSelectors"
import { useRecoilValue } from "recoil"
import Environment from "../enviroments"

// TODO: Get this from environment config
const signalrBaseUrl = Environment.signalrBaseUrl

if (!signalrBaseUrl) {
  console.warn("No signalrBase URL, real time events will not be processed")
}

export interface SignalrClientProps {
  children: React.ReactNode
}

export const SignalrClient = (props: SignalrClientProps) => {
  const quadriProjectId = useRecoilValue(quadriProjectIdState)

  // Initialize signalr client when project ID changes (will only initialize if project id is present)
  React.useEffect(() => {
    console.log("Running effect: ", quadriProjectId)
    console.log("onReceive", onReceiveMessage)
    initSignalrClient({
      projectId: quadriProjectId,
      onReceiveMessage
    })
  }, [quadriProjectId])

  return <>{props.children}</>
}

const connection = signalrBaseUrl
  ? (() => {
      console.log("Constructing signalr hub connection")
      return new signalR.HubConnectionBuilder()
        .withUrl(`${signalrBaseUrl}/quadrihub`, {
          accessTokenFactory: () => localStorage.getItem("tc-access-token") as string
        })
        .build()
    })()
  : undefined

const initSignalrClient = (props: { projectId?: string; onReceiveMessage: (topic: string, message: any) => void }) => {
  if (props.projectId && connection) {
    connection.on("ReceiveMessage", props.onReceiveMessage)
    connection
      .start()
      // .then(() => {
      //   console.log("waiting 30 sec to resolve...")
      //   return new Promise((resolve) => setTimeout(resolve, 30000))
      // })
      .then(() => {
        connection
          .invoke("JoinProjectUpdates", props.projectId)
          .catch((err) => {
            return console.error(err.toString())
          })
          .then(() => {
            console.log("Initialized signalr client with project id: ", props.projectId)
          })
      })
      .catch((err) => {
        return console.error(err.toString())
      })
  }
}

const onReceiveMessage = (topic: string, message: any) => {
  console.log("Received signalr message, topic ", topic, ", message", message)
  switch (topic) {
    case "ddm-tcps-delete-project-result-topic":
      window.location.reload() // Reload when project finished deleting
  }
}
