import { useTranslation } from "react-i18next"
import { Route, Switch } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { sidebarTaskState } from "../StateSelectors"

const TasksBreadCrumbs = (props: any) => {
  const rootData = props.rootData
  const reversedCopy =
    rootData && rootData.getTasks ? rootData.getTasks[0].taskPath.map((item: any) => item).reverse() : []
  const setSelectedTask = useSetRecoilState(sidebarTaskState)
  // Always close side bar on breadcrumbs navigation
  const closeSideBar = () => {
    setSelectedTask(null)
  }
  const { t } = useTranslation("tasks")
  return (
    <Switch>
      <Route path="/tasks" exact>
        {/* At the top level, we do not show the task name in the heading */}
        <h1> {t("breadCrumbs.taskHeading")} </h1>
      </Route>
      <Route path="/tasks/:id">
        <div>
          <div>
            <ul className="breadcrumbs">
              {reversedCopy.map((item: any, ind: number) => (
                <li key={item.id}>
                  {ind === 0 ? (
                    // First item in the breadcrumbs, i.e. top level. Link to "/task" without task id, include chevron
                    <>
                      <a href={"/#/tasks"} onClick={closeSideBar}>
                        {item.name}
                      </a>
                      <i className="icon-font tc-icon-chevron-right i16"></i>
                    </>
                  ) : ind < reversedCopy.length - 1 ? (
                    // Neither first nor last item in the breadcrumbs. Link to /task with task id, include chevron
                    <>
                      <a href={"/#/tasks/" + item.id} onClick={closeSideBar}>
                        {item.name}
                      </a>
                      <i className="icon-font tc-icon-chevron-right i16"></i>
                    </>
                  ) : (
                    // Last (bottom level) item of the breadcrumbs. No link; no chevron
                    item.name
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div>
            {/* rootData.getTasks will always have only one task element - the one selected by the user (with possible subtasks) */}
            <h1>{rootData.getTasks ? rootData.getTasks[0].name : ""}</h1>
          </div>
        </div>
      </Route>
    </Switch>
  )
}

export default TasksBreadCrumbs
