import { useTranslation } from "react-i18next"
import i18next from "i18next"
import React from "react"
import "./Timeline.css"
import DateRangePicker from "../FromConnect/DateRangePicker"
import TypePicker from "../FromConnect/TypePicker"
import UserPicker from "../FromConnect/UserPicker"
import { TimelineCard } from "./TimelineCard"
import TimelineItemsSkeleton from "./TimelineItemsSkeleton"
import { Activity } from "../../types"
import { useQuery } from "@apollo/client"
import { GET_ACTIVITIES } from "../../api/QuadriApi"
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil"
import {
  projectIdState,
  projectOriginState,
  quadriProjectIdState,
  sidebarTimelineState,
  usersByTidUuidState
} from "../StateSelectors"
import NoData from "../NoData/NoData"
import { ConnectUser } from "../../api/ConnectApi"
import { getOnFilterChanged } from "../../util/filtering"
import { TimelineError } from "./TimelineError"

type ActivityType = "Unknown" | "Receive" | "Release" | "Reserve" | "Share" | "Join"

export interface ActivityFilter {
  types: ActivityType[]
  tidUserIds: string[]
  // WTF is this doing here? Need to show the correct UI state of the filter
  connectUserIds: string[]
  startDate?: string
  endDate?: string
}

const TimelineMain = () => {
  const connectProjectId = useRecoilValue(projectIdState)
  const quadriProjectId = useRecoilValue(quadriProjectIdState)
  const projectOrigin = useRecoilValue(projectOriginState)
  const setSelectedTimeline = useSetRecoilState(sidebarTimelineState)
  const users = useRecoilValueLoadable(usersByTidUuidState)

  const { t } = useTranslation("timeline")

  if (!connectProjectId) throw new Error(t("main.errConnectId)"))
  if (!quadriProjectId) throw new Error(t("main.errQuadriId"))

  const [active, setActive] = React.useState(undefined as undefined | Activity)
  const [filter, setFilter] = React.useState(getDefaultFilter() as ActivityFilter)

  const transData = setupActivityComponentTranslations()
  const typeList = transData.typeList
  const translationSource = transData.translationSource
  console.log("Getting activities, filter: ", filter)
  const activitiesQuery = useQuery(GET_ACTIVITIES, {
    variables: {
      quadriProjectId,
      filter: {
        ...filter,
        // Need to remove these from the query, or it will result in a 400
        connectUserIds: undefined
      }
    }
  })

  // TODO: This is only for debugging
  React.useEffect(() => console.log(filter), [filter])

  const loading = activitiesQuery.loading || users.state === "loading"
  const hasError = activitiesQuery.error || users.state === "hasError"

  const onFilterChange = getOnFilterChanged(setFilter)
  const resetFilter = () => setFilter(getDefaultFilter())

  const onTimelineCardSelect = (activity: Activity) => {
    setActive(activity)
    setSelectedTimeline(activity.taskChanges?.length ? { activity, users: users.contents, quadriProjectId } : null)
  }

  const usersApiUrl = `${projectOrigin}/projects/${connectProjectId}/users?includesRemoved=false`
  // const usersApiUrl = `https://app21.connect.trimble.com/tc/api/2.0/projects/KpWXEFjl4iQ/users?includesRemoved=false`

  const activitiesData = activitiesQuery.data?.getActivities as Activity[] | undefined
  const thereIsActivitiesData = activitiesData && activitiesData.length > 0

  return hasError ? (
    <TimelineError activitiesQuery={activitiesQuery} />
  ) : (
    <>
      <div className="head-container">
        <div className="page-heading">
          <h1>{t("main.timelineHeading")}</h1>
          {/* <TimelineActions /> */}
        </div>
      </div>

      <div className="toolbar-section">
        <div className="filter-container">
          <div className="filters">
            <div>
              <div className="filter-type">
                <TypePicker
                  onChange={(e: any, which: any) => onFilterChange(e, which)}
                  id="timeline-type-picker"
                  typeList={typeList}
                  selectionList={filter.types.map((t) => typeList.find((tl) => tl.value === t))}
                  translationSource={translationSource}
                />
              </div>
              <div className="filter-type">
                <UserPicker
                  onChange={(e: any, which: any) => onFilterChange(e, which)}
                  apiUrl={usersApiUrl}
                  id="activity-user-picker"
                  selectionList={filter.connectUserIds}
                />
              </div>
              <div className="filter-type">
                <DateRangePicker
                  onChange={(e: any, which: any) => onFilterChange(e, which)}
                  id="activity-daterange-picker"
                  selection={{
                    from: filter.startDate || null,
                    to: filter.endDate || null
                  }}
                />
              </div>
              {filterIsActive(filter) && (
                <button className="button link-primary d-none d-xl-block" id="filter-reset" onClick={resetFilter}>
                  {t("main.reset")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-12">
          {!loading ? (
            thereIsActivitiesData ? (
              <ActivityCards
                activities={activitiesData as Activity[]}
                active={active}
                onTimelineCardSelect={onTimelineCardSelect}
                connectProjectId={connectProjectId}
                users={users.contents}
                filter={filter}
              />
            ) : (
              <NoData icon="tc-icon-activity" heading={t("main.noTimeLine")} message={t("main.sharedShow")} />
            )
          ) : (
            <TimelineItemsSkeleton count={5} />
          )}
        </div>
      </div>
    </>
  )
}
const setupActivityComponentTranslations = () => {
  const typeList: { value: ActivityType; translationKeyForValue: string }[] = [
    { value: "Join", translationKeyForValue: "TASKS_JOINED" },
    { value: "Reserve", translationKeyForValue: "TASKS_RESERVED" },
    { value: "Share", translationKeyForValue: "TASKS_SHARED" },
    { value: "Receive", translationKeyForValue: "TASKS_RECEIVED" },
    { value: "Release", translationKeyForValue: "TASKS_RELEASED" }
  ]

  const translationSource: any[] = [
    {
      type: "DICTIONARY",
      value: {
        // files: translate("files"),
        // FILE_ADD: translate("FILE_ADD"),
        TASKS_JOINED: i18next.t("timeline:main.tasksJoined"),
        TASKS_RESERVED: i18next.t("timeline:main.tasksReserved"),
        TASKS_SHARED: i18next.t("timeline:main.tasksShared"),
        TASKS_RECEIVED: i18next.t("timeline:main.tasksReceived"),
        TASKS_RELEASED: i18next.t("timeline:main.tasksReleased"),
        Label: i18next.t("timeline:main.tasksLabel")
      }
    }
  ]
  return { typeList, translationSource }
}
const ActivityCards = (props: {
  activities: Activity[]
  active: undefined | Activity
  onTimelineCardSelect: (activity: Activity) => void
  connectProjectId: string
  users: { [key: string]: ConnectUser }
  filter: ActivityFilter
}) => {
  const { activities, active, onTimelineCardSelect, connectProjectId } = props
  // const { filter } = props
  // const { tidUserIds } = filter

  if (activities.length > 0) {
    return (
      <>
        {activities.map((activity, i) => (
          <TimelineCard
            key={`timeline-card-${i}`}
            activity={activity}
            active={active}
            onSelect={onTimelineCardSelect}
            connectProjectId={connectProjectId}
            users={props.users}
          />
        ))}
      </>
    )
  } else {
    return <>No activity yet</>
  }
}

const getDefaultFilter = () => ({
  types: [],
  tidUserIds: [],
  connectUserIds: []
})

const filterIsActive = (filter: ActivityFilter): boolean =>
  (filter.types && filter.types.length > 0) ||
  (filter.tidUserIds && filter.tidUserIds.length > 0) ||
  typeof filter.startDate !== "undefined" ||
  typeof filter.endDate !== "undefined"

export default TimelineMain
