import { Link } from "react-router-dom"
import Skeleton from "react-skeleton-loader"
import { iconBindings } from "../assets/icons/quadriWindows/iconBindings"
import { sidebarTaskState } from "./StateSelectors"
import { useSetRecoilState } from "recoil"

interface Task {
  id: string
  name: string
  type: string
  taskPath: Task[]
  deleted?: boolean
}

interface SidebarTasksListProps {
  loading?: boolean
  tasksData?: Task[]
  /** Optional parameter used to render loading skeleton items */
  tasksCount?: number
  truncated?: boolean
}

export const SidebarTasksList = ({ loading, tasksData, tasksCount, truncated }: SidebarTasksListProps) => {
  const setSidebar = useSetRecoilState(sidebarTaskState)
  return (
    <ul className="list">
      {loading
        ? Array.from(Array(tasksCount || 3).keys()).map((_, i) => (
            <li key={`list-task-${i}`} className="list-item group">
              <Skeleton widthRandomness={0} width={"100%"} height={"2rem"} borderRadius={"0px"} color={"#EAEAEF"} />
            </li>
          ))
        : tasksData && (
            <>
              {tasksData.map((t: Task) => {
                console.log("Task: ", t)
                const path = t.taskPath
                  .map((p) => p.name)
                  .reverse()
                  .join(" / ")
                return (
                  <div key={`task-list-div-${t.id}`} className="group-items mt-2">
                    <img src={iconBindings(t.type)} className="mb-n1 mr-1" width="24" height="24" alt="a" />
                    {t.deleted ? (
                      <span className="">
                        {t.name} <span className="text_col_red">(Deleted)</span>
                      </span>
                    ) : (
                      <Link
                        to={`/tasks/${t.id}`}
                        onClick={() => {
                          setSidebar(null) // The selected task name is shown in the heading, any subtasks are shown in the main grid. There is nothing to show in the sidebar.
                        }}
                        className="link-primary"
                        title={path}
                      >
                        {t.name}
                      </Link>
                    )}
                  </div>
                )
              })}
              {truncated && (
                <div className="col my-2 text-muted" key="truncated-warning">
                  ...There are too many tasks to display, the list has been truncated...
                </div>
              )}
            </>
          )}
    </ul>
  )
}
