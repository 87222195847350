import { User, UserDetails } from "trimble-connect-sdk"
import fetch from "node-fetch"
import { ConnectProjectRegion } from "../types"

const ConnectApiBaseUrl = "https://app21.connect.trimble.com/tc/api/2.0"

export interface ConnectData {
  // TODO: Set region as required. And throw error earlier if it is not set
  region?: ConnectProjectRegion
  unitSystem?: string
  boundaryCoordinates?: string
  crsName?: string
}

export interface ConnectUser {
  /** This is the Connect User ID, not TID ! */
  id: string
  firstName: string
  lastName: string
  tiduuid: string
  thumbnail: string
  email: string
  company: {
    id: string
    name: string
  }
  role: "ADMIN" | "USER"
}

class ConnectApi {
  private baseUrl: string

  constructor(baseUrl?: string) {
    this.baseUrl = baseUrl || ConnectApiBaseUrl
  }

  public async getUser(): Promise<UserDetails> {
    const init = this.requestInit()
    const user = await fetch(`${this.baseUrl}/users/me`, init)
      .then((res) => res.json())
      .then(async (data) => await fetch(`${this.baseUrl}/users/${data.id}`, init))
      .then((res) => res.json())
    return user
  }

  public async isAdminUserRole(userId: string, projectId: string): Promise<boolean> {
    const init = this.requestInit()

    const users = await fetch(`${this.baseUrl}/projects/${projectId}/users`, init).then((res) => res.json())

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return users.filter((user: User) => user.id === userId)[0].role! === "ADMIN"
  }

  public async getLoggedInUserProjectRoles(): Promise<any[]> {
    const init = this.requestInit()

    const project = await fetch(`${this.baseUrl}/projects/me`, init)
      .then((res) => res.json())
      .catch((err) => console.error(err))

    return project
  }

  public async getProject(projectId: string): Promise<any> {
    const init = this.requestInit()

    const project = await fetch(`${this.baseUrl}/projects/${projectId}`, init)
      .then((res) => res.json())
      .catch((err) => console.error(err))

    return project
  }

  public async getConnectProjectData(projectId: string): Promise<ConnectData> {
    const init = this.requestInit()

    // Fetch two endpoints concurrently
    const [project, settings] = await Promise.all([
      fetch(`${this.baseUrl}/projects/${projectId}`, init).then((r) => r.json()),
      fetch(`${this.baseUrl}/projects/${projectId}/settings`, init).then((r) => r.json())
    ])

    return {
      region: project?.location,
      unitSystem: settings?.unitSettings?.unitsystem,
      boundaryCoordinates: project?.address?.geometry,
      crsName: project?.crs?.name
    }
  }

  public async getConnectProjectUsersByTidUuid(projectId: string): Promise<{ [key: string]: ConnectUser }> {
    const init = this.requestInit()

    const users = await fetch(`${this.baseUrl}/projects/${projectId}/users`, init).then((res) => res.json())

    let usersByTidUuid: { [key: string]: ConnectUser } = {}
    for (const u of users) {
      if (u.tiduuid) {
        usersByTidUuid[u.tiduuid] = u
      }
    }

    return usersByTidUuid
  }

  private requestInit(method?: string) {
    const accessToken = localStorage.getItem("tc-access-token")
    return { method: method || "GET", headers: { Authorization: "Bearer " + accessToken } }
  }
}

export default ConnectApi
