import React, { useRef } from "react"
import { useQuery } from "@apollo/client"
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil"
import DateRangePicker from "../FromConnect/DateRangePicker"
import TypePicker from "../FromConnect/TypePicker"
import UserPicker from "../FromConnect/UserPicker"
import CommonTable, { ISortOptions } from "../FromConnect/CommonTable/CommonTable"
import NoData from "../NoData/NoData"
import {
  projectIdState,
  projectOriginState,
  quadriProjectIdState,
  sidebarWorksetState,
  usersByTidUuidState
} from "../StateSelectors"
import { WorksetStatusType, WorksetType } from "../../types"
import { PendingSpinner } from "../Pending"
import { GET_WORKSETS } from "../../api/QuadriApi"
import { ReactComponent as StatusConnected1 } from "../../assets/icons/quadri/WorksetConnectedNoReservations_24.svg"
import { ReactComponent as StatusConnected2 } from "../../assets/icons/quadri/WorksetConnectedWithReservations_24.svg"
import { formatDateAsConnect } from "../../util/dateTools"
import { ConnectUser } from "../../api/ConnectApi"
import { userFullName } from "../UserRow"
import { getOnFilterChanged } from "../../util/filtering"
import { WorksetsError } from "./WorksetsError"
import { useTranslation } from "react-i18next"

interface WorksetsFilter {
  nameSearch?: string
  tidUserIds?: string[]
  // WTF is this doing here? Need to show the correct UI state of the filter
  connectUserIds?: string[]
  status?: WorksetStatusType[]
  startDate?: string
  endDate?: string
}

const WorksetsMain = () => {
  const projectId = useRecoilValue(projectIdState)
  const quadriProjectId = useRecoilValue(quadriProjectIdState)
  const projectOrigin = useRecoilValue(projectOriginState)
  const users = useRecoilValueLoadable(usersByTidUuidState)
  const { t } = useTranslation("worksets")

  const [filter, setFilter] = React.useState({} as WorksetsFilter)
  const worksetsQuery = useQuery(GET_WORKSETS, {
    variables: {
      quadriProjectId,
      filter: {
        ...filter,
        // Need to remove these from the query, or it will result in a 400
        connectUserIds: undefined
      }
    }
  })

  const loading = worksetsQuery.loading || users.state === "loading"
  const hasError = worksetsQuery.error || users.state === "hasError"

  const scrollDiv = useRef(null)

  React.useEffect(() => console.log(filter), [filter])

  const onFilterChange = getOnFilterChanged(setFilter, "status")
  const resetFilter = () => setFilter({})

  const onSearchEnter = (e: any) => {
    e.preventDefault()
    setFilter({ ...filter, nameSearch: e.target.value })
    console.log("Search filter changed!")
  }

  const usersApiUrl = `${projectOrigin}/projects/${projectId}/users?includesRemoved=false`

  const worksetsData = worksetsQuery.data?.getWorksets as WorksetType[] | undefined
  const thereIsWorksetsData = worksetsData && worksetsData.length > 0

  return hasError ? (
    <WorksetsError worksetsQuery={worksetsQuery} />
  ) : (
    <>
      <div className="head-container">
        <div className="page-heading">
          {/* <h1>Shared model | worksets</h1> */}
          <h1>{t("main.header")}</h1>
        </div>
      </div>

      <div className="toolbar-section">
        <div className="w-100">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-9 filters">
              <div>
                <div className="filter-type">
                  <UserPicker
                    onChange={onFilterChange}
                    apiUrl={usersApiUrl}
                    id="workset-user-picker"
                    selectionList={filter.connectUserIds || []}
                  />
                </div>
                <div className="filter-type">
                  <TypePicker
                    onChange={onFilterChange}
                    id="workset-status-picker"
                    typeList={statusList}
                    selectionList={(filter.status || []).map((s) => statusList.find((sl) => sl.value === s))}
                    translationSource={translationSource}
                  />
                </div>
                <div className="filter-type">
                  <DateRangePicker
                    onChange={onFilterChange}
                    id="workset-date-range-picker"
                    selection={{
                      from: filter.startDate || null,
                      to: filter.endDate || null
                    }}
                  />
                </div>
                {filterIsActive(filter) && (
                  <button className="button link-primary d-none d-xl-block" id="filter-reset" onClick={resetFilter}>
                    {t("main.reset")}
                  </button>
                )}
              </div>
            </div>

            <div className="col">
              <div className="input-button-group search">
                <button className="button default hollow icon-medium icon-circle">
                  <i className="icon-font tc-icon-search"></i>
                </button>
                <input
                  type="text"
                  placeholder={t("main.searchPlaceholder")}
                  onChange={onSearchEnter}
                  value={filter.nameSearch || ""}
                />
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref={scrollDiv}>
        <div className="row">
          <div className="col-12 col-md-12">
            {!loading ? (
              thereIsWorksetsData ? (
                <TableComponent worksets={worksetsData} users={users.contents} scrollElement={scrollDiv} />
              ) : (
                <NoData icon="tc-icon-cloud" heading={t("main.noWorksets")} message={t("main.noDataMessage")} />
              )
            ) : (
              <PendingSpinner />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const TableComponent = (props: {
  worksets: WorksetType[]
  users: { [key: string]: ConnectUser }
  scrollElement: React.RefObject<HTMLDivElement>
}) => {
  const { worksets, users } = props
  const [selectedWorkset, setSelectedWorkset] = useRecoilState(sidebarWorksetState)
  // Type 1 is descending
  const [sortConfig, setSortConfig] = React.useState<ISortOptions>({ col: "createdOn", type: 1 })
  const { t } = useTranslation("worksets")

  const formatWorksets = (ws: WorksetType[], sortConfig: ISortOptions) =>
    ws
      .map((w: WorksetType) => ({
        originalWorkset: w,
        status:
          w.status === "Connected" ? (
            w.reservedTaskIds && w.reservedTaskIds.length > 0 ? (
              <StatusConnected2 height="24" width="24" />
            ) : (
              <StatusConnected1 height="24" width="24" />
            )
          ) : (
            <i className="icon-font text_col_red tc-icon-cloud-off"></i>
          ),
        name: w.name,
        user: userFullName(users[w.userId]),
        createdOn: formatDateAsConnect(w.createdTime),
        reservations: w.reservedTaskIds && w.reservedTaskIds.length
      }))
      // What is this you say, sorted twice? Always sort by date descending
      // first so that if you sort on something else, for example name, then
      // entries with the same name are sorted by date descending
      .sort(getSortFunc({ col: "createdOn", type: 1 }))
      .sort(getSortFunc(sortConfig))

  const onSort = (col: string) => {
    if (col === sortConfig.col) {
      // If it is the currently sorted column, flip direction
      setSortConfig({ ...sortConfig, type: sortConfig.type === 1 ? 0 : 1 })
    } else {
      setSortConfig({ col, type: 0 })
    }
  }

  const sortedFilteredRows = formatWorksets(worksets, sortConfig)
  // console.log("list: ", list)

  const onRowClick = (index: number) => {
    const workset = sortedFilteredRows[index].originalWorkset
    const fullName = userFullName(users[workset.userId])
    // setSelectedRows([index])
    setSelectedWorkset({ ...workset, fullName })
  }

  // The table needs an array of indices to render as selected. This code
  // determines if there is a selected workset, and if there is, it derives
  // the index of that workset in the sorted table data
  const selectedRows = selectedWorkset
    ? (() => {
        const index = sortedFilteredRows.findIndex((w: any) => {
          return w.originalWorkset.id === selectedWorkset.id
        })
        return index === -1 ? [] : [index]
      })()
    : []

  return (
    <CommonTable<Workset>
      columns={getColumns(t)}
      lazyLoad={false}
      loadMore={async () => null}
      list={sortedFilteredRows}
      isLoading={false}
      sortOptions={sortConfig}
      // errMessage={this.state.errorMessage}
      doSort={onSort}
      onRowSelect={onRowClick}
      selectedRows={selectedRows}
      scrollElement={props.scrollElement}
      // totCount={this.props.release.totCount}
      // selectedComponent={actionDropdown}
      // staticWidth={this.props.staticWidth}
      // responsiveHeader={this.responsiveHeader}
      // staticHeader={true}
      // defaultConfig={{ preScanRows: 5, preFetchRows: 5, rowHeight: getBreakPoint() !== "xs" ? 64 : 104 }}
    />
  )
}

const getSortFunc = (sortConfig: ISortOptions) => {
  const dir = sortConfig.type === 1 ? 1 : -1
  switch (sortConfig.col) {
    case "status":
      return (a: any, b: any) => dir * a.originalWorkset.status.localeCompare(b.originalWorkset.status)
    case "name":
      return (a: any, b: any) => dir * a.name.localeCompare(b.name)
    case "user":
      return (a: any, b: any) => dir * a.user.localeCompare(b.user)
    case "createdOn":
      return (a: any, b: any) => dir * (new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf())
    case "reservations":
      return (a: any, b: any) => dir * ((a.reservations || 0) - (b.reservations || 0))
    default:
      return undefined
  }
}

const statusList = [
  { value: "Connected", translationKeyForValue: "CONNECTED" },
  { value: "Disconnected", translationKeyForValue: "DISCONNECTED" }
]

const translationSource: any[] = [
  {
    type: "DICTIONARY",
    value: {
      CONNECTED: "Connected",
      DISCONNECTED: "Disconnected",
      Label: "Status"
    }
  }
]

const filterIsActive = (filter: WorksetsFilter): boolean =>
  (filter.status && filter.status.length > 0) ||
  (filter.tidUserIds && filter.tidUserIds.length > 0) ||
  typeof filter.startDate !== "undefined" ||
  typeof filter.endDate !== "undefined" ||
  typeof filter.nameSearch !== "undefined"

interface Workset {
  status: JSX.Element
  name: string
  user: string
  createdOn: string
  reservations?: number
}

const getColumns = (t: any) => [
  {
    label: t("main.status"),
    field: "status",
    isSort: true,
    noTooltip: true,
    colClass: "pl-3 mr-0 pr-0 ",
    fieldClass: "iconMarginWidth"
  },
  {
    label: t("main.workset"),
    field: "name",
    isSort: true,
    noTooltip: true,
    colClass: "col list-data ml-0 pl-0",
    fieldClass: "col list-data ml-0 pl-0"
  },
  {
    label: t("main.user"),
    field: "user",
    isSort: true,
    noTooltip: true,
    colClass: "col list-data",
    fieldClass: "col  list-data"
  },
  {
    label: t("main.createdOn"),
    field: "createdOn",
    isSort: true,
    noTooltip: true,
    colClass: "col list-data",
    fieldClass: "col list-data"
  },
  {
    label: t("main.reservations"),
    field: "reservations",
    isSort: true,
    noTooltip: true,
    colClass: "col list-data",
    fieldClass: "col list-data"
  }
]

export default WorksetsMain
