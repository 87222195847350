import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import { AuthCallbackHandler } from "./auth/AuthCallbackHandler"
import { TidSettings } from "./auth/TidSettings"
import { RecoilRoot } from "recoil"
import { HashRouter } from "react-router-dom"

// eslint-disable-next-line no-restricted-globals
const isAuthCallback = location.pathname.toLowerCase().includes("tidauth")

if (isAuthCallback) {
  const callbackHandler = new AuthCallbackHandler(TidSettings)
  callbackHandler.handleCallback()
} else {
  ReactDOM.render(
    <React.StrictMode>
      <HashRouter>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </HashRouter>
    </React.StrictMode>,
    document.getElementById("root")
  )
}
